// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.closeIconBtn {
  color: #6c6666;
  font-size: 30px;
}

.modalBox {
  width: 500px;
  height: 300px;
  text-align: center;
}

.popupHead {
  color: #3a3a3a;
}

@media (max-width: 640px) {
  .modalBox {
    width: 300px;
    height: 250px;
  }
}
@media (max-width: 375px) {
  .modalBox {
    width: 240px;
    height: 180px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/PopupModal/popupModal.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE;IACE,YAAA;IACA,aAAA;EACF;AACF;AAEA;EACE;IACE,YAAA;IACA,aAAA;EAAF;AACF","sourcesContent":[".closeIconBtn {\n  color: #6c6666;\n  font-size: 30px;\n}\n\n.modalBox {\n  width: 500px;\n  height: 300px;\n  text-align: center;\n}\n\n.popupHead {\n  color: #3a3a3a;\n}\n\n@media (max-width: 640px) {\n  .modalBox {\n    width: 300px;\n    height: 250px;\n  }\n}\n\n@media (max-width: 375px) {\n  .modalBox {\n    width: 240px;\n    height: 180px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
