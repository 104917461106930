import "../Components/IndustoryBox/industoryBox.scss";

const homePageIndustries = [
  {
    text: `Banking & Financial Services`,
    margin: "0vh",
    IndustoryTextClassName: "industryTextBox1",
    IndustoryBoxClassName: "industory-box1",
    link: "/bank",
  },
  {
    text: `Communication, Media
  & Technology`,
    margin: "20vh",
    IndustoryTextClassName: "industryTextBox2",
    IndustoryBoxClassName: "industory-box2",
    link: "/communication",
  },
  {
    text: `Energy & Utilities`,
    margin: "30vh",
    IndustoryTextClassName: "industryTextBox3",
    IndustoryBoxClassName: "industory-box3",
    link: "/energy",
  },
  {
    text: `Retail, Consumer Goods & Services`,
    margin: "50vh",
    IndustoryTextClassName: "industryTextBox2",
    IndustoryBoxClassName: "industory-box2",
    link: "/retail",
  },
  {
    text: `Travel & Hospitality`,
    margin: "30vh",
    IndustoryTextClassName: "industryTextBox3",
    IndustoryBoxClassName: "industory-box3",
    link: "/travel",
  },
  {
    text: `Life Sciences & Healthcare`,
    margin: "20vh",
    IndustoryTextClassName: "industryTextBox2",
    IndustoryBoxClassName: "industory-box2",
    link: "/life",
  },
  {
    text: `Insurance`,
    margin: "0vh",
    IndustoryTextClassName: "industryTextBox1",
    IndustoryBoxClassName: "industory-box1",
    link: "/insurance",
  },
];

export { homePageIndustries };
