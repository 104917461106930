"use client";

import { Image } from "antd";
import React from "react";
import Ashish_Baghel from "../../assets/events_images/speakers/Ashish_Baghel.png";
import Deepti_Baghel from "../../assets/events_images/speakers/Deepti_Baghel.png";
import Kiran_Ramireddi from "../../assets/events_images/speakers/Kiran_Ramireddi.png";
import Luis_Gonzalez from "../../assets/events_images/speakers/Luis_Gonzalez.png";
import Phani_Kalyan_Cherukuri from "../../assets/events_images/speakers/Phani_Kalyan_Cherukuri.png";
import Matt_McDonald from "../../assets/events_images/speakers/Matt_McDonald.png";
import Srini_Nanduri from "../../assets/events_images/speakers/Srini_Nanduri.png";
import Shankar_Vedaraman from "../../assets/events_images/speakers/Shankar_Vedaraman.png";

interface SpeakersMember {
  image: string;
  name: string;
  designation: string;
  socialLinks: SocialLink[];
}

interface SocialLink {
  iconName: string;
  url: string;
  bgColor: string;
}

const SpeakersMemberData: SpeakersMember[] = [
  {
    image: Ashish_Baghel,
    name: "Ashish Baghel",
    designation: "NucleusTeq- CEO & Founder",
    socialLinks: [
      {
        iconName: "icofont-facebook",
        url: "https://facebook.com/",
        bgColor: "#4267b2",
      },
      {
        iconName: "icofont-twitter",
        url: "https://twitter.com/",
        bgColor: "#38A1F3",
      },
      {
        iconName: "icofont-instagram",
        url: "https://instagram.com/",
        bgColor:
          "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      },
      {
        iconName: "icofont-linkedin",
        url: "https://linkedin.com/",
        bgColor: "#0077B5",
      },
    ],
  },
  {
    image: Deepti_Baghel,
    name: "Deepti Baghel",
    designation: "NuoData- VP, Technology",
    socialLinks: [
      {
        iconName: "icofont-facebook",
        url: "https://facebook.com/",
        bgColor: "#4267b2",
      },
      {
        iconName: "icofont-twitter",
        url: "https://twitter.com/",
        bgColor: "#38A1F3",
      },
      {
        iconName: "icofont-instagram",
        url: "https://instagram.com/",
        bgColor:
          "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      },
      {
        iconName: "icofont-linkedin",
        url: "https://linkedin.com/",
        bgColor: "#0077B5",
      },
    ],
  },
  {
    image: Kiran_Ramireddi,
    name: "	Kiran Ramireddi",
    designation: "NucleusTeq- EVP, Strategy",
    socialLinks: [
      {
        iconName: "icofont-facebook",
        url: "https://facebook.com/",
        bgColor: "#4267b2",
      },
      {
        iconName: "icofont-twitter",
        url: "https://twitter.com/",
        bgColor: "#38A1F3",
      },
      {
        iconName: "icofont-instagram",
        url: "https://instagram.com/",
        bgColor:
          "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      },
      {
        iconName: "icofont-linkedin",
        url: "https://linkedin.com/",
        bgColor: "#0077B5",
      },
    ],
  },
  {
    image: Luis_Gonzalez,
    name: "Luis Gonzalez",
    designation: "PetSmart- Senior Director, Data Engineering and Architecture",
    socialLinks: [
      {
        iconName: "icofont-facebook",
        url: "https://facebook.com/",
        bgColor: "#4267b2",
      },
      {
        iconName: "icofont-twitter",
        url: "https://twitter.com/",
        bgColor: "#38A1F3",
      },
      {
        iconName: "icofont-instagram",
        url: "https://instagram.com/",
        bgColor:
          "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      },
      {
        iconName: "icofont-linkedin",
        url: "https://linkedin.com/",
        bgColor: "#0077B5",
      },
    ],
  },
  {
    image: Phani_Kalyan_Cherukuri,
    name: "Phani Kalyan Cherukuri",
    designation: "American Express- Vice President, Engineering",
    socialLinks: [
      {
        iconName: "icofont-facebook",
        url: "https://facebook.com/",
        bgColor: "#4267b2",
      },
      {
        iconName: "icofont-twitter",
        url: "https://twitter.com/",
        bgColor: "#38A1F3",
      },
      {
        iconName: "icofont-instagram",
        url: "https://instagram.com/",
        bgColor:
          "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      },
      {
        iconName: "icofont-linkedin",
        url: "https://linkedin.com/",
        bgColor: "#0077B5",
      },
    ],
  },
  {
    image: Matt_McDonald,
    name: "Matt McDonald",
    designation:
      "CAVA- Senior Director - Data Engineering, Data Platforms, and Analytics",
    socialLinks: [
      {
        iconName: "icofont-facebook",
        url: "https://facebook.com/",
        bgColor: "#4267b2",
      },
      {
        iconName: "icofont-twitter",
        url: "https://twitter.com/",
        bgColor: "#38A1F3",
      },
      {
        iconName: "icofont-instagram",
        url: "https://instagram.com/",
        bgColor:
          "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      },
      {
        iconName: "icofont-linkedin",
        url: "https://linkedin.com/",
        bgColor: "#0077B5",
      },
    ],
  },
  {
    image: Srini_Nanduri,
    name: "Srini Nanduri",
    designation:
      "Republic Services- VP, Head of Data Science AI/ML and Enterprise Analytics",
    socialLinks: [
      {
        iconName: "icofont-facebook",
        url: "https://facebook.com/",
        bgColor: "#4267b2",
      },
      {
        iconName: "icofont-twitter",
        url: "https://twitter.com/",
        bgColor: "#38A1F3",
      },
      {
        iconName: "icofont-instagram",
        url: "https://instagram.com/",
        bgColor:
          "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      },
      {
        iconName: "icofont-linkedin",
        url: "https://linkedin.com/",
        bgColor: "#0077B5",
      },
    ],
  },
  {
    image: Shankar_Vedaraman,
    name: "Shankar Vedaraman",
    designation: "Salesforce- Vice President, Data and Analytics",
    socialLinks: [
      {
        iconName: "icofont-facebook",
        url: "https://facebook.com/",
        bgColor: "#4267b2",
      },
      {
        iconName: "icofont-twitter",
        url: "https://twitter.com/",
        bgColor: "#38A1F3",
      },
      {
        iconName: "icofont-instagram",
        url: "https://instagram.com/",
        bgColor:
          "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
      },
      {
        iconName: "icofont-linkedin",
        url: "https://linkedin.com/",
        bgColor: "#0077B5",
      },
    ],
  },
];

const Speakers: React.FC = () => {
  return (
    <>
      <div className="speakers-area  pb-0">
        <div className="speakers-section">
          <div className="container">
            <div className="speakers-section-title">
              <span>Listen to the keynote speakers</span>
              <h2>Who&apos;s Speaking</h2>
            </div>
          </div>
          <div className="bg-title">Speakers</div>
        </div>

        {/* <div className="coming-soon-speakers">
          <div className="container">
            <h3>
              Hear from some of the brightest minds in data, cloud,
              <br />
              and AI/ML. Full lineup of speakers
            </h3>
            <h1>coming soon.</h1>
          </div>
        </div> */}

        <div className="speakers-row row m-0">
          {SpeakersMemberData &&
            SpeakersMemberData.slice(0, 8).map((value, i) => (
              <div className="col-lg-3 col-sm-6 p-0" key={i}>
                <div className="elkevent-single-speakers">
                  <Image
                    src={value.image}
                    preview={false}
                    alt="speaker"
                    width={"100%"}
                    height={"100%"}
                  />

                  <div className="speakers-content">
                    <h3>{value.name}</h3>
                    <span>{value.designation}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Speakers;
