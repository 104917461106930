import React from "react";
import "./gridBox.scss";
import { Space } from "antd";

interface GridBoxData {
  image: string;
  text: string;
}

interface Props {
  gridBoxData: GridBoxData[];
}

const GridBox = ({ gridBoxData }: Props) => {
  return (
    <div className="gridBoxMain">
      {gridBoxData.map((data, index) => (
        <div className="gridBox">
          <Space size={"large"} direction="horizontal">
            <img
              src={data.image}
              className="gridBoxImage"
              alt="NucleusTeq"
              loading="lazy"
            />
            <b className="gridBoxText paragraphText">{data.text}</b>
          </Space>
        </div>
      ))}
    </div>
  );
};

export default GridBox;
