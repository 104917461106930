import React from "react";
import "./leftText.scss";
import { Col, Row } from "antd";

interface LeftTextItem {
  text: string;
  className: string;
  isList?: boolean;
}

interface Props {
  rightTextImage: string;
  leftTextItems: LeftTextItem[];
}

const LeftText = ({ rightTextImage, leftTextItems }: Props) => {
  return (
    <div>
      <Row justify={"center"} align={"middle"}>
        <Col
          xs={22}
          sm={22}
          md={22}
          lg={12}
          xl={12}
          xxl={12}
          className="ltCenter"
        >
          <img
            src={rightTextImage}
            className="ltImageWidth"
            alt="nucleusteq"
            loading="lazy"
          />
        </Col>
        <Col
          offset={1}
          xs={21}
          sm={21}
          md={21}
          lg={10}
          xl={10}
          xxl={10}
          className="ltCenter ltTextCol"
        >
          <Row>
            {leftTextItems.map((item, index) => (
              <React.Fragment key={index}>
                <Row className="ltTextRow">
                  {item.isList ? (
                    <Col span={24}>
                      <ul className="commonListStyle paragraphText">
                        {item.text ? (
                          <li>{item.text}</li>
                        ) : (
                          <li className="leftTextHide" />
                        )}
                      </ul>
                    </Col>
                  ) : (
                    <p className={item.className}>{item.text}</p>
                  )}
                </Row>
              </React.Fragment>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LeftText;
