import React from "react";
import { Row, Col } from "antd";
// import "./blog.scss";
import BannerBg from "../../assets/images/blog/banner.jpg";
import { ProductBanner } from "../../Components/ProductBanner";
import retail_Card_img1 from "../../assets/images/blog/blog_Card_img1.png";
import retail_Card_img2 from "../../assets/images/blog/blog_Card_img2.png";
import retail_Card_img3 from "../../assets/images/blog/blog_Card_img3.png";
import retail_Card_img4 from "../../assets/images/blog/blog_Card_img4.png";
import retail_Card_img5 from "../../assets/images/blog/blog_Card_img5.png";
import retail_Card_img6 from "../../assets/images/blog/blog_Card_img6.png";
import retail_Card_img7 from "../../assets/images/blog/blog_Card_img7.png";
import retail_Card_img8 from "../../assets/images/blog/blog_Card_img8.png";
import retail_Card_img9 from "../../assets/images/blog/blog_Card_img9.png";
import BlogCards from "../../Components/BlogCards/BlogCards";
import BlogEditTab from "../../Components/BlogEditTab/BlogEditTab";
const BlogEdit = (props: any) => {
  const dataCom = [
    {
      blogCardImg1: retail_Card_img1,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img2,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img3,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img4,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img5,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img6,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img7,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img8,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img9,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
  ];
  return (
    <>
      <Row style={{ width: "100%" }} justify={"center"}>
        <Col span={22}>
          <BlogEditTab />
          </Col>
      </Row>
    </>
  );
};

export default BlogEdit;
