import React from "react";
import "./benefits.scss";
import { Col, Row, Typography } from "antd";
import { benefitCards } from "../../mocks/benefits";
import { benefitCards2 } from "../../mocks/benefits";
import BenefitCards from "../BenefitCards/BenefitCards";
import { NewTitle } from "../NewTitle";

interface Props {
  text: string;
  text2: string;
  texting: string;
}

const Benefits = ({ text, text2, texting }: Props) => {
  const { Text } = Typography;
  return (
    <>
      <div className=" benefit_blocking">
      <NewTitle text={text2} textTitleClassName="textTitleClassName" />
      <div className="benefit_main_Conatiner" style={{ marginTop: "4vh" }}>
        <Row justify={"center"} className="benefit_MainRow">
          {benefitCards2.map(({ img, heading1, benefitText }) => (
            <>
              <Col
                xs={24}
                sm={24}
                lg={12}
                md={24}
                xl={12}
                className="benefit_Column"
              >
                <BenefitCards
                  img={img}
                  heading1={heading1}
                  benefitText={benefitText}
                />
              </Col>
            </>
          ))}
        </Row>
      </div>
    </div>
    <div className=" benefit_blocking">
      <NewTitle text={text} textTitleClassName="textTitleClassName" />
      <div className="benefit_main_Conatiner" style={{ marginTop: "4vh" }}>
        <Row justify={"center"} className="benefit_MainRow">
          {benefitCards.map(({ img, heading1, benefitText }) => (
            <>
              <Col
                xs={24}
                sm={24}
                lg={12}
                md={24}
                xl={12}
                className="benefit_Column"
              >
                <BenefitCards
                  img={img}
                  heading1={heading1}
                  benefitText={benefitText}
                />
              </Col>
            </>
          ))}
        </Row>
          <Row justify={"center"}>
          <Text className="textNucleusteqs">{texting}</Text>
          </Row>
      </div>
    </div>
    </>
  );
};

export default Benefits;
