import { Row, Col, Typography } from "antd";
import "./newTitle.scss";

interface Props {
  text: string;
  textTitleClassName?: string;
}
const { Text } = Typography;

const NewTitle = ({ text, textTitleClassName }: Props) => {
  return (
    <>
      <Row justify={"center"} className="titleRow">
        <Col className="titleCol1">
      <Text className={textTitleClassName}>{text}</Text>
        </Col>
      </Row>
    </>
  );
};

export default NewTitle;

