// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageheading_headingRow {
  padding: 2rem;
}
.pageheading_image {
  height: 1.9rem;
  width: 1.9rem;
}

@media only screen and (min-width: 1920px) and (max-width: 2561px) {
  .pageheading_image {
    height: 4rem;
    width: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/SocialIcons/socialIcon.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGE;EACE,cAAA;EACA,aAAA;AADJ;;AAIA;EAEE;IACE,YAAA;IACA,WAAA;EAFF;AACF","sourcesContent":[".pageheading {\n  &_headingRow {\n    padding: 2rem;\n  }\n\n  &_image {\n    height: 1.9rem;\n    width: 1.9rem;\n  }\n}\n@media only screen and (min-width: 1920px) and (max-width:2561px) {\n  .pageheading {\n  &_image {\n    height: 4rem;\n    width: 4rem;\n  }\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
