// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.communication_communicationHeading {
  color: #fff;
  font-size: 2rem;
  font-family: "Helvetica";
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding-top: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/CommunicationHeading/communicationHeading.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACE,eAAA;EACA,wBAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,iBAAA;AAAN","sourcesContent":[".communication{\n  &_communicationHeading {\n    color: #fff;\n      font-size: 2rem;\n      font-family: \"Helvetica\";\n      display: flex;\n      justify-content: center;\n      font-weight: bold;\n      padding-top: 4rem;\n      \n    \n    }\n  \n   \n  \n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
