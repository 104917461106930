import { useState, useRef } from "react";
import { Carousel as CarouselComponent, Row, Col, Image, Progress } from "antd";
import { Layout } from "antd";
import { CommonButton } from "../CommonButton";
import carouselBg from "../../assets/images/home/slider.png";
import gartnerBg from "../../assets/images/home/gartner-bg.png";
import upLine from "../../assets/images/home/up-line.svg";
import downLine from "../../assets/images/home/down-line.svg";
import leftLine from "../../assets/images/home/left-line.svg";
import rightLine from "../../assets/images/home/right-line.svg";
import { CarouselRef } from "antd/es/carousel";
import { BsArrowRight } from "react-icons/bs";

interface HomePageCarouselDataType {
  id: number;
  title: string;
  subTitle: string;
  bgImage: string;
  redirectionUrl: string;
  link: string;
}

type HomePageCarouselProps = {
  data: HomePageCarouselDataType[];
};

const Carousel = ({ data }: HomePageCarouselProps) => {
  const { Content } = Layout;
  const [currentSlide, setCurrentSlide] = useState(0);
  const slider = useRef<CarouselRef | null>(null);

  const totalItems = data.length;
  const progressPercent = ((currentSlide + 1) / totalItems) * 100;

  const goToNextSlide = () => {
    if (slider && slider.current) {
      slider?.current?.next();
    }
  };

  const goToPrevSlide = () => {
    if (slider && slider.current) {
      slider?.current?.prev();
    }
  };

  return (
    <Layout className="carousel">
      <CarouselComponent
        effect={"fade"}
        dots={false}
        autoplay={true}
        draggable={true}
        autoplaySpeed={3000}
        afterChange={(current) => setCurrentSlide(current)}
        initialSlide={currentSlide}
        ref={(ref) => {
          slider.current = ref;
        }}
      >
        {data.map((item: HomePageCarouselDataType, index) => {
          return (
            <div key={item.id}>
              <div
                className="carouselImage"
                style={{
                  backgroundImage: `url(${carouselBg})`,
                  backgroundColor: "#1D1A36",
                }}
              >
                <Content className="carouselContent">
                  <Row align={"middle"} className="carouselContentRow">
                    <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
                      {item.id === 1 && (
                        <h2 className="carouselSubHeadingLight">Explore our</h2>
                      )}
                      {item.id === 0 && (
                        <h2 className="carouselSubHeadingLight">
                          Building Tomorrow’s
                        </h2>
                      )}

                      <h2 className="carouselHeading">
                        <Image
                          src={item.title}
                          alt="title"
                          preview={false}
                          className="carouselHeadingImage"
                        />
                      </h2>
                      {item.id === 0 && (
                        <h2 className="carouselSubHeadingLight">Today</h2>
                      )}
                      <h1 className="carouselSubHeading">{item.subTitle}</h1>

                      {/* {item.id === 1 && (
                        <h2 className="carouselSubHeadingLight">
                          March 11 - March 13, Orlando
                        </h2>
                      )} */}
                      <Row align={"middle"} justify={"center"}>
                        <CommonButton
                          buttonText="Read More"
                          link={item.link}
                          icon={<BsArrowRight />}
                        />
                      </Row>
                    </Col>

                    <Col
                      xs={0}
                      sm={0}
                      md={11}
                      lg={11}
                      xl={11}
                      xxl={11}
                      className="carouselContentCol"
                    >
                      {[0, 3].includes(item.id) ? (
                        <Image
                          src={item.bgImage}
                          alt="bgImage"
                          preview={false}
                          width={item.id === 0 ? "35vw" : "40vw"}
                        />
                      ) : (
                        <iframe
                          title="bgImage"
                          key={item.id}
                          src={item.bgImage}
                          style={{
                            width: "100%",
                            height: item.id === 1 ? "50vh" : "75vh",
                          }}
                        />
                      )}
                    </Col>

                    <Col className="carouselSideDiv">
                      <Image
                        src={upLine}
                        alt="upLine"
                        preview={false}
                        onClick={() => goToNextSlide()}
                        className="carouselSideDivImg"
                      />
                      <div className="carouselID">0{item.id + 1}</div>
                      <Image
                        src={downLine}
                        alt="downLine"
                        preview={false}
                        onClick={goToPrevSlide}
                        className="carouselSideDivImg"
                      />
                    </Col>

                    <Row
                      justify={"center"}
                      align={"middle"}
                      className="carouselBottomDiv"
                    >
                      <Col span={10}>
                        <Image
                          src={leftLine}
                          alt="leftLine"
                          preview={false}
                          onClick={() => goToPrevSlide()}
                          className="carouselBottomImg"
                        />
                      </Col>

                      <Col span={2}>
                        <div className="carouselID">0{item.id}</div>
                      </Col>

                      <Col span={10}>
                        <Image
                          src={rightLine}
                          alt="rightLine"
                          preview={false}
                          onClick={goToNextSlide}
                          className="carouselBottomImg"
                        />
                      </Col>
                    </Row>
                  </Row>
                </Content>
              </div>
            </div>
          );
        })}
      </CarouselComponent>
      <Row
        align={"middle"}
        justify={"center"}
        style={{ backgroundColor: "#1d1a36", height: "1.5vh" }}
      >
        <Col xs={22} sm={22} md={10} lg={10} xl={10} xxl={10}>
          <Progress
            percent={progressPercent}
            showInfo={false}
            style={{
              marginTop: "1.4vh",
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default Carousel;
