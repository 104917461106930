import React from "react";
import "./industoryBox.scss";
import { Typography } from "antd";

interface Props {
  text: string;
  textClassName?: string;
  boxClassName?: string;
}

const { Text } = Typography;
const IndustoryBox = ({ text, textClassName, boxClassName }: Props) => {
  return (
    <div className={boxClassName}>
      <div className="industory-box">
        <Text className={textClassName}>{text}</Text>
      </div>
    </div>
  );
};
export default IndustoryBox;
