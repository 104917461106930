"use client";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Image, Row } from "antd";
import dateIcon from "../../../assets/events_images/date-icon.svg";
import locationIcon from "../../../assets/events_images/address-icon.svg";

const MainBanner: React.FC = () => {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      {/* <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/embed/bk7McNUjWgw"]}
      /> */}

      <div className="main-banner">
        <video
          loop
          autoPlay
          muted
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -2,
          }}
        >
          <source src="/video/banner-video.mp4" type="video/mp4" />
        </video>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#0000036",
            zIndex: -1,
          }}
        />
        <div className="d-table">
          <div className="d-table-cell">
            <div className="main-banner-content banner-content-center">
              <h1>Data & AI Leadership Summit</h1>
              <h2>Building Tomorrow’s Data Universe Today</h2>

              <Row justify={"center"} style={{ rowGap: "16px" }}>
                <Col>
                  <h3>
                    <Image
                      src={dateIcon}
                      preview={false}
                      alt="date"
                      className="main-banner-icons"
                    />
                    October 17-18, 2024
                  </h3>
                </Col>
                <Col className="main-banner-sec-col">
                  <h3>
                    <Image
                      src={locationIcon}
                      preview={false}
                      alt="date"
                      className="main-banner-icons"
                    />
                    JW Marriott Desert Ridge, Phoenix, Arizona
                  </h3>
                </Col>
              </Row>

              <div className="btn-box">
                <Link to="/events/#registerSection" className="btn btn-primary">
                  Register Now
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Countdown */}
        {/* <Countdown endDate="" /> */}
      </div>
    </>
  );
};

export default MainBanner;
