// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overviewSucessStory_heading {
  border: 1px solid transparent;
  border-image: linear-gradient(146deg, rgb(255, 182, 160) 0%, rgb(240, 89, 42) 26%, rgb(37, 60, 119) 100%);
  border-image-slice: 1;
  padding: 0.6rem;
  margin: 1rem;
  font-weight: 400;
  font-family: Roboto;
  font-size: 1vw;
  text-align: center;
  color: #fff;
}
@media (max-width: 1300px) {
  .overviewSucessStory_heading {
    font-size: 1.7vw;
    padding-left: 5.5vw;
    padding-right: 5.5vw;
  }
}
@media (max-width: 992px) {
  .overviewSucessStory_heading {
    font-size: 2vw;
    padding-left: 5.5vw;
    padding-right: 5.5vw;
  }
}
@media (max-width: 769px) {
  .overviewSucessStory_heading {
    font-size: 2.5vw;
    padding-left: 5.5vw;
    padding-right: 5.5vw;
  }
}
@media (max-width: 576px) {
  .overviewSucessStory_heading {
    font-size: 4vw;
    padding-left: 5.5vw;
    padding-right: 5.5vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/OverviewSuccessStory/overviewSucessStory.scss"],"names":[],"mappings":"AACE;EACE,6BAAA;EACA,yGAAA;EAMA,qBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;AALJ;AAOI;EAjBF;IAkBI,gBAAA;IACA,mBAAA;IACA,oBAAA;EAJJ;AACF;AAKI;EAtBF;IAuBI,cAAA;IACA,mBAAA;IACA,oBAAA;EAFJ;AACF;AAGI;EA3BF;IA4BI,gBAAA;IACA,mBAAA;IACA,oBAAA;EAAJ;AACF;AACI;EAhCF;IAiCI,cAAA;IACA,mBAAA;IACA,oBAAA;EAEJ;AACF","sourcesContent":[".overviewSucessStory {\n  &_heading {\n    border: 1px solid transparent;\n    border-image: linear-gradient(\n      146deg,\n      rgba(255, 182, 160, 1) 0%,\n      rgba(240, 89, 42, 1) 26%,\n      rgba(37, 60, 119, 1) 100%\n    );\n    border-image-slice: 1;\n    padding: 0.6rem;\n    margin: 1rem;\n    font-weight: 400;\n    font-family: Roboto;\n    font-size: 1vw;\n    text-align: center;\n    color: #fff;\n\n    @media (max-width: 1300px) {\n      font-size: 1.7vw;\n      padding-left: 5.5vw;\n      padding-right: 5.5vw;\n    }\n    @media (max-width: 992px) {\n      font-size: 2vw;\n      padding-left: 5.5vw;\n      padding-right: 5.5vw;\n    }\n    @media (max-width: 769px) {\n      font-size: 2.5vw;\n      padding-left: 5.5vw;\n      padding-right: 5.5vw;\n    }\n    @media (max-width: 576px) {\n      font-size: 4vw;\n      padding-left: 5.5vw;\n      padding-right: 5.5vw;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
