import { Row, Col, Space } from "antd";
import "./socialIcon.scss";
import linkdin from "../../assets/images/communication/linkdin.svg";
import twitter from "../../assets/images/communication/twitter.svg";
import insta from "../../assets/images/communication/insta.svg";

const SocialIcons = () => {
  return (
    <Row justify={"center"} className="pageheading_headingRow">
      <Col>
        <Space direction="horizontal" size="middle" align="center">
          <a href="https://www.linkedin.com/company/nucleusteq/">
            <img
              src={linkdin}
              alt="linkdin"
              className="pageheading_image"
              loading="lazy"
            />
          </a>

          <a href="/">
            <img
              src={twitter}
              alt="twitter"
              className="pageheading_image"
              loading="lazy"
            />
          </a>
          <a href="/">
            <img
              src={insta}
              alt="insta"
              className="pageheading_image"
              loading="lazy"
            />
          </a>
        </Space>
      </Col>
    </Row>
  );
};
export default SocialIcons;
