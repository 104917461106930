import logo1 from "../assets/images/customers-and-partners/cutomer1.png";
import logo2 from "../assets/images/customers-and-partners/cutomer2.png";
import logo3 from "../assets/images/customers-and-partners/cutomer3.png";
import logo4 from "../assets/images/customers-and-partners/cutomer4.png";
import logo5 from "../assets/images/customers-and-partners/cutomer5.png";
import logo6 from "../assets/images/customers-and-partners/cutomer6.png";
import logo7 from "../assets/images/customers-and-partners/cutomer7.png";
import logo8 from "../assets/images/customers-and-partners/cutomer8.png";
import logo9 from "../assets/images/customers-and-partners/cutomer9.png";
import logo10 from "../assets/images/customers-and-partners/cutomer10.png";
import logo11 from "../assets/images/customers-and-partners/cutomer11.png";
import logo12 from "../assets/images/customers-and-partners/cutomer12.png";
import logo13 from "../assets/images/customers-and-partners/cutomer13.png";
import logo14 from "../assets/images/customers-and-partners/cutomer14.png";
import logo15 from "../assets/images/customers-and-partners/cutomer15.png";
import logo16 from "../assets/images/customers-and-partners/cutomer16.png";

export const customersAndPartners = [
  {
    tabKey: "1",
    tab: logo1,
    items: [
      {
        title: "A Trusted Partnership with AAA",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "2",
    tab: logo2,
    items: [
      {
        title: "A Trusted Partnership with Petsmart",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "3",
    tab: logo3,
    items: [
      {
        title: "A Trusted Partnership with Visa",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "4",
    tab: logo4,
    items: [
      {
        title: "A Trusted Partnership with American Express",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "5",
    tab: logo5,
    items: [
      {
        title: "A Trusted Partnership with IBM",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "6",
    tab: logo6,
    items: [
      {
        title: "A Trusted Partnership with Fiserv",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "7",
    tab: logo7,
    items: [
      {
        title: "A Trusted Partnership with CircleK",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "8",
    tab: logo8,
    items: [
      {
        title: "A Trusted Partnership for Excellence",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "9",
    tab: logo9,
    items: [
      {
        title: "A Trusted Partnership with Auto Owners",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "10",
    tab: logo10,
    items: [
      {
        title: "A Trusted Partnership with Charles Schwab",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "11",
    tab: logo11,
    items: [
      {
        title: "A Trusted Partnership with Wells Fargo",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "12",
    tab: logo12,
    items: [
      {
        title: "A Trusted Partnership with Ally",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "13",
    tab: logo13,
    items: [
      {
        title: "A Trusted Partnership with Capital One",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "14",
    tab: logo14,
    items: [
      {
        title: "A Trusted Partnership with Kemper",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "15",
    tab: logo15,
    items: [
      {
        title: "A Trusted Partnership with Saleforce",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
  {
    tabKey: "16",
    tab: logo16,
    items: [
      {
        title: "A Trusted Partnership for Excellence",
        text: "At NucleusTeq, we are committed to excellence and have an unwavering promise to consistently deliver value and forge enduring relationships with our clients, partners, and employees. ",
      },
    ],
  },
];
