// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portal_card_mainDiv {
  width: 100%;
  background-color: #1D1A36;
}
.portal_card_Input {
  padding-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
}
.portal_card_main_conatiner {
  width: 100%;
  margin: auto;
  padding: 0px 2vw;
  padding-top: 3rem;
  padding-bottom: 4rem;
}
.portal_card_selectInput div {
  border-radius: 0 !important;
}
.portal_card_openingCard_Row {
  justify-content: center;
}
.portal_card_openingCard_Col {
  padding: 3vh;
  display: flex;
  justify-content: center;
}
.portal_card_input {
  border-radius: 0px;
}

.portal_card_input {
  border: 1px solid #5F5F5F;
  color: #5F5F5F;
  height: 40px;
}

.ant-select-selector {
  border: 1px solid #5F5F5F !important;
  color: #5F5F5F;
  height: 40px !important;
  border-radius: 0 !important;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    margin: 0;
    padding: 0px 14px 0px;
    white-space: initial;
    text-align: start;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/CareerOpenings/careerOpenings.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,yBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AAFJ;AAME;EACE,2BAAA;AAJJ;AAOE;EACE,uBAAA;AALJ;AAQE;EACE,YAAA;EACA,aAAA;EACA,uBAAA;AANJ;AASE;EACE,kBAAA;AAPJ;;AAWA;EACE,yBAAA;EACA,cAAA;EACA,YAAA;AARF;;AAWA;EACE,oCAAA;EACA,cAAA;EACA,uBAAA;EACA,2BAAA;AARF;;AAWA;EACE;IACE,SAAA;IACA,qBAAA;IACA,oBAAA;IACA,iBAAA;EARF;AACF","sourcesContent":[".portal_card {\n  &_mainDiv {\n    width: 100%;\n    background-color: #1D1A36;\n  }\n\n  &_Input {\n    padding-top: 3%;\n    padding-left: 3%;\n    padding-right: 3%;\n  }\n\n  &_main_conatiner {\n    width: 100%;\n    margin: auto;\n    padding: 0px 2vw;\n    padding-top: 3rem;\n    padding-bottom: 4rem;\n\n  }\n\n  &_selectInput div {\n    border-radius: 0 !important;\n  }\n\n  &_openingCard_Row {\n    justify-content: center;\n  }\n\n  &_openingCard_Col {\n    padding: 3vh;\n    display: flex;\n    justify-content: center;\n  }\n\n  &_input {\n    border-radius: 0px;\n  }\n}\n\n.portal_card_input {\n  border: 1px solid #5F5F5F;\n  color: #5F5F5F;\n  height: 40px;\n}\n\n.ant-select-selector {\n  border: 1px solid #5F5F5F !important;\n  color: #5F5F5F;\n  height: 40px !important;\n  border-radius: 0 !important;\n}\n\n@media (max-width: 575px) {\n  .ant-form-item .ant-form-item-label {\n    margin: 0;\n    padding: 0px 14px 0px;\n    white-space: initial;\n    text-align: start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
