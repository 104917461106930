import { Col, Row } from "antd";
import React from "react";
import "./services.scss";

import NewTitle from "../../Components/NewTitle/NewTitle";
import { CommonButton } from "../CommonButton";
import { CommunicationHeading } from "../CommunicationHeading";
import { BsArrowRight } from "react-icons/bs";

type Props = {
  servicedata: {
    heading: string;
    heading1: string;
    heading2: string;
    className1: string;
    className2: string;
    link: string;
  }[];
};

const OurServices = ({ servicedata }: Props) => {
  return (
    <>
      <div className="serviceContainer_mainDiv">
        <NewTitle text="OUR SERVICES" textTitleClassName="textTitleClassName" />

        <div>
          {servicedata.map((e) => {
            return (
              <Row justify={"center"}>
                <Col xs={20}>
                  <div className={e.className1}>{e.heading}</div>

                  <div className={e.className2}>
                    {e.heading1}
                    <br />
                    {e.heading2}
                  </div>
                  <div className="serviceContainer_btnDiv">
                    <CommonButton
                      buttonText="Read More"
                      link={e.link}
                      icon={<BsArrowRight />}
                    ></CommonButton>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OurServices;
