// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edmKeyBenefits {
  position: relative;
}

.edmGradient {
  width: 50vw;
  height: 84vh;
  flex-shrink: 0;
  position: absolute;
  z-index: 0;
  border-radius: 100%;
  opacity: 0.6;
  background: var(--orange-4, rgba(240, 89, 42, 0.4));
  filter: blur(150px);
}

.doubleBottomMargin {
  margin-bottom: 15vh;
}
@media (max-width: 992px) {
  .doubleBottomMargin {
    margin-bottom: 8vh;
  }
}

.textBottomMargin {
  margin-bottom: 8vh;
}
@media (max-width: 992px) {
  .textBottomMargin {
    margin-bottom: 4vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/EDM/edm.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,mBAAA;EACA,YAAA;EACA,mDAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;AAAE;EAFF;IAGI,kBAAA;EAGF;AACF;;AAAA;EACE,kBAAA;AAGF;AAFE;EAFF;IAGI,kBAAA;EAKF;AACF","sourcesContent":[".edmKeyBenefits {\n  position: relative;\n}\n\n.edmGradient {\n  width: 50vw;\n  height: 84vh;\n  flex-shrink: 0;\n  position: absolute;\n  z-index: 0;\n  border-radius: 100%;\n  opacity: 0.6;\n  background: var(--orange-4, rgba(240, 89, 42, 0.4));\n  filter: blur(150px);\n}\n\n.doubleBottomMargin {\n  margin-bottom: 15vh;\n  @media (max-width: 992px) {\n    margin-bottom: 8vh;\n  }\n}\n\n.textBottomMargin {\n  margin-bottom: 8vh;\n  @media (max-width: 992px) {\n    margin-bottom: 4vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
