// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analyticsMain {
  background: #1d1a36;
}

.analyticsMainRow {
  margin-top: 15vh;
}

.analyticsSlantRow1 {
  width: 100%;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Analytics/analytics.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AACF","sourcesContent":[".analyticsMain {\n  background: #1d1a36;\n}\n\n.analyticsMainRow {\n  margin-top: 15vh;\n}\n\n.analyticsSlantRow1 {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
