import React from "react";
import { Row, Col } from "antd";
import "./edm.scss";
import { RightText } from "../../Components/RightText";
import image1 from "../../assets/images/edm/edm_right_image1.png";
import image2 from "../../assets/images/edm/edm_right_image2.png";
import edmBanner from "../../assets/images/services_banners/services_edm.png";
import LiftShift from "../../Components/LiftShift/LiftShift";
import { NTApproach } from "../../Components/NTApproach";
import { KeyBenefits } from "../../Components/KeyBenefits";
import Solution from "../../Components/Solution/Solution";
import OrangeBar from "../../Components/OrangeBar/OrangeBar";
import SuccessIndex from "../../Components/Success/SuccessIndex";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { HeroIndustries } from "../../Components/HeroIndustries";
import { LeftText } from "../../Components/LeftText";
const EDM = (props: any) => {
  FunctionTitles("NucleusTeq | Enterprise Datawarehouse Modernization");

  const benefitItems = [
    {
      text: "10x Performance boost",
    },
    {
      text: "8x Faster migration with 100% accurate transformations",
    },
    {
      text: "50%+ cost savings",
    },
    {
      text: "90% energy savings",
    },
    {
      text: "Zero business interruption",
    },
  ];

  const leftTextItems = [
    {
      text: "Aging data-warehouse systems lacking in agility and efficiency.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Redundant and unnecessary queries.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "The high cost of lift & shift migrations to the cloud.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Missed opportunities for advanced analytics and real-time processing.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Loss of data lineage and subject matter expertise.",
      className: "paragraphText",
      isList: true,
    },
  ];

  return (
    <>
      <div style={{ background: "#1D1A36" }}>
        <Row
          style={{ width: "100%" }}
          justify={"center"}
          className="doubleBottomMargin"
        >
          <HeroIndustries
            image={edmBanner}
            alt={"edmBanner"}
            pageName={"SERVICES  /  ENTERPRISE DATA MODERNIZATION"}
            pageIntro={"ENTERPRISE DATA MODERNIZATION"}
            pageIntroPara={
              "Empower Your Business with NucleusTeq's NuoData Platform"
            }
          />
        </Row>
        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <Col md={21} xs={22} sm={22}>
            <p className="paragraphText">
              In today's data-driven world, staying ahead requires embracing
              innovation. NucleusTeq's Enterprise Data Modernization solution is
              the key to unlocking the true potential of your data. We
              understand the challenges of aging data-warehouse systems, and
              we're here to provide a transformative solution.
            </p>
          </Col>
          {/* <h1 className="commonTitleHead">THE ENTERPRISE CHALLENGE</h1> */}
          {/* <LeftText rightTextImage={image2} leftTextItems={leftTextItems} /> */}
          <Col xl={20} lg={20} md={20} sm={0} xs={0}>
            <iframe
              title="bgImage"
              src={"/all-graph/service/edm/data.html"}
              className="liftShiftIframe"
            />
          </Col>
          <Col xl={0} lg={0} md={0} sm={22} xs={22}>
            <iframe
              title="bgImage"
              src={"/all-graph/service/mobile_view/one/demo/one.html"}
              className="liftShiftIframeMob"
            />
          </Col>
        </Row>
        <LiftShift
          lsHead1="ENTERPRISE MAY FALL PREY TO"
          lsContent="Enterprises deal with the time & cost pressure all the time which may
          push them to an ‘easy’ option of lift & shift of the workload. This
          approach is indeed a path of no return and the entire investment made
          will be written off. It will also make the data unusable for any other
          purposes such as self-service Analytics, Advanced analytics like ML &
          AI & application data consumption."
        />
        <NTApproach />
        <div className="edmKeyBenefits">
          <div className="edmGradient" />
          <KeyBenefits benefitItems={benefitItems} />
        </div>
        {/* <Solution /> */}
        <OrangeBar />
        <SuccessIndex />
      </div>
    </>
  );
};

export default EDM;
