import React from "react";
import "./fyndrAdvantage.scss";
import { Row, Col, Typography } from "antd";
import { NewTitle } from "../NewTitle";
import { BookNowBtn } from "../BookNowBtn";
import { BenefitCards } from "../BenefitCards";
import { fyndrAdvantage } from "../../mocks/fyndrAdvantage";
import { fyndrAdvantage2 } from "../../mocks/fyndrAdvantage";

const { Text } = Typography;

export default function FyndrAdvantage() {
  return (
    <>
      <div className=" benefit_blocking">
        <div className="benefit_main_Conatiner" style={{ marginTop: "4vh" }}>
          <Row justify={"center"} className="benefit_MainRow">
            {fyndrAdvantage.map(({ img, heading1, benefitText }) => (
              <>
                <Col
                  xs={24}
                  sm={24}
                  lg={12}
                  md={24}
                  xl={12}
                  className="benefit_Column"
                >
                  <BenefitCards
                    img={img}
                    heading1={heading1}
                    benefitText={benefitText}
                  />
                </Col>
              </>
            ))}
          </Row>
        </div>
      </div>
      <div className="fyndrAdText_title">
        <NewTitle
          text="Unlock the Fyndr Advantage"
          textTitleClassName="fyndrAdText_textTitleClassName"
        />
        <div className="benefit_main_Conatiner" style={{ marginTop: "4vh" }}>
          <Row justify={"center"} className="benefit_MainRow">
            {fyndrAdvantage2.map(({ img, heading1, benefitText }) => (
              <>
                <Col
                  xs={24}
                  sm={24}
                  lg={12}
                  md={24}
                  xl={12}
                  className="benefit_Column"
                >
                  <BenefitCards
                    img={img}
                    heading1={heading1}
                    benefitText={benefitText}
                  />
                </Col>
              </>
            ))}
          </Row>
          <Row align={"middle"} justify={"center"}>
            <Text className="fyndrAdText">
              Don't miss out on the Fyndr revolution. Join us today and witness
              your business thrive like never before. Contact us now for a free
              demo.
            </Text>
            <BookNowBtn buttonText="Book a Demo Now" modalShow={true} />
          </Row>
        </div>
      </div>
    </>
  );
}
