// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.benefit_blocking {
  width: 100%;
  background-color: #1D1A36;
  padding: 2% 2% 2% 2%;
}
.benefit_main_Container {
  margin: auto;
  padding: 0px 2vw;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.benefit_Column {
  padding: 3vh;
}`, "",{"version":3,"sources":["webpack://./src/Components/Benefits/benefits.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,yBAAA;EACA,oBAAA;AAAJ;AAGE;EACE,YAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AADJ;AAIE;EACE,YAAA;AAFJ","sourcesContent":[".benefit {\n  &_blocking {\n    width: 100%;\n    background-color: #1D1A36;\n    padding: 2% 2% 2% 2%;\n  }\n\n  &_main_Container {\n    margin: auto;\n    padding: 0px 2vw;\n    padding-top: 2rem;\n    padding-bottom: 2rem;\n  }\n\n  &_Column {\n    padding: 3vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
