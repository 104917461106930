// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partnersDiv {
  display: flex;
}
@media (max-width: 768px) {
  .partnersDiv {
    padding: 0 1.5vw;
  }
}

.partnersRowMain {
  display: flex;
  justify-content: center;
  width: 100%;
}

.partnersMainDiv {
  border-radius: 0.56vw;
  background: linear-gradient(137deg, rgba(255, 182, 160, 0.2) 0%, rgba(240, 89, 42, 0.2) 18.54%, rgba(37, 60, 119, 0.2) 100%);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.2);
}
@media (max-width: 768px) {
  .partnersMainDiv {
    padding: 0.5rem;
    border-radius: 1.1vw;
  }
}

.partnersImageCol {
  width: 20%;
  padding: 0.6vw;
}
@media (max-width: 768px) {
  .partnersImageCol {
    width: 50%;
  }
}

.partnersImage {
  border-radius: 0.52vw;
}`, "",{"version":3,"sources":["webpack://./src/Components/Partners/partners.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EAFF;IAGI,gBAAA;EAGF;AACF;;AADA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;AAIF;;AADA;EACE,qBAAA;EACA,4HAAA;EAMA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,0CAAA;AADF;AAEE;EAbF;IAcI,eAAA;IACA,oBAAA;EACF;AACF;;AAEA;EACE,UAAA;EACA,cAAA;AACF;AAAE;EAHF;IAII,UAAA;EAGF;AACF;;AAAA;EACE,qBAAA;AAGF","sourcesContent":[".partnersDiv {\n  display: flex;\n  @media (max-width: 768px) {\n    padding: 0 1.5vw;\n  }\n}\n.partnersRowMain {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.partnersMainDiv {\n  border-radius: 0.56vw;\n  background: linear-gradient(\n    137deg,\n    rgba(255, 182, 160, 0.2) 0%,\n    rgba(240, 89, 42, 0.2) 18.54%,\n    rgba(37, 60, 119, 0.2) 100%\n  );\n  padding: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 2px solid rgba(255, 255, 255, 0.2);\n  @media (max-width: 768px) {\n    padding: 0.5rem;\n    border-radius: 1.1vw;\n  }\n}\n\n.partnersImageCol {\n  width: 20%;\n  padding: 0.6vw;\n  @media (max-width: 768px) {\n    width: 50%;\n  }\n}\n\n.partnersImage {\n  border-radius: 0.52vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
