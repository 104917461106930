import icon1 from "../assets/images/careerPage/peoples_Image1.png";
import icon2 from "../assets/images/careerPage/peoples_Image2.png";
import icon3 from "../assets/images/careerPage/peoples_Image3.png";
import icon4 from "../assets/images/careerPage/peoples_Image4.png";
import icon5 from "../assets/images/careerPage/peoples_Image5.png";
import icon6 from "../assets/images/careerPage/peoples_Image6.png";

const imagesP = [
  {
    img: icon1,
  },
  {
    img: icon2,
  },
  {
    img: icon3,
  },
  {
    img: icon4,
  },
  {
    img: icon5,
  },
  {
    img: icon6,
  },
];

export { imagesP };
