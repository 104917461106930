// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newText {
  color: #fff;
  text-align: center;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 8vw;
  padding-right: 8vw;
  background-color: rgb(29, 26, 54);
}
@media (max-width: 992px) {
  .newText {
    font-size: 2.5vw;
  }
}
@media (max-width: 768px) {
  .newText {
    font-size: 2.5vw;
  }
}
@media (max-width: 576px) {
  .newText {
    font-size: 3.5vw;
    padding-left: 5.5vw;
    padding-right: 5.5vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/NewText/newText.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iCAAA;AACF;AAEE;EAZF;IAaI,gBAAA;EACF;AACF;AAAE;EAfF;IAgBI,gBAAA;EAGF;AACF;AAFE;EAlBF;IAmBI,gBAAA;IACA,mBAAA;IACA,oBAAA;EAKF;AACF","sourcesContent":[".newText {\n  color: #fff;\n  text-align: center;\n  font-size: 1.6vw;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 150%;\n  padding-left: 8vw;\n  padding-right: 8vw;\n  background-color: rgb(29, 26, 54);\n  // padding-bottom: 7vw;\n\n  @media (max-width: 992px) {\n    font-size: 2.5vw;\n  }\n  @media (max-width: 768px) {\n    font-size: 2.5vw;\n  }\n  @media (max-width: 576px) {\n    font-size: 3.5vw;\n    padding-left: 5.5vw;\n    padding-right: 5.5vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
