import React from "react";
import "./rightText.scss";
import { Col, Row } from "antd";
import { BookNowBtn } from "../BookNowBtn";

interface RightTextItem {
  text: string;
  className: string;
  isList?: boolean;
}

interface Props {
  rightTextItems: RightTextItem[];
  rightTextImage: string;
  isBookBtn?: boolean;
  link: string;
}

const RightText = ({
  rightTextItems,
  rightTextImage,
  isBookBtn,
  link,
}: Props) => {
  return (
    <div className="rightTextMain">
      <Row justify={"center"} align={"middle"}>
        <Col
          offset={1}
          xs={21}
          sm={21}
          md={21}
          lg={11}
          xl={11}
          xxl={11}
          className="rightText"
        >
          <Row>
            {rightTextItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.text ? (
                  item.isList ? (
                    <h5 className={item.className}>
                      <ul className="commonListStyle">
                        <li>{item.text}</li>
                      </ul>
                    </h5>
                  ) : (
                    <h4 className={item.className}>{item.text}</h4>
                  )
                ) : (
                  <p className="rightTextHid" />
                )}
              </React.Fragment>
            ))}

            {isBookBtn === true ? (
              <Row align="middle" justify="center">
                <BookNowBtn buttonText="Book a Demo Now" modalShow={true} />
              </Row>
            ) : (
              <p></p>
            )}
          </Row>
        </Col>

        <Col
          xs={22}
          sm={22}
          md={22}
          lg={12}
          xl={12}
          xxl={12}
          className="righttextImageCol"
        >
          <img
            src={rightTextImage}
            className="rightTextImage"
            alt="NucleusTeq"
            loading="lazy"
          />
        </Col>
      </Row>
    </div>
  );
};

export default RightText;
