import React from "react";
import "./productBanner.scss";
import { Row, Col, Space } from "antd";
import { Layout } from "antd";
import { CommonButton } from "../CommonButton";
import { BookNowBtn } from "../BookNowBtn";
import { BsArrowRight } from "react-icons/bs";
// import { BookNowBtn } from "../BookNowBtn";

interface Props {
  title: string;
  link: string;
  title2?: string;
  productBanner: string;
  btnTitle: string;
  btnTitle2: string;
}

const ProductBanner = ({
  title,
  link,
  productBanner,
  title2,
  btnTitle,
  btnTitle2,
}: Props) => {
  const { Content } = Layout;

  return (
    <Layout className="carousel">
      <div>
        <div
          className="productBannerImg"
          style={{
            backgroundImage: `url(${productBanner})`,
            backgroundColor: "#1D1A36",
          }}
        >
          <Content className="carouselContent">
            <Row justify={"center"} align={"middle"} style={{ width: "100%" }}>
              <Col md={22} xs={22}>
                <h1 className="productBannerHead">{title}</h1>
                {title2 && (
                  <Row justify={"center"} align={"middle"}>
                    <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22}>
                      <h5 className="productBannerSubHead">{title2}</h5>
                    </Col>
                  </Row>
                )}
                {title2 ? (
                  <Row align={"middle"} justify={"center"}>
                    <Space direction="horizontal" size={"large"}>
                      <BookNowBtn buttonText={btnTitle} modalShow={true} />
                      {btnTitle2 && (
                        <CommonButton
                          buttonText={btnTitle2}
                          link={link}
                          icon={<BsArrowRight />}
                        />
                      )}
                    </Space>
                  </Row>
                ) : (
                  <Row align={"middle"} justify={"center"}>
                    <CommonButton
                      buttonText="Read More"
                      link={link}
                      icon={<BsArrowRight />}
                    />
                  </Row>
                )}
              </Col>
            </Row>
          </Content>
        </div>
      </div>
    </Layout>
  );
};

export default ProductBanner;
