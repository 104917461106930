// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textTitleClassName {
  color: #fff;
  font-size: 5.1vh;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  z-index: 100;
}

.titleRow {
  width: 100%;
  text-align: center;
}

@media (max-width: 1200px) {
  .textTitleClassName {
    font-size: 5.6224899598vh;
  }
}
@media (max-width: 992px) {
  .textTitleClassName {
    font-size: 2.6rem;
  }
}
@media (max-width: 768px) {
  .textTitleClassName {
    font-size: 1.6rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/NewTitle/newTitle.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE;IACE,yBAAA;EACF;AACF;AACA;EACE;IACE,iBAAA;EACF;AACF;AACA;EACE;IACE,iBAAA;EACF;AACF","sourcesContent":[".textTitleClassName {\n  color: #fff;\n  font-size: 5.1vh;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 100%;\n  z-index: 100;\n}\n\n.titleRow {\n  width: 100%;\n  text-align: center;\n}\n\n@media (max-width: 1200px) {\n  .textTitleClassName {\n    font-size: 5.622489959839357vh;\n  }\n}\n@media (max-width: 992px) {\n  .textTitleClassName {\n    font-size: 2.6rem;\n  }\n}\n@media (max-width: 768px) {\n  .textTitleClassName {\n    font-size: 1.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
