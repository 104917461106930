import React, { ReactNode } from "react";
import "./commonButton.scss";
import { Button, Space } from "antd";

interface Props {
  buttonText: string;
  link: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  icon?: ReactNode;
  target?: string;
}

const CommonButton = ({
  buttonText,
  link,
  style,
  onClick,
  icon,
  target,
}: Props) => {
  return (
    <div>
      <Button
        style={style}
        type="primary"
        htmlType="submit"
        className="commonButton"
        onClick={onClick}
      >
        <a
          href={link}
          target={target}
          className="linkTag"
          rel={target === "_blank" ? "noopener noreferrer" : undefined}
        >
          <span>
            <Space direction="horizontal" size={"small"}>
              {buttonText}
              {icon && <>{icon}</>}
            </Space>
          </span>
        </a>
      </Button>
    </div>
  );
};

export default CommonButton;
