// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/careerPage/ComeWork with NucleusTeq.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comeWith_Main {
  background-color: #253c77;
  display: flex;
  height: 55.4vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.comeWith_Row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.comeWith_Image {
  height: 100%;
  border-radius: 2.7972027972vh;
}

.comeWith_Text {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: 2%;
  align-items: center;
  color: #000;
  font-family: Roboto;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  line-break: auto !important;
}

.comeWith_heading1 {
  color: #fff;
  margin-top: 1vh;
  font-size: 2.8vw;
}

.comeWith_heading6 {
  color: #fff;
  margin-top: 3vh;
  font-weight: 300;
  font-size: 1.5vw;
}

.comeWith_Row2 {
  height: 100%;
}

@media (max-width: 768px) {
  .comeWith_Text {
    font-size: 1.1rem;
    width: 60%;
  }
  .comeWith_heading1 {
    font-size: 2.625rem;
  }
  .comeWith_heading6 {
    font-size: 1.125rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/ComeWith/comeWith.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;EACA,4BAAA;EACA,yDAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AASA;EACE,YAAA;EACA,6BAAA;AANF;;AASA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;AANF;;AASA;EACE,WAAA;EACA,eAAA;EACA,gBAAA;AANF;;AASA;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AANF;;AASA;EACE,YAAA;AANF;;AASA;EACE;IACE,iBAAA;IACA,UAAA;EANF;EASA;IACE,mBAAA;EAPF;EAUA;IACE,mBAAA;EARF;AACF","sourcesContent":[".comeWith_Main {\n  background-color: #253c77;\n  display: flex;\n  height: 55.4vh;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-image: url(\"../../assets/images/careerPage/ComeWork with NucleusTeq.jpg\");\n}\n\n.comeWith_Row {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n// .comeWith {\n//   background-color: #fff;\n//   margin: 2%;\n//   height: 15vh;\n//   border-radius: 2.797202797202797vh;\n// }\n\n.comeWith_Image {\n  height: 100%;\n  border-radius: 2.797202797202797vh;\n}\n\n.comeWith_Text {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  margin-left: 2%;\n  align-items: center;\n  color: #000;\n  font-family: Roboto;\n  font-size: 1.3vw;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 150%;\n  line-break: auto !important;\n}\n\n.comeWith_heading1 {\n  color: #fff;\n  margin-top: 1vh;\n  font-size: 2.8vw;\n}\n\n.comeWith_heading6 {\n  color: #fff;\n  margin-top: 3vh;\n  font-weight: 300;\n  font-size: 1.5vw;\n}\n\n.comeWith_Row2 {\n  height: 100%;\n}\n\n@media (max-width: 768px) {\n  .comeWith_Text {\n    font-size: 1.1rem;\n    width: 60%;\n  }\n\n  .comeWith_heading1 {\n    font-size: 2.625rem;\n  }\n\n  .comeWith_heading6 {\n    font-size: 1.125rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
