// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnalyticsSCDiv {
  display: flex;
  justify-content: center;
  background: #1d1a36;
}

.ASCBoxDiv {
  height: 100%;
  width: 100%;
  padding: 2vh;
  background: #1d1a36;
}

.ASCBoxMain {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ASCBox {
  background: linear-gradient(120deg, rgb(255, 182, 160), rgb(240, 89, 42), rgb(37, 60, 119)) !important;
  padding: 0 1px 1px 1px;
  margin: 1%;
  height: auto;
}
@media (max-width: 769px) {
  .ASCBox {
    margin: 3vh;
  }
}

.ASCHeadRow {
  display: flex;
  font-size: 1.1vw;
  color: #fff;
  font-weight: 500;
  line-height: 120%;
}

.ASCParaRow {
  display: flex;
  font-size: 1.17vw;
  justify-content: center;
}

.ASCParaRow p {
  color: #919dba !important;
  margin-bottom: 0 !important;
  font-size: 1.2vw;
  line-height: 150%;
}
@media (max-width: 1024px) {
  .ASCParaRow p {
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .ASCHeadRow {
    font-size: 1.1rem;
  }
}
.ASCParaRow {
  font-size: 1.125rem;
}

@media (max-width: 990px) {
  .ASCBoxMain {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/GradientCard/gradientCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,sGAAA;EAMA,sBAAA;EACA,UAAA;EACA,YAAA;AAJF;AAKE;EAVF;IAWI,WAAA;EAFF;AACF;;AAKA;EACE,aAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,aAAA;EACA,iBAAA;EACA,uBAAA;AAFF;;AAKA;EACE,yBAAA;EACA,2BAAA;EACA,gBAAA;EACA,iBAAA;AAFF;AAIE;EANF;IAOI,eAAA;EADF;AACF;;AAGA;EACE;IACE,iBAAA;EAAF;AACF;AAGA;EACE,mBAAA;AADF;;AAIA;EACE;IACE,WAAA;EADF;AACF","sourcesContent":[".AnalyticsSCDiv {\n  display: flex;\n  justify-content: center;\n  background: #1d1a36;\n}\n\n.ASCBoxDiv {\n  height: 100%;\n  width: 100%;\n  padding: 2vh;\n  background: #1d1a36;\n}\n\n.ASCBoxMain {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.ASCBox {\n  background: linear-gradient(\n    120deg,\n    rgba(255, 182, 160, 1),\n    rgba(240, 89, 42, 1),\n    rgba(37, 60, 119, 1)\n  ) !important;\n  padding: 0 1px 1px 1px;\n  margin: 1%;\n  height: auto;\n  @media (max-width: 769px) {\n    margin: 3vh;\n  }\n}\n\n.ASCHeadRow {\n  display: flex;\n  font-size: 1.1vw;\n  color: #fff;\n  font-weight: 500;\n  line-height: 120%;\n}\n\n.ASCParaRow {\n  display: flex;\n  font-size: 1.17vw;\n  justify-content: center;\n}\n\n.ASCParaRow p {\n  color: #919dba !important;\n  margin-bottom: 0 !important;\n  font-size: 1.2vw;\n  line-height: 150%;\n\n  @media (max-width: 1024px) {\n    font-size: 1rem;\n  }\n}\n@media (max-width: 1024px) {\n  .ASCHeadRow {\n    font-size: 1.1rem;\n  }\n}\n\n.ASCParaRow {\n  font-size: 1.125rem;\n}\n\n@media (max-width: 990px) {\n  .ASCBoxMain {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
