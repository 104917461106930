import React, { useState } from "react";
import "./letConnect.scss";
import { PopupModal } from "../PopupModal";
import { Col, Row, Input, Form, Button } from "antd";
import connect from "../../assets/images/connect_component/connect_right_img.png";
import { BookNowBtn } from "../BookNowBtn";

interface Props {
  padding: boolean;
}

const LetConnect = ({ padding }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleButtonClick = () => {
    // setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <div
        className="connectMainDiv"
        style={{
          padding: padding ? "7.5rem 0" : "8vh 0",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row className="mainRow">
          <Col
            xs={24}
            sm={16}
            md={16}
            lg={16}
            xl={16}
            xxl={16}
            className="leftDiv"
          >
            <h1 className="leftTitleHead">Let's Connect</h1>
            <Form
              name="basic"
              className="leftFormInput"
              autoComplete="off"
              onFinish={handleButtonClick}
            >
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={11}
                  xl={11}
                  xxl={11}
                  className="leftFormName"
                >
                  <Form.Item
                    name="Name"
                    className="leftFormInput"
                    rules={[
                      { required: true, message: "Please input your Name!" },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: "Name should contain only letters and spaces!",
                      },
                    ]}
                  >
                    <Input
                      className="formInput"
                      placeholder="Full Name"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                  <Form.Item
                    name="Email Address"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email Address!",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid Email Address!",
                      },
                    ]}
                  >
                    <Input
                      className="formInput"
                      placeholder="Work Email"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={11}
                  xl={11}
                  xxl={11}
                  className="leftFormName"
                >
                  <Form.Item
                    name="Subject"
                    rules={[
                      { required: true, message: "Please enter Subject!" },
                    ]}
                  >
                    <Input
                      className="formInput"
                      placeholder="Subject"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                  <Form.Item
                    name="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone Number!",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Phone Number should contain only digits!",
                      },
                      {
                        min: 10,
                        message: "Phone Number should be at least 10 digits!",
                      },
                    ]}
                  >
                    <Input
                      className="formInput"
                      placeholder="Phone Number"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Col span={24}>
                <Form.Item
                  name="Message"
                  rules={[
                    { required: true, message: "Please input your Message!" },
                    {
                      min: 10,
                      message: "Message should be at least 10 characters!",
                    },
                  ]}
                >
                  <Input
                    className="formInput"
                    placeholder="Message"
                    bordered={false}
                  />
                </Form.Item>
              </Col>

              <Form.Item className="btnSubmitItem">
                <BookNowBtn
                  buttonText="Book a Consultation"
                  onClick={handleButtonClick}
                  modalShow={false}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
      <PopupModal
        modalVisible={modalVisible}
        closeModal={handleModalClose}
        heading="Submitted!"
        text="We Will Get Back To You Soon."
      />
    </>
  );
};

export default LetConnect;
