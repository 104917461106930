// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageheading {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3vw;
  text-align: center;
  color: #fff;
  line-height: 1vw;
  padding: 0vw 0vw;
}
.pageheading_sub {
  border: 1px solid transparent;
  border-image: linear-gradient(146deg, rgb(255, 182, 160) 0%, rgb(240, 89, 42) 26%, rgb(37, 60, 119) 100%);
  border-image-slice: 1;
  padding: 0.6rem;
  margin: 1rem;
  font-weight: 400;
  font-family: Roboto;
  font-size: 1vw;
  text-align: center;
  color: #fff;
}
.pageheading_div {
  width: 100%;
}

@media only screen and (max-width: 376px) {
  .pageheading {
    font-size: 4vw;
    line-height: 4.7vw;
    padding: 0vw 4vw;
  }
  .pageheading_sub {
    font-size: smaller;
  }
}
@media only screen and (min-width: 376px) and (max-width: 426px) {
  .pageheading {
    font-size: 3.5vw;
    line-height: 4vw;
    padding: 0.8rem;
  }
  .pageheading_sub {
    font-size: 3vw;
    text-align: center;
  }
}
@media only screen and (min-width: 426px) and (max-width: 1022px) {
  .pageheading {
    font-size: 3vw;
    line-height: 4vw;
    padding: 0.8rem;
  }
  .pageheading_sub {
    font-size: 2.5vw;
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/PageHeading/pageHeading.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AACF;AACE;EACE,6BAAA;EACA,yGAAA;EAMA,qBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;AAJJ;AAME;EACE,WAAA;AAJJ;;AAQA;EACE;IACE,cAAA;IACA,kBAAA;IACA,gBAAA;EALF;EAQA;IACE,kBAAA;EANF;AACF;AASA;EACE;IACE,gBAAA;IACA,gBAAA;IACA,eAAA;EAPF;EAWA;IACE,cAAA;IACA,kBAAA;EATF;AACF;AAYA;EACE;IACE,cAAA;IACA,gBAAA;IACI,eAAA;EAVN;EAeA;IACE,gBAAA;IACA,kBAAA;EAbF;AACF","sourcesContent":[".pageheading {\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 1.3vw;\n  text-align: center;\n  color: #fff;\n  line-height: 1vw;\n  padding: 0vw 0vw;\n\n  &_sub {\n    border: 1px solid transparent;\n    border-image: linear-gradient(\n      146deg,\n      rgba(255, 182, 160, 1) 0%,\n      rgba(240, 89, 42, 1) 26%,\n      rgba(37, 60, 119, 1) 100%\n    );\n    border-image-slice: 1;\n    padding: 0.6rem;\n    margin: 1rem;\n    font-weight: 400;\n    font-family: Roboto;\n    font-size: 1vw;\n    text-align: center;\n    color: #fff;\n  }\n  &_div {\n    width: 100%;\n  }\n}\n\n@media only screen and (max-width: 376px) {\n  .pageheading {\n    font-size: 4vw;\n    line-height: 4.7vw;\n    padding: 0vw 4vw;\n    }\n\n  .pageheading_sub {\n    font-size: smaller;\n  }\n}\n\n@media only screen and (min-width: 376px) and (max-width: 426px) {\n  .pageheading {\n    font-size: 3.5vw;\n    line-height: 4vw;\n    padding: 0.8rem;\n\n  }\n\n  .pageheading_sub {\n    font-size: 3vw;\n    text-align: center;\n  }\n}\n\n@media only screen and (min-width: 426px) and (max-width: 1022px) {\n  .pageheading {\n    font-size: 3vw;\n    line-height: 4vw;\n        padding: 0.8rem;\n\n\n  }\n\n  .pageheading_sub {\n    font-size: 2.5vw;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
