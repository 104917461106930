import { useEffect, useState } from "react";
import { Layout, Row, Col, Space } from "antd";
import { homePageIndustries } from "../../../mocks/homepageIndustries";
import { IndustoryBox } from "../../../Components/IndustoryBox";
import { FAQButton } from "../../../Components/FAQButton";

const Industries = () => {
  const [isAbove1200, setIsAbove1200] = useState(false);
  const [isViewInd, setIsViewInd] = useState(true);

  const firstRowElements = homePageIndustries.slice(0, 4);
  const secondRowElements = homePageIndustries.slice(4);

  useEffect(() => {
    setIsViewInd(false);
    if (typeof window != "undefined") {
      const handleResize = () => {
        setIsAbove1200(window.innerWidth > 1400);
        setIsViewInd(true);
      };

      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [typeof window != "undefined"]);

  const handleLinkClick = (link: any) => {
    window.location.href = link;
    window.scrollTo(0, 0);
  };

  return (
    <Layout className="industries-section">
      <Row align={"middle"} justify={"center"} className="container-industries">
        <h2>INDUSTRIES</h2>

        <Row
          align={"middle"}
          justify={"center"}
          className="industries-main-row"
        >
          <Col xs={22} sm={22} md={22} lg={7} xl={6} xxl={6}>
            {/* <h4>We Empower</h4> */}

            <FAQButton />
          </Col>

          <Col xs={22} sm={22} md={22} lg={16} xl={16} xxl={16} offset={1}>
            <h3> Accelerating Innovation and Driving Results</h3>
            <span>
              Our solutions empower businesses in all major industries to
              innovate, optimize operations, and achieve measurable results.
              Tailored for sectors like finance, healthcare, and manufacturing,
              we address unique challenges and provide the expertise needed to
              excel in today's competitive landscape.
            </span>
          </Col>
        </Row>
        <Row className="industries-industoryBox">
          {isViewInd && (
            <Space direction="vertical" size={"large"}>
              <Row gutter={[0, 16]} justify={"center"}>
                {firstRowElements.map(
                  ({
                    text,
                    IndustoryTextClassName,
                    IndustoryBoxClassName,
                    link,
                  }) => (
                    <Col
                      key={text}
                      xs={24}
                      sm={12}
                      md={6}
                      span={6}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleLinkClick(link)}
                      >
                        <IndustoryBox
                          textClassName={IndustoryTextClassName}
                          boxClassName={IndustoryBoxClassName}
                          text={text}
                        />
                      </div>
                    </Col>
                  )
                )}
              </Row>
              <Row gutter={[0, 16]} justify={"center"}>
                {secondRowElements.map(
                  ({
                    text,
                    IndustoryTextClassName,
                    IndustoryBoxClassName,
                    link,
                  }) => (
                    <Col
                      key={text}
                      xs={24}
                      sm={12}
                      md={6}
                      span={6}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleLinkClick(link)}
                      >
                        <IndustoryBox
                          textClassName={IndustoryTextClassName}
                          boxClassName={IndustoryBoxClassName}
                          text={text}
                        />
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </Space>
          )}
        </Row>
      </Row>
    </Layout>
  );
};

export default Industries;
