import React from "react";
import "./careerOpenings.scss";
import { Col, Row, Form, Input } from "antd";
import { NewTitle } from "../NewTitle";
import Cards from "../PortalCards/PortalCards";
interface Props {
  cards: {
    location: string;
    role: string;
    salary: string;
    position: string;
    image1: string;
    image2: string;
    image3: string;
  }[];
  text: string;
}
const CareerOpenings = ({ cards, text }: Props) => {
  return (
    <div className="portal_card_mainDiv">
     <NewTitle text={text} textTitleClassName="textTitleClassName"/>
      <div className="container">
        <Row justify={"space-evenly"} style={{marginTop:"3vh"}}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            className="leftFormInput"
            autoComplete="off"
          >
            <Row style={{ width: "100%" }}>
              <Col
                xs={22}
                sm={22}
                md={22}
                lg={10}
                xl={10}
                xxl={10}
                className="leftFormName"
              >
                <Form.Item
                  name="Name"
                  className="leftFormInput"
                  rules={[
                    { required: true, message: "Please input your Name!" },
                  ]}
                >
                  <Input
                    className="formInput"
                    placeholder="Departments"
                    bordered={false}
                    name="Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={22} sm={22} md={22} lg={10} xl={10} xxl={10}>
                <Form.Item
                  name="Email Address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email Address!",
                    },
                  ]}
                >
                  <Input
                    className="formInput"
                    placeholder="Location"
                    bordered={false}
                    name="Email Address"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>
      <div className="portal_card_main_conatiner">
        <Row className="portal_card_openingCard_Row">
          {cards.map((card, i) => (
            <>
              <Col
                xs={24}
                sm={22}
                lg={22}
                md={22}
                xl={12}
                className="portal_card_openingCard_Col"
              >
                <Cards
                  image1={card.image1}
                  location={card.location}
                  role={card.role}
                  image2={card.image2}
                  position={card.position}
                  salary={card.salary}
                  image3={card.image3}
                />
              </Col>
            </>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default CareerOpenings;