const dummyText = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`;
export const homepageSuccessStories = {
  image: "/assets/images/success stories.png",
  description: dummyText,
  cards: [
    {
      text: "Sentiment Analysis of preferred customers for major US airline",
    },
    {
      text: "Digital Transformation for one of the largest oil and Gas company in the world",
    },
    {
      text: "Realtime anomaly detection & network monitoring for major telecommunications company in USA",
    },
  ],
};

export const successStories = [
  {
    id: 1,
    image: "/assets/images/success-stories/1.png",
    title: `Modernization of Enterprise reporting (Netezza) to AWS for a major housing finance company`,
    aboutCustomer: `About the customer – Customer is one of the top federal housing finance company`,
    customerChallenge: [
      `Enterprise Data Warehouse (EDW) on Netezza to be migrated to AWS Cloud.`,
      `Design and deliver a comprehensive solution implementing best practice recommendations in line with the enterprise data lake goals.`,
      `Replicate the current Netezza snapshotting process in the EDW environment into the AWS environment`,
      `Provide best practice recommendations in the areas of metadata capture, lineage & audit`,
    ],
    solution: [
      `Migrated the snapshotting process from Netezza to AWS based environment`,
      `Migrated base tables in 3NF from IDS (Integrated Data Store) to S3 and update them on an ongoing basis with incremental updates`,
      `Implemented identified data migration patterns in a way that it can be reused for tables conforming to similar patterns`,
      `Identified and migrated major percentage of the EDW users and their associated queries that qualify as long-running workloads to S3`,
      `Comprehensive architecture on AWS addressing the enterprise data lake goals with best practice recommendations in the areas of metadata capture, lineage, storage & audit`,
    ],
    delivery: [
      `Offload of the major percentage of Netezza based EDW (data and workloads) to AWS based environment`,
      `Free the EDW from snapshotting process by migrating the process to AWS`,
      `Use of cloud offering elasticity to the process for future scaling`,
    ],
  },
  {
    id: 2,
    image: "/assets/images/success-stories/13.png",
    title: `Sentiment Analysis of preferred customers for major US airline`,
    aboutCustomer: ``,
    customerChallenge: [
      `Identify the sentiment & Change the way customers perceive the airline, both in domestic and international sectors.`,
      `Sentiments captured from Facebook, Twitter, Email Support, Call Center Support, Website, Travel Booking sites, etc.`,
      `Connect all together to derive relevance w.r.t. passenger, travel dates, times, sectors, planes, staff etc.`,
    ],
    solution: [
      `Performed aspect-based sentiment analysis and identified potential areas of improvement`,
      `Worked on linkage with other datasets to identify the root causes of the problem and suggest system improvement changes`,
      `Provide high level recommendations based on past actions taken`,
    ],
    delivery: [
      `More than 80% accuracy in identifying the relevant sentiments that impacted the customer’s satisfaction.`,
      `Effectively provide recommendations to provide recommended action for specific cases.`,
    ],
  },

  {
    id: 3,
    image: "/assets/images/success-stories/12.png",
    title: `Digital Transformation for one of the largest oil and Gas company in the world.`,
    aboutCustomer: `About the customer – Customer is one of the largest Oil & Gas company in the world`,
    customerChallenge: [
      `Fragmented functions lead to significant delays and less than optimal recommendations`,
      `The company did not have a unified digital strategy leading to significant delay in execution`,
      `Insights were not leading to effective actions`,
    ],
    solution: [
      `Defined & Implemented Azure based cloud platform strategy leveraging Industry Gen 4 reference Architecture`,
      `Implemented 10+ Insights that would lead to smart decisions such as operations optimization, Real-time Monitoring etc.`,
      `IoT streaming & real-time analytics for predictive failures`,
    ],
    delivery: [
      `15% more yield through advanced Machine Learning recommendations`,
      `10% faster time to market while leveraging the cloud based digital platform.`,
      `12-15% reduction in failures & incidents through predictive monitoring`,
    ],
  },

  {
    id: 4,
    image: "/assets/images/success-stories/11.png",
    title: `Realtime anomaly detection & network monitoring for major telecommunications company in USA.`,
    aboutCustomer: `About the customer – Customer is one of the major telecommunications companies in USA`,
    customerChallenge: [
      `Detect router issues, tower failure & call drops in real-time`,
      `Identify and route the traffic to another (working) infrastructure.`,
      `Improve customer experience`,
      `Feedback loop to customer and link it with marketing`,
    ],
    solution: [
      `NucleusTeq created an end-to-end pipeline where the network data is ingested, transformed and dynamically analyzed via Machine Learning Algorithms to identify anomalies and sent to appropriate channel for further action`,
      `Real-time routing and monitoring of the network.`,
    ],
    delivery: [
      `Unsupervised algorithm dynamically updates itself and identifies anomalies. Thus, it always considers the latest dynamics of the environment`,
      `Real-time monitoring & optimization of the infrastructure`,
      `Enhanced customer experience`,
    ],
  },

  {
    id: 5,
    image: "/assets/images/success-stories/10.png",
    title: `Enterprise Data modernization of one of world’s largest credit card company from Teradata to On-Premise Big-Data Lake`,
    aboutCustomer: `About the customer – Customer is one of the major telecommunications companies in USA`,
    customerChallenge: [
      `Teradata was the only platform that was catering to the enterprise reporting, This added to significant annual costs for the customer`,
      `Data was in silos and each BU had its own process to exchange data with other Bus, This added significant delays in developing products that required enterprise-level data.`,
      `Lack of self-service of the enterprise data leading to higher time to market, costs & redundancies.`,
    ],
    solution: [
      `Centralized, Standardized & Certified Data for Consistency`,
      `Democratize Data through self-service API’s while eliminating redundancy`,
      `Ensuring compliance to Enterprise data security guidelines`,
      `Maximize application/data Reusability & Globalization`,
    ],
    delivery: [
      `Comprehensive Metadata Management, Security Data Quality & Governance`,
      `Single source of truth`,
      `Data integrity & quality with financial B&C`,
      `Eliminated redundancy by 47%`,
      `Reduced Attribute SORs by 72%`,
      `4 Petabyte data migrated`,
      `18 Months total delivery time`,
      `35+ M in operations savings per year`,
    ],
  },
  {
    id: 6,
    image: "/assets/images/success-stories/9.png",
    title: `End-to-End Data Lake Setup & Migration of Netezza reporting platform to Azure for a Major retailer`,
    aboutCustomer: ``,
    customerChallenge: [
      `Lack of centralization, standardization and self-service of the enterprise data leading to higher time to market, costs & redundancies`,
      `Point to Point Integrations leading to significant cost, lack of governance, data quality & missing single version of “truth”.`,
    ],
    solution: [
      `Centralized, Standardized & Certified Data for Consistency`,
      `Democratize Data through self-service API’s while eliminating redundancy`,
      `Ensuring compliance to Enterprise data security guidelines`,
      `Maximize application/data Reusability & Globalization`,
    ],
    delivery: [
      `Data Lake on Azure`,
      `Comprehensive Metadata Management, Security & Governance`,
      `Self Service Data Management Portal`,
      `Single source of truth`,
      `Data integrity & quality with financial B&C`,
      `Eliminated redundancy by 40%`,
      `Reduced IT Infra Cost by 52%`,
      `Reduced project cost by 45%`,
      `Expedited project delivery by 20% by leveraging Offshore`,
      `16 SORs`,
      `100 TB Data migrated`,
      `Customer & Services BUs`,
      `3+ M in operations savings per year`,
      `50% more customer retention & repeat business expected`,
      `12-15% increase in Offers based sales`,
    ],
  },

  {
    id: 7,
    image: "/assets/images/success-stories/8.png",
    title: `Real-time insights and recommendation engine for one of the largest pharmaceuticals company in the world`,
    aboutCustomer: `About the customer – One of the largest manufactures & distributors of prescription pharmaceuticals in several therapeutic areas, including cancer, HIV/AIDS, cardiovascular disease, diabetes, hepatitis, rheumatoid arthritis and psychiatric disorders.`,
    customerChallenge: [
      `An unmanaged on-prem data lake running the risk of becoming a data swamp, needed to be optimized & migrated to cloud`,
      `Unusable insights & recommendations for the enterprise users`,
      `Unable to efficiently utilize the provisioned cluster`,
      `Enable An organized data lake supported by a curated semantic layer reducing redundancy and confusion.`,
      `Breakdown silos and enable a consolidated reporting platform powered by Hadoop`,
    ],
    solution: [
      `Partnered with the customers stakeholders across IT and business to develop a comprehensive roadmap and strategy to optimize and migrate to the cloud`,
      `Defined a multi-year phased cloud migration plan for success and execution`,
      `Enabled and trained internal teams to get cloud and big data-enabled at the end-user level`,
      `Put in execution optimization, data harmonization and migration to the cloud`,
      `Converged siloed analytics into a uniform converged platform expanding access for easy discovery and multi-faceted analysis.`,
    ],
    delivery: [
      `Business users are able to perform wider analysis across multiple channels unhindered by silos`,
      `A flexible and elastic data platform in the cloud capable of catering to the diverse and dynamic needs of the enterprise for a significantly smaller budget footprint`,
      `Cost savings and effort reduction enabled by dynamic AWS native apps`,
      `One stop metrics repository for senior management review and chargebacks`,
    ],
  },

  {
    id: 8,
    image: "/assets/images/success-stories/4.png",
    title: `Create new liability insurance models based on IOT sensor data for a leading business Insurer.`,
    aboutCustomer: `About the customer – Customer is one of the top 3 B2B broker in the world`,
    customerChallenge: [
      `Create a unified risk-based model for real-time underwriting & presentment of Insurance to a Business customer`,
      `Lack of centralization, standardization and self-service of the enterprise data leading to higher time to market, costs & redundancies`,
      `Point to Point Integrations leading to significant cost, lack of governance, data quality & missing single version of “truth”.`,
    ],
    solution: [
      `Centralized, Standardized & Certified Data for Consistency`,
      `Democratize Data through self-service API’s while eliminating redundancy`,
      `Ensuring compliance to Enterprise data security guidelines`,
      `Created a data model that accounted for various parameters such as IOT sensor data of the field worker, probability of injury, pattern analysis etc..`,
    ],
    delivery: [
      `Data Lake on Azure for unified data management & single source of truth`,
      `Data integrity & quality with financial B&C`,
      `Real-time (100 TPS) system for Insurance underwriting & premium processing`,
      `Eliminated system redundancy by 40%`,
    ],
  },

  {
    id: 9,
    image: "/assets/images/success-stories/6.png",
    title: `Detection of Intent to Fraud using NLP for one of world’s largest banks`,
    aboutCustomer: `About the customer – Customer is one of the top 10 largest banks in the world.`,
    customerChallenge: [
      `Bank lost billions of dollars in penalties and trading losses`,
      `Challenge posed was to detect the intent to commit fraud proactively by monitoring communications in order to avoid penalties and damage to reputation`,
      `Large team of ~30 people were dedicated to ensure compliance using keyword search but face too many false positives`,
    ],
    solution: [
      `Delivered a semi-supervised learning-based NLP solution to aid in detecting fraudulent activity`,
      `Coordinated multiple rounds of reviews to improve solution accuracy across 10 categories`,
      `Attained expected precision in SLA within 2 months`,
      `Implemented solution on Stanford NLP, Spark and Tachyon`,
    ],
    delivery: [
      `Delivered expected accuracy to detect email messages with scores for fraud categories`,
      `Elimination of a large part of the data via identification of non-relevant messages using learning algorithms`,
      `Defined templates unique to each type of fraud based on presence of different entities (persons, places etc)`,
      `Created an approach to profile all the users in the data by means of graph analytics algorithms`,
    ],
  },

  {
    id: 10,
    image: "/assets/images/success-stories/5.png",
    title: `Automation of Insurance – Sales, Underwriting & Services process for Leading Insurance company with $135 B in assets`,
    aboutCustomer: ``,
    customerChallenge: [
      `Mandate to Increase revenues & customer retention`,
      `Reduce customer support costs`,
      `Mandate to Improve customer experience`,
    ],
    solution: [
      `Billing Inquiry Analysis`,
      `Insurance Quote Lookup`,
      `Knowledge Push`,
      `Automated Documentation`,
      `Agent Groups: Customer Service, Sales, Underwriting, Processing`,
    ],
    delivery: [
      `72% Manual Effort Automated`,
      `6 million in annual savings`,
      `2 weeks CSR training time reduction`,
      `20% AHT reduction`,
      `6 months implementation`,
    ],
  },

  {
    id: 11,
    image: "/assets/images/success-stories/3.png",
    title: `Back Office RPA Solution for American Multinational investment`,
    aboutCustomer: `Back Office RPA Solution for American Multinational investment bank and financial services company, also Big 4 Bank with around 10.73% of American bank deposits`,
    customerChallenge: [
      `Higher waiting time for Mortgage Processing`,
      `Mandate to reduce errors and paper work in the process`,
      `Highly manual process, with redundant and repetitive steps`,
      `Mandate to Improve Customer Satisfaction Rating`,
    ],
    solution: [
      `Through RPA, the manual & repetitive entries in application processing were automated`,
      `The paper work was digitized through OCR, easier online application & verification, faster underwriting and escalation processing; and reduced process time to a competitive 1 minute from 2 hours approvals`,
      `Process Transformation through Digitization and Process Improvement efforts led to reduced errors`,
    ],
    delivery: [
      `AHT improved by a record 35%`,
      `Errors reduced to 0.005%`,
      `Increased loan origination revenue by $12 Million`,
      `AHT reductions and over 25% increased online applications in 2018`,
      `Strongest consumer score in the bank’s history`,
    ],
  },

  {
    id: 12,
    image: "/assets/images/success-stories/2.png",
    title: `Big-Data Lake setup on Azure Cloud for top 3 Pet Retailers in the world`,
    aboutCustomer: `About the customer – Customer is one of the top 3 pet retailers in the world`,
    customerChallenge: [
      `Lack of centralization, standardization and self-service of the enterprise data leading to higher time to market, costs & redundancies`,
      `Projects based development as opposed to “Platform / Products” thinking leading to significant cost & redundancy.`,
    ],
    solution: [
      `Defined & Created core platform components on the Azure environment to support Scheduling, Ingestion, Metadata Management, Data quality etc.`,
      `Created end-to-end monitoring & operations management platform to ensure optimal use of the platform`,
      `Created Continuous Integration, Continuous Deployment, Automated testing capabilities for all federated applications. Extensive use of enterprise Jenkins.`,
      `Created data consumption layer through microservices architecture using Azure Kubernetes Services.`,
    ],
    delivery: [
      `Unified single version of truth data lake for the enterprise`,
      `5X faster time to market for new reports & data capabilities`,
      `40% reduction in operating expenses through cloud adoption & management.`,
    ],
  },
];

export const fetchSuccessStory = (id="1") => {
  const convertStringToInt = parseInt(id);
   const data = successStories.filter((story) => convertStringToInt === story.id)
   return data;
}