import React from "react";
import { Row } from "antd";
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";
import TermsBanner from "../../Components/TermsBanner/TermsBanner";
import { Policy } from "../../Components/Policy";
import { PolicyBanner } from "../../Components/PolicyBanner";

const Terms = () => {
  return (
    <>
      <div className="uiMain">
        <PolicyBanner />
        <Row justify={"center"} align={"middle"} className="autoMarginTop">
          <Policy />
          <CustomersAndPartners />
        </Row>
      </div>
    </>
  );
};

export default Terms;
