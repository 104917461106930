import { Row, Col } from "antd";
import "./leadershipComponent.scss";
import leadership_ashish from "../../assets/images/leadership/leadership_ashish.png";
import leadership_will from "../../assets/images/leadership/leadership_will.png";
import leadership_kiran from "../../assets/images/leadership/leadership_kiran.png";
import leadership_swapnil from "../../assets/images/leadership/leadership_swapnil.png";
import leadership_ankita from "../../assets/images/leadership/leadership_ankita.png";
import leadership_deepti from "../../assets/images/leadership/leadership_deepti.png";
import leadership_kristen from "../../assets/images/leadership/leadership_kristen.png";
import leadership_manjunath from "../../assets/images/leadership/leadership_manjunath.png"

import CirleImage from "../../Components/CircleImage/CircleImage";
import LeadershipIntroLeft from "../../Components/LeadershipIntroLeft/LeadershipIntroLeft";
import LeadershipIntroRight from "../../Components/LeadershipIntroRight/LeadershipIntroRight";
import leadershipIntro from "../../mocks/leadershipIntro";

const LeadershipComponent = (props: any) => {
  return (
    <>
      <div className="leadershipcomp_dekstopview">
        <Row justify="center" style={{ paddingBottom: "2.5vw" }}>
          <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
            <CirleImage
              img={leadership_ashish}
              routeLinkedin="https://www.linkedin.com/in/ashish-b-baghel/"
              routeMail="ashish@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={14} lg={16} xxl={16} xl={17} md={16} style={{textAlign:"justify"}}>
            <LeadershipIntroLeft
              name="ASHISH BAGHEL"
              post="Founder & CEO"
              about={leadershipIntro.ashishIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "2.5vw" }}>
          <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
            <CirleImage
              img={leadership_will}
              routeLinkedin="https://www.linkedin.com/in/willsellenraad/"
              routeMail="will@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={14} lg={16} xxl={16} xl={17} md={16}>
            <LeadershipIntroLeft
              name="WILL SELLENRAAD"
              post="EVP, CRO"
              about={leadershipIntro.willIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "2.5vw" }}>
          <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
            <CirleImage
              img={leadership_kiran}
              routeLinkedin="https://www.linkedin.com/in/kiranramireddi/"
              routeMail="kiran@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={14} lg={16} xxl={16} xl={17} md={16}>
            <LeadershipIntroLeft
              name="KIRAN RAMIREDDI"
              post="Executive Vice President"
              about={leadershipIntro.kiranIntro}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ paddingBottom: "2.5vw" }}>
          <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
            <CirleImage
              img={leadership_kristen}
              routeLinkedin="https://www.linkedin.com/in/kristenkalupski"
              routeMail="kristen@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={14} lg={16} xxl={16} xl={17} md={16}>
            <LeadershipIntroLeft
              name="KRISTEN KALUPSKI"
              post="CMO"
              about={leadershipIntro.kristenIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "2.5vw" }}>
          <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
            <CirleImage
              img={leadership_deepti}
              routeLinkedin="https://www.linkedin.com/in/deepti-baghel/"
              routeMail="deepti@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={14} lg={16} xxl={16} xl={17} md={16}>
            <LeadershipIntroLeft
              name="DEEPTI BAGHEL"
              post="Vice President"
              about={leadershipIntro.deeptiIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "2.5vw" }}>
          <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
            <CirleImage
              img={leadership_swapnil}
              routeLinkedin="https://www.linkedin.com/in/connect-swapnil/"
              routeMail=""
            />
          </Col>
          <Col xs={24} sm={14} lg={16} xxl={16} xl={17} md={16}>
            <LeadershipIntroLeft
              name="SWAPNIL DESHMUKH"
              post="Senior Director of Information & Cloud Technology"
              about={leadershipIntro.swapnilIntro}
            />
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
            <CirleImage
              img={leadership_manjunath}
              routeLinkedin="https://www.linkedin.com/in/manjunath-siddaiah-169a669/"
              routeMail="manjunath@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={14} lg={16} xxl={16} xl={17} md={16}>
            <LeadershipIntroLeft
              name="MANJUNATH SIDDAIAH"
              post="Senior Director – Strategic Accounts"
              about={leadershipIntro.majunathIntro}
            />
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={10} lg={8} xxl={8} xl={7} md={8}>
            <CirleImage
              img={leadership_ankita}
              routeLinkedin="https://www.linkedin.com/in/ankita-s-sharma/"
              routeMail="ankita@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={14} lg={16} xxl={16} xl={17} md={16}>
            <LeadershipIntroLeft
              name="ANKITA SHARMA"
              post="Director - Human Empowerment"
              about={leadershipIntro.ankitaIntro}
            />
          </Col>
        </Row>

        
      </div>

      {/*Mobile view*/}

      <div className="leadershipcomp_mobileview">
        <Row justify="center" style={{ paddingBottom: "10vw" }}>
          <Col xs={24} sm={10}>
            <CirleImage
              img={leadership_ashish}
              routeLinkedin="https://www.linkedin.com/in/ashish-b-baghel/"
              routeMail="ashish@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={10}>
            <LeadershipIntroLeft
              name="ASHISH BAGHEL"
              post="Founder & CEO"
              about={leadershipIntro.ashishIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "10vw" }}>
          <Col xs={24} sm={10}>
            <CirleImage
              img={leadership_will}
              routeLinkedin="https://www.linkedin.com/in/willsellenraad/"
              routeMail="will@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={10}>
            <LeadershipIntroLeft
              name="WILL SELLENRAAD"
              post="EVP, CRO"
              about={leadershipIntro.willIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "10vw" }}>
          <Col xs={24} sm={10}>
            <CirleImage
              img={leadership_kiran}
              routeLinkedin="https://www.linkedin.com/in/kiranramireddi/"
              routeMail="kiran@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={10}>
            <LeadershipIntroLeft
              name="KIRAN RAMIREDDI"
              post="Vice President"
              about={leadershipIntro.kiranIntro}
            />
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={10}>
            <CirleImage
              img={leadership_deepti}
              routeLinkedin="https://www.linkedin.com/in/deepti-baghel/"
              routeMail="deepti@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={10}>
            <LeadershipIntroLeft
              name="DEEPTI BAGHEL"
              post="Vice President"
              about={leadershipIntro.deeptiIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "10vw" }}>
          <Col xs={24} sm={10}>
            <CirleImage
              img={leadership_swapnil}
              routeLinkedin="https://www.linkedin.com/in/connect-swapnil/"
              routeMail=""
            />
          </Col>
          <Col xs={24} sm={10}>
            <LeadershipIntroLeft
              name="SWAPNIL DESHMUKH"
              post="Director Information & Cloud Technology"
              about={leadershipIntro.swapnilIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "10vw" }}>
          <Col xs={24} sm={10}>
            <CirleImage
              img={leadership_ankita}
              routeLinkedin="https://www.linkedin.com/in/ankita-s-sharma/"
              routeMail="ankita@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={10}>
            <LeadershipIntroLeft
              name="ANKITA SHARMA"
              post="Associate Director - Human Empowerment"
              about={leadershipIntro.ankitaIntro}
            />
          </Col>
        </Row>

        <Row justify="center" style={{ paddingBottom: "10vw" }}>
          <Col xs={24} sm={10}>
            <CirleImage
              img={leadership_kristen}
              routeLinkedin="https://www.linkedin.com/in/kristenkalupski"
              routeMail="kristen@nucleusteq.com"
            />
          </Col>
          <Col xs={24} sm={10}>
            <LeadershipIntroLeft
              name="KRISTEN KALUPSKI"
              post="CMO"
              about={leadershipIntro.kristenIntro}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default LeadershipComponent;
