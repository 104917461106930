import React from "react";
import "./analytics.scss";
import { Row, Col } from "antd";
import { RightText } from "../../Components/RightText";
import OrangeBar from "../../Components/OrangeBar/OrangeBar";
import SuccessIndex from "../../Components/Success/SuccessIndex";
import AnalyticRight from "../../assets/images/analytic/analytic_right_img.png";
import AnalyticBanner from "../../assets/images/services_banners/services_analytics.jpg";
import AnalyticSlant1 from "../../assets/images/analytic/analytic_slant1.svg";
import AnalyticSlant2 from "../../assets/images/analytic/analytic_slant2.svg";
import AnalyticSlant3 from "../../assets/images/analytic/analytic_slant3.svg";
import AnalyticSlant4 from "../../assets/images/analytic/analytic_slant4.svg";
import AnalyticsGridBox from "../../Components/AnalyticsGridBox/AnalyticsGridBox";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { HeroIndustries } from "../../Components/HeroIndustries";
import GradientCard from "../../Components/GradientCard/GradientCard";
const Analytics = (props: any) => {
  FunctionTitles("NucleusTeq | Analytic Services");
  const rightTextItems = [
    {
      text: "At NucleusTeq, we take an outcome-driven approach to analytics, ensuring that your data strategy yields both short-term wins and long-term value. We provide clear, strategic roadmaps that lay out the anticipated return on investment, helping enterprises prioritize their data initiatives.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "But data alone isn't enough. Machine learning, deep learning, and AI are only as intelligent as the data and insights that fuel them. That's where NucleusTeq steps in. We specialize in unlocking powerful analytics insights, tapping into data that organizations often didn't even know existed. With our services, you'll transform your data into a strategic asset that drives informed, smarter decisions.",
      className: "paragraphText",
      isList: true,
    },
  ];

  const analyticsData = [
    {
      image: AnalyticSlant1,
      heading: "Data and Analytics Strategy",
      text: "We’ll map your analytics initiatives to quantifiable business outcomes with a data-driven approach.",
      lgValue: 5,
    },
    {
      image: AnalyticSlant2,
      heading: "Data Discovery and Augmentation",
      text: "We will augment your assets with third-party data and predictive analytics.",
      lgValue: 5,
    },
    {
      image: AnalyticSlant3,
      heading: "Data Management and Optimization",
      text: "We will assist with governance, monetization and compliance.",
      lgValue: 5,
    },
    {
      image: AnalyticSlant4,
      heading: "Analytics Democratization",
      text: "We create user-friendly tools to help enterprises and customers effectively begin their AI journey.",
      lgValue: 5,
    },
  ];
  return (
    <>
      <div className="analyticsMain">
        <HeroIndustries
          image={AnalyticBanner}
          alt={"edmBanner"}
          pageName={"SERVICES / AI, ML & ANALYTICS"}
          pageIntro={"AI, ML & ANALYTICS"}
          pageIntroPara={
            "Transforming Data into Actionable Insights for Smarter Decisions"
          }
        />
        <Row
          style={{ width: "100%", textAlign: "center" }}
          className="analyticsMainRow"
          justify={"center"}
        >
          <RightText
            rightTextItems={rightTextItems}
            rightTextImage={AnalyticRight}
            link=""
            isBookBtn={false}
          />
        </Row>

        <Row className="analyticsSlantRow1">
          <Col span={22} xs={22} sm={22} className="analyticsMain">
            <h3 className="commonTitleHead">
              NUCLEUSTEQ BUILDING BLOCKS TO ML & AI MATURITY
            </h3>
          </Col>
        </Row>

        <GradientCard data={analyticsData} />

        <Row className="analyticsSlantRow1">
          <Col span={22} xs={22} sm={22} className="analyticsMain">
            <h3 className="commonTitleHead">
            THE NUCLEUSTEQ EXPERTISE AREAS
            </h3>
          </Col>
        </Row>
        <AnalyticsGridBox />
        <OrangeBar />
        <SuccessIndex />
      </div>
    </>
  );
};

export default Analytics;
