import { Col, Row, Layout } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { homepageSuccessStories } from "../../../mocks/successStories";
import Title from "antd/es/typography/Title";

const SuccessStories = () => {
  const { image, description, cards } = homepageSuccessStories;
  return (
    <Layout className="container-fluid success-stories">
      <Row className="container mt-4 ss-img-text">
        <Col
          className="d-flex justify-center"
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={8}
        >
          <img className="ss-image" src={image} alt="success-stories" loading="lazy"/>
        </Col>
        <Col className="mt-3" xs={24} sm={24} md={16} lg={16} xl={16} >
          <Row className="ss-heading">
            <h3 className="headingTextMain">SUCCESS STORIES</h3>
          </Row>
          <Title level={4}>{description}</Title>
        </Col>
      </Row>

      <div className="justify-center">
        <Row className="success-card mt-3 mb-3" justify="center">
          {cards.map((item) => {
            return (
              <Col
                className="justify-center ss-cards "
                xs={24}
                sm={24}
                md={7}
                lg={7}
                xl={7}
              >
                <Title level={4}>{item.text}</Title>
                <div className="d-flex align-center ml-3">
                  <ArrowRightOutlined className="ss-rightArrow" />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </Layout>
  );
};

export default SuccessStories;
