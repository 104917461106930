import React from "react";
import "./orangeBar.scss";
import { Row } from "antd";
import CommonCarousel from "../CommonCarousel/CommonCarousel";

const OrangeBar = () => {
  return (
    <div className="orangeMain">
      <CommonCarousel
        carousel={[
          {
            image: require("../../assets/images/OrangeBar/ob_bg1.jpg"),
            header: {
              first: "5x+",
              second: "100%",
              third: "40%+",
              fourth: "22+",
            },
            data: {
              first: "Faster Conversion",
              second: "Accurate Conversion",
              third: "Cost Savings",
              fourth: "Completed Modernizations",
            },
          },
          {
            image: require("../../assets/images/OrangeBar/ob_bg2.jpg"),
            header: {
              first: "10+",
              second: "60+",
              third: "8+",
              fourth: "300M+",
            },
            data: {
              first: "Fortune 500 Clients",
              second: "Active Projects",
              third: "Global Delivery Centers",
              fourth: "Achieved in savings",
            },
          },
          {
            image: require("../../assets/images/OrangeBar/ob_bg1.jpg"),
            header: {
              first: "20",
              second: "600+",
              third: "20+",
              fourth: "2.6M",
            },
            data: {
              first: "Engagements",
              second: "Bots in Engagement",
              third: "Automated Process",
              fourth: "Transactions",
            },
          },
        ].map((e) => {
          return (
            <>
              <div
                className="orangeDetail"
                style={{ backgroundImage: `url(${e.image})` }}
              >
                <Row className="orangeBarRow">
                  <div className="orangeDetailRow">
                    <h1>{e.header.first}</h1>
                    <p>{e.data.first}</p>
                  </div>
                  <div className="orangeDetailRow">
                    <h1>{e.header.second}</h1>
                    <p>{e.data.second}</p>
                  </div>
                  <div className="orangeDetailRow">
                    <h1>{e.header.third}</h1>
                    <p>{e.data.third}</p>
                  </div>
                  <div className="orangeDetailRow">
                    <h1>{e.header.fourth}</h1>
                    <p>{e.data.fourth}</p>
                  </div>
                </Row>
              </div>
            </>
          );
        })}
      />
    </div>
  );
};

export default OrangeBar;
