import React from "react";
import {FAQ} from "../../Components/FAQ"
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";

export default function index() {
  return (
    <>
      <FAQ/>
      <CustomersAndPartners />
    </>
  );
}
