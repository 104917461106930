import { BookNowBtn } from "../BookNowBtn";
import "./comeWith.scss";
import { Col, Row } from "antd";

interface Props {
  src1: string;
  src2: string;
  gbText1: string;
  gbText2: string;
}

const ComeWith = ({ src1, src2, gbText1, gbText2 }: Props) => {
  return (
    <Row className="comeWith_Main" justify={"center"} align={"middle"}>
      <Col xs={24} sm={24} md={22} lg={22} xl={22} xxl={22}>
        <Row style={{ width: "100%" }} justify={"center"}>
          <Col
            className="comeWith"
            xs={23}
            sm={23}
            md={22}
            lg={11}
            xl={11}
            xxl={11}
          >
            <a className="linkTag" href="https://nucleusteq.breezy.hr">
              <BookNowBtn buttonText="Browse Opportunities" style={{width: "36vh"}} modalShow={false} />
            </a>
          </Col>
          {/* <Col
            className="comeWith"
            xs={23}
            sm={23}
            md={22}
            lg={11}
            xl={11}
            xxl={11}
          >
            <a className="linkTag" href="/portal">
              <Row className="comeWith_Row2">
                <img
                  src={src2}
                  className="comeWith_Image"
                  alt=""
                  loading="lazy"
                />
                <b className="comeWith_Text">{gbText2}</b>
              </Row>
            </a>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

export default ComeWith;
