import React from "react";
import "./ntApproach.scss";
import { Row, Col } from "antd";

const NTApproach = () => {
  const approachItems = [
    {
      text: "At NucleusTeq, we take a methodical approach to data modernization, transforming your data into a reusable enterprise asset.",
    },
    {
      text: "Using NuoData, our cutting-edge platform, modernizes data from any source to any cloud platform, providing real-time insights that drive strategic decisions. From predictive analytics to anomaly detection, NuoData unleashes the full potential of your data.",
    },
  ];
  const ourApproachItems = [
    {
      text: "In-depth analysis of existing queries for optimization.",
    },
    {
      text: "Data ingestion from the source for a single version of truth.",
    },
    {
      text: "Robust data governance, metadata, lineage, and data quality.",
    },
    {
      text: "Seamless migration and query conversion.",
    },
    {
      text: "Query optimization and validation against production systems.",
    },
  ];
  return (
    <div>
      <div className="NTApproachDiv">
        <Row justify={"center"} align={"middle"}>
          <Col md={20} xs={24} sm={24}>
            <h1 className="commonTitleHead">The NucleusTeq Approach</h1>

            {approachItems.map((item, index) => (
              <React.Fragment key={index}>
                <Row justify={"center"} align={"middle"}>
                  <Col xs={22} sm={22} md={23}>
                    <h5 className="NTAText">{item.text}</h5>
                  </Col>
                </Row>

                <Row
                  style={{ width: "100%" }}
                  justify={"center"}
                  align={"middle"}
                >
                  <div className="NTADivider" />
                </Row>
              </React.Fragment>
            ))}

            <h1 className="commonTitleHead">Our approach includes:</h1>
            <Row style={{ width: "100%" }} justify={"center"} align={"middle"}>
              <div className="NTADivider" />
            </Row>

            {ourApproachItems.map((item, index) => (
              <React.Fragment key={index}>
                <Row justify={"center"} align={"middle"}>
                  <Col xs={22} sm={22} md={23}>
                    <h5 className="NTAText">{item.text}</h5>
                  </Col>
                </Row>

                <Row
                  style={{ width: "100%" }}
                  justify={"center"}
                  align={"middle"}
                >
                  <div className="NTADivider" />
                </Row>
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NTApproach;
