// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogCardDiv {
  width: 100%;
  padding: 45px 29px !important;
}
@media (max-width: 1024px) {
  .BlogCardDiv {
    padding: 16px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Blog/blog.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,6BAAA;AACF;AAAE;EAHF;IAII,wBAAA;EAGF;AACF","sourcesContent":[".BlogCardDiv {\n  width: 100%;\n  padding: 45px 29px !important;\n  @media (max-width: 1024px) {\n    padding: 16px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
