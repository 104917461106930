// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainHeading {
  width: 100%;
  height: 27vh;
  padding: 2vh;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  letter-spacing: 0em;
  background-color: #1D1A36;
}

.textCareer {
  font-size: 1.5vw;
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media (max-width: 1200px) {
  .textCareer {
    font-size: 2vw;
  }
}
@media (max-width: 992px) {
  .textCareer {
    font-size: 2vw;
  }
}
@media (max-width: 768px) {
  .textCareer {
    font-size: 2.3vw;
  }
}
@media (max-width: 576px) {
  .textCareer {
    font-size: 4vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/ContentOfPages/contentOfPages.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACF,kBAAA;EACA,mBAAA;EACE,mBAAA;EACA,yBAAA;AACJ;;AAGA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAA;;AAIA;EACI;IACI,cAAA;EADN;AACF;AAGA;EACI;IACI,cAAA;EADN;AACF;AAGA;EACI;IACI,gBAAA;EADN;AACF;AAGA;EACI;IACI,cAAA;EADN;AACF","sourcesContent":[".mainHeading {\n    width: 100%;\n    height: 27vh;\n    padding: 2vh;\n    display: flex;\n    justify-content: center;\n  text-align: center;\n  align-items: center;\n    letter-spacing: 0em;\n    background-color: #1D1A36;\n}\n\n\n.textCareer{\nfont-size: 1.5vw;\ncolor: #FFF;\ntext-align: center;\nfont-family: Roboto;\nfont-style: normal;\nfont-weight: 400;\nline-height: 150%;\n}\n\n\n@media (max-width: 1200px) {\n    .textCareer{\n        font-size: 2vw;\n    }\n}\n@media (max-width: 992px) {\n    .textCareer{\n        font-size: 2vw;\n    }\n}\n@media (max-width: 768px) {\n    .textCareer{\n        font-size: 2.3vw;\n    }\n}\n@media (max-width: 576px) {\n    .textCareer{\n        font-size: 4vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
