import React, { useState } from "react";
import { Tabs } from "antd";
import LeftText from "../LeftText/LeftText";
import BigDataLeft from "../../assets/images/big_data/big_data_left_img.png";
import BigDataLeft2 from "../../assets/images/big_data/big_data_left_img2.png";
import BigDataLeft3 from "../../assets/images/big_data/big_data_left_img3.png";
import "./bdLeftText.scss";

const { TabPane } = Tabs;

function LeftTextIndex() {
  const leftTextItems = [
    {
      text: "Centralized, Standardized & Certified Data: Achieve data consistency, trust, and reliability.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Data Democratization: Empower self-service data access while eliminating redundancy.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Enterprise Data Security: Ensure compliance with stringent data security guidelines.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Maximized Reusability: Leverage applications and data globally for enhanced efficiency.",
      className: "paragraphText",
      isList: true,
    },
  ];

  const leftTextItems2 = [
    {
      text: "Big-Data Maturity Assessment: Evaluate your organization's readiness for big data adoption.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Big-Data Tooling Strategy: Strategically select and implement the right tools for your data needs.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Big-Data Solution Blueprinting: Craft a tailored blueprint to address your big data challenges.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Roadmap & CBA Analysis: Chart a clear path forward with a cost-benefit analysis.",
      className: "paragraphText",
      isList: true,
    },
  ];

  const leftTextItems3 = [
    {
      text: "Big-Data Development: Build robust solutions to harness the power of your data.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Big-Data Tooling Strategy: Strategically select and implement the right tools for your data needs.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Big-Data Operations & DevOps: Optimize and streamline your data operations.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Big-Data Reporting & BI: Uncover actionable insights through comprehensive reporting and business intelligence.",
      className: "paragraphText",
      isList: true,
    },
  ];

  const [selectedTab, setSelectedTab] = useState("1");

  const handleTabChange = (key: any) => {
    setSelectedTab(key);
  };

  return (
    <>
      <Tabs
        activeKey={selectedTab}
        onChange={handleTabChange}
        tabPosition="top"
        className="bd-tab"
      >
        <TabPane tab="Big-Data Lake" key="1" className="bd-tab">
          <div className="BDLeftTextDiv">
            <LeftText
              rightTextImage={BigDataLeft}
              leftTextItems={leftTextItems}
            />
          </div>
        </TabPane>
        <TabPane tab="Big-Data Advisory" key="2" className="bd-tab">
          <div className="BDLeftTextDiv">
            <LeftText
              rightTextImage={BigDataLeft2}
              leftTextItems={leftTextItems2}
            />
          </div>
        </TabPane>
        <TabPane tab="Big-Data Engineering" key="3" className="bd-tab">
          <div className="BDLeftTextDiv">
            <LeftText
              rightTextImage={BigDataLeft3}
              leftTextItems={leftTextItems3}
            />
          </div>
        </TabPane>
      </Tabs>
    </>
  );
}

export default LeftTextIndex;
