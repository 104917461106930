// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.globalPressence_main_conatiner {
  width: 95%;
  margin: auto;
  padding: 0px 2vw;
  padding-top: 7vh;
  padding-bottom: 1vh;
}

.globalPressence_blockings {
  margin-top: 3vh;
}`, "",{"version":3,"sources":["webpack://./src/Components/GlobalPresences/GlobalPresences.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".globalPressence_main_conatiner {\n    width: 95%;\n    margin: auto;\n    padding: 0px 2vw;\n    padding-top: 7vh;\n    padding-bottom: 1vh;\n}\n\n.globalPressence_blockings{\n    margin-top: 3vh;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
