import React from "react";
import ImagesForAlls from "../../Components/ImagesForAlls/ImagesForAlls";
import Mails from "../../Components/Mails/Mails";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";

const News = (props: any) => {
  FunctionTitles("NucleusTeq | Newsroom");
  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <ImagesForAlls heading="Newsroom" />
      <Mails />
    </div>
  );
};

export default News;
