import React from "react";
import "./aboutUsCards.scss";
import { Col, Row, Image, Space } from "antd";

interface AnalyticsItem {
  image: string;
  heading: string;
//   text: string;
  lgValue: number;
}

interface AnalyticsSCProps {
  data: AnalyticsItem[];
}

const AboutUsCards: React.FC<AnalyticsSCProps> = ({ data }) => {
  return (
    <div className="AnalyticsSCDiv">
      <Row className="ASCBoxMain">
        {data.map((item, index) => (
          <Col
            key={index}
            xs={22}
            sm={10}
            md={10}
            lg={item.lgValue}
            className={`ASCBox`}
          >
            <Space direction="vertical" className="ASCBoxDiv">
              <Image
                src={item.image}
                preview={false}
                loading="lazy"
                width={"15vh"}
              />
              <Row className="ASCHeadRow">{item.heading}</Row>
            </Space>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AboutUsCards;
