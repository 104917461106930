import React from "react";
import "./blogRightText.scss";
import { Col, Row } from "antd";

interface Props {
  rightTextImage: string;
  rightTextTitle: string;
  rightText: string;
}

const BlogRightText = ({
  rightTextImage,
  rightText,
  rightTextTitle,
}: Props) => {
  const aboutLines = rightText.split("\n");
  return (
    <div>
      <Row justify={"center"} align={"middle"}>
        <Col span={24} className="ltCenter brtTitle">
          {rightTextTitle}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} className="ltCenter">
          <img
            src={rightTextImage}
            className="brtImageWidth"
            alt="nucleusteq"
            loading="lazy"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
          <Row className="ltCenter headingParagraph brtText">
            {aboutLines.map((line, index) => (
              <span
                key={index}
                style={{
                  marginBottom: index !== aboutLines.length - 1 ? "30px" : 0,
                }}
              >
                {line}
                <br />
              </span>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default BlogRightText;
