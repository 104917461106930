import React, { useState } from "react";
import "./faqButton.scss";
import { Button, Image, Row, Modal } from "antd"; // Import Modal from Ant Design

import faqArrow from "../../assets/images/industries/faqArrow.svg";
import { LetConnect } from "../LetConnect";
import { CloseCircleOutlined } from "@ant-design/icons";
interface Props {
  style?: React.CSSProperties;
}

const FAQButton = ({ style }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Row align={"middle"} justify={"start"}>
      <div className="weEmpowerDiv">
        <div className="weEmpowerBox" />
        <Button
          style={style}
          type="primary"
          htmlType="submit"
          className="faqButton"
          onClick={showModal}
        >
          <div className="faqButtonShadow" />
          <div className="faqButtonText">
            Contact Us <br />
            Now
          </div>
          <div className="faqArrow">
            <Image
              src={faqArrow}
              alt="faqArrow"
              preview={false}
              className="faqArrowImage"
            />
          </div>
        </Button>

        <Modal
          title="Contact us"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          className="faqModal"
          closeIcon={<CloseCircleOutlined className="faqModalClose" />}
        >
          <LetConnect padding={false} />
        </Modal>
      </div>
    </Row>
  );
};

export default FAQButton;
