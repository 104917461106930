// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rightText {
  display: flex;
  justify-content: left;
  text-align: left;
  font-weight: 600;
}

.rightTextMain {
  display: flex;
  justify-content: left;
}

.righttextImageCol {
  justify-content: center;
  display: flex;
}

.rightTextImage {
  width: 90%;
  object-fit: contain;
}

.rightTextHid {
  visibility: hidden;
}

@media (min-width: 2100px) {
  .rightTextBlock {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 992px) {
  .rightTextImage {
    width: 100%;
  }
  .rightTextMain {
    display: flex;
    justify-content: left;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/RightText/rightText.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;AACF;;AAEA;EACE,uBAAA;EACA,aAAA;AACF;;AACA;EACE,UAAA;EACA,mBAAA;AAEF;;AACA;EACE,kBAAA;AAEF;;AACA;EACE;IACE,YAAA;IACA,aAAA;EAEF;AACF;AACA;EACE;IACE,WAAA;EACF;EAEA;IACE,aAAA;IACA,qBAAA;EAAF;AACF","sourcesContent":[".rightText {\n  display: flex;\n  justify-content: left;\n  text-align: left;\n  font-weight: 600;\n}\n\n.rightTextMain {\n  display: flex;\n  justify-content: left;\n}\n\n.righttextImageCol {\n  justify-content: center;\n  display: flex;\n}\n.rightTextImage {\n  width: 90%;\n  object-fit: contain;\n}\n\n.rightTextHid {\n  visibility: hidden;\n}\n\n@media (min-width: 2100px) {\n  .rightTextBlock {\n    width: 100px;\n    height: 100px;\n  }\n}\n\n@media (max-width: 992px) {\n  .rightTextImage {\n    width: 100%;\n  }\n\n  .rightTextMain {\n    display: flex;\n    justify-content: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
