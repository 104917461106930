import React from "react";
import leadershipHeroImg from "../../assets/images/leadership/leadershipHeroImg.png";
import HeroIndustries from "../../Components/HeroIndustries/HeroIndustries";

import LeadershipComponent from "../../Components/LeadershipComponent/LeadershipComponent";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
const Leadership = (props: any) => {
  FunctionTitles("NucleusTeq | Leadership");
  return (
    <>
      <HeroIndustries
        pageName="SERVICES  /  LEADERSHIP"
        image={leadershipHeroImg}
        pageIntro="LEADERSHIP"
        alt="Nucleusteq"
        pageIntroPara={
          "Empowering Data-Driven Enterprises to Forge a Single Version of Truth"
        }
      />
      <LeadershipComponent />
    </>
  );
};
export default Leadership;
