import React from "react";
import { Col, Row, Space } from "antd";
import "./blogCards.scss";
import { Link } from "react-router-dom";
import { CommonButton } from "../CommonButton";
import { BsArrowRight } from "react-icons/bs";
type Props = {
  blogdata: {
    blogCardImg1: string;
    CardText1: string;
    CardTitle: string;
    linkAnchor: string;
  }[];
};

const BlogCards = ({ blogdata }: Props) => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Row gutter={[32, 62]} style={{ margin: "0 auto" }} justify={"center"}>
        {blogdata.map((card, index) => (
          <Col key={index} xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
            <div
              className="blog-card"
              style={{
                background: `url("${card.blogCardImg1}") no-repeat center center`,
                backgroundSize: "cover",
                margin: "0 auto",
              }}
            ></div>
            <div className="blog-overlay-text">
              <Space direction="vertical" size={"small"}>
                <h1>{card.CardTitle}</h1>
                <p>{card.CardText1}</p>
                <CommonButton
                  buttonText="Read More"
                  link={card.linkAnchor}
                  style={{ marginTop: "0" }}
                  onClick={handleLinkClick}
                  icon={<BsArrowRight />}
                />
              </Space>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BlogCards;
