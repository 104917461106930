import React from "react";
import { Row, Col } from "antd";
import "./commonPara.scss";

type Props = {
  heading: string;
  subheading: String;
  para1: string;
  para2: string;
  para3: string;
  para4: string;
  para5: string;
};

const CommonPara = ({
  heading,
  subheading,
  para1,
  para2,
  para3,
  para4,
  para5,
}: Props) => {
  return (
    <>
      <Row justify={"center"} style={{ background: "#1D1A36" }}>
        <Col span={24}>
          <p className="commPara_commHeading">{heading}</p>
        </Col>
        <Col span={24}>
          {" "}
          <p className="commPara_subHeading">{subheading}</p>
        </Col>
        <Col span={24}>
          {" "}
          <p className="commPara_paraCom"> {para1}</p>
        </Col>
        <Col span={24}>
          {" "}
          <p className="commPara_paraCom1"> {para2}</p>
        </Col>
        <Col span={24}>
          {" "}
          <p className="commPara_paraCom2"> {para3}</p>
        </Col>
        <Col span={24}>
          {" "}
          <p className="commPara_paraCom3"> {para4}</p>
        </Col>
        <Col span={24}>
          {" "}
          <p className="commPara_paraCom4"> {para5}</p>
        </Col>
      </Row>
    </>
  );
};
export default CommonPara;
