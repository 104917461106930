import React from "react";
import "./benefitCards.scss";
import { Col, Row, Card } from "antd";

interface Props {
  heading1: string;
  benefitText: string;
  img: string;
}

const BenefitCards = ({ img, heading1, benefitText }: Props) => {
  return (
    <div style={{background:"linear-gradient(146deg, rgba(255,182,160,1) 0%, rgba(240,89,42,1) 26%, rgba(37,60,119,1) 100%)", padding:"1px", borderRadius:"2.797202797202797vh" }}>
      <Card className="benefitCard">
        <Row justify={"center"} align={"middle"}>
          <Col xs={24} sm={24} md={6} lg={5} className="benefitIconCol" >
            <img src={img} alt="icon" loading="lazy" className="imageIconBenefit"/>
          </Col>
          <Col xs={24} sm={24} md={18} lg={27}>
            <h5 className="benefitCardHeading1">{heading1}</h5>
            <p className="benefitCardPtag">{benefitText}</p>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default BenefitCards;
