// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/newsRoom/newsroom_newsroom_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allImage {
  height: 30vw;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
}
.allImage_heading1 {
  font-size: 4vw;
  color: #ffffff;
  font-weight: 600;
  font-family: Helvetica;
}

@media (max-width: 1200px) {
  .allImage h1 {
    font-size: 4.5vw;
  }
}
@media (max-width: 992px) {
  .allImage h1 {
    font-size: 4rem;
  }
}
@media (max-width: 768px) {
  .allImage h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 576px) {
  .allImage h1 {
    font-size: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/ImagesForAlls/imagesForAlls.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mDAAA;EACA,4BAAA;EACA,wBAAA;AACJ;AACI;EACI,cAAA;EACA,cAAA;EACA,gBAAA;EACA,sBAAA;AACR;;AAGA;EACI;IACI,gBAAA;EAAN;AACF;AAGA;EACI;IACI,eAAA;EADN;AACF;AAIA;EACI;IACI,iBAAA;EAFN;AACF;AAKA;EACI;IACI,eAAA;EAHN;AACF","sourcesContent":[".allImage {\n    height: 30vw;\n    display: flex;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    background: url(\"../../assets/images/newsRoom/newsroom_newsroom_bg.png\");\n    background-repeat: no-repeat;\n    background-size: contain;\n\n    &_heading1 {\n        font-size: 4vw;\n        color: #ffffff;\n        font-weight: 600;\n        font-family: Helvetica;\n    }\n}\n\n@media (max-width: 1200px) {\n    .allImage h1 {\n        font-size: 4.5vw;\n    }\n}\n\n@media (max-width: 992px) {\n    .allImage h1 {\n        font-size: 4rem;\n    }\n}\n\n@media (max-width: 768px) {\n    .allImage h1 {\n        font-size: 2.5rem;\n    }\n}\n\n@media (max-width: 576px) {\n    .allImage h1 {\n        font-size: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
