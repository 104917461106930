import React from "react";
import { InfoBankingServices } from "../../Components/InfoBankingServices";
import Accomplish from "../../Components/Accomplishment/Accomplish";
import OurSuccessStory from "../../Components/OurSuccessStory/OurSuccessStory";
import bgImg5 from "../../assets/images/bank/bank_bg_image.jpg";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { HeroIndustries } from "../../Components/HeroIndustries";
import { CommunicationHeading } from "../../Components/CommunicationHeading";
import { BankCarousel } from "../../Components/BankCarousel";
import squarImg from "../../assets/images/acoomplish_component/accomplishImg.jpg";
import commImg from "../../assets/images/bank/OUR_SUCCESS.png";
// import {NewText} from "../../Components/NewText"
import { NewText } from "../../Components/NewText";
import { OrangeBar } from "../../Components/OrangeBar";

function Bank() {
  FunctionTitles("NucleusTeq | Banking & Financial Services");
  return (
    <>
      <HeroIndustries
        pageName="INDUSTRIES  /  BANKING & FINANCIAL"
        pageIntro="NAVIGATING THE FUTURE OF BANKING & FINANCIAL SERVICES"
        pageIntroPara={"Empowering Growth in the Age of Disruption"}
        alt="img"
        image={bgImg5}
      ></HeroIndustries>

      <InfoBankingServices
        heading="BANKING & FINANCIAL SERVICES"
        alt="Image"
        para1="In the banking and financial services sector, disruption has become the new norm. The industry is experiencing unprecedented changes as innovative challengers and advanced technologies reshape market dynamics and revenue streams."
        para2="NucleusTeq recognizes that the time for action is now. Mere digital transformation is not enough; banks must enhance their relevance, fully embrace digital strategies, and rethink their business models to thrive in this evolving landscape. Our banking consulting services are designed to equip clients with data-driven insights and the tools needed to adapt to today’s digital world and prepare for tomorrow’s opportunities."
        para3=""
      />
      <CommunicationHeading cardHeadingText="OUR BANKING & FINANCIAL SERVICES CAPABILITIES" />
      <BankCarousel />
      <NewText newtext="At NucleusTeq, we're here to help you navigate the future of banking and financial services. With our comprehensive offerings and expertise, we empower growth, drive innovation, and position your organization for success in an ever-changing landscape. Embrace the future with confidence, backed by NucleusTeq's transformative solutions." />
      {/* <Accomplish
        squareImg={squarImg}
        accomplishText="OUR"
        accomplishText2=" ACCOMPLISHMENTS"
        accomplishText_fortune="Fortune 500 Clients"
      /> */}
      <OrangeBar />
      <OurSuccessStory
        successImg1={commImg}
        successHeading="OUR SUCCESS STORIES"
        successText="Enterprise Data modernization of one of world’s largest credit card company from Teradata to On-Premise Big-Data Lake."
      />
    </>
  );
}

export default Bank;
