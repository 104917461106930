import React from "react";
import "./popupModal.scss";
import { Col, Modal, Image } from "antd";
import { MdOutlineCancel } from "react-icons/md";
import gifUrl from "../../assets/images/popup/popup-gif.gif";

interface Props {
  modalVisible: boolean;
  closeModal: () => void;
  heading: string;
  text: string;
}

const PopupModal = ({ modalVisible, closeModal, heading, text }: Props) => {
  const closeIcon = <MdOutlineCancel className="closeIconBtn" />;
  const modalStyle = { display: "flex", alignItems: "center" };

  return (
    <Modal
      visible={modalVisible}
      onCancel={() => {
        closeModal();
        window.location.reload();
      }}
      closeIcon={closeIcon}
      footer={null}
      maskClosable={false}
      style={modalStyle}
    >
      <div className="modalBox">
        <Col className="modalBoxCol">
          <Image
            src={gifUrl}
            alt="My GIF"
            width={"60%"}
            preview={false}
            loading="lazy"
          />
          <h3 className="popupHead">{heading}</h3>
          <p>{text}</p>
        </Col>
      </div>
    </Modal>
  );
};

export default PopupModal;
