// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.termsBannerImg {
  background-size: cover;
  background-repeat: no-repeat;
  height: 30.5vw;
}
@media (max-width: 1024px) {
  .termsBannerImg {
    height: 50vh;
  }
}

.termsBannerHead {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 5.5vw;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 150%;
  width: 100%;
  margin-bottom: 5vh;
}
@media (max-width: 600px) {
  .termsBannerHead {
    font-size: 2.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/TermsBanner/termsBanner.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,4BAAA;EACA,cAAA;AACF;AACE;EALF;IAMI,YAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;AAEF;AADE;EAZF;IAaI,iBAAA;EAIF;AACF","sourcesContent":[".termsBannerImg {\n  background-size: cover;\n  background-repeat: no-repeat;\n  height: 30.5vw;\n\n  @media (max-width: 1024px) {\n    height: 50vh;\n  }\n}\n\n.termsBannerHead {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  color: #fff;\n  font-size: 5.5vw;\n  font-weight: 700;\n  text-transform: uppercase;\n  line-height: 150%;\n  width: 100%;\n  margin-bottom: 5vh;\n  @media (max-width: 600px) {\n    font-size: 2.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
