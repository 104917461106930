import React from "react";
import { Col, Row } from "antd";
import "./info.scss";

type Props = {
  heading: string;
  alt: string;
  para1: string;
  para2: string;
  para3: string;
};
const InfoBankingServices = ({ heading, para1, para2, para3 }: Props) => {
  return (
    <>
      <Row justify={"center"} style={{background:"#1D1A36"}}>
        <Col>
          <p className="Info_heading">{heading}</p>
        </Col>

        <Col>
          <p className="Info_para"> {para1}</p>
        </Col>
        <Col>
          <p className="Info_para1"> {para2}</p>
        </Col>
      </Row>
    </>
  );
};

export default InfoBankingServices;
