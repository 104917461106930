"use client";
import { useEffect } from "react";

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "44916108",
          formId: "f214883f-c999-43b6-8f4b-e57cdcb29788",
          target: "#hubspotForm",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  return <div id="hubspotForm"></div>;
};
export default HubSpotForm;
