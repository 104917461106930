import { Col, Row } from "antd";
import React from "react";
import HubSpotForm from "./HubForm";

export default function Register() {
  return (
    <div className="register-area">
      <Row align={"middle"} justify={"center"}>
        <Col md={22} sm={22} xs={22}>
          <h1>Registration</h1>
        </Col>
        <Col md={16} sm={22} xs={22}>
          <HubSpotForm />
        </Col>
      </Row>
    </div>
  );
}
