import React from "react";
import "./bigData.scss";
import { Row, Col, Image } from "antd";
import { RightText } from "../../Components/RightText";
import BDIMAGE1 from "../../assets/images/big_data/big_data_right_img.png";
import edmBanner from "../../assets/images/services_banners/services_bigData.jpg";
import OrangeBar from "../../Components/OrangeBar/OrangeBar";
import SuccessIndex from "../../Components/Success/SuccessIndex";
import { KeyBenefits } from "../../Components/KeyBenefits";
import LeftTextIndex from "../../Components/BDLeftText/BDLeftText";
import upQuote from "../../assets/images/big_data/big_data_up_quote.png";
import downQuote from "../../assets/images/big_data/big_data_down_quote.png";
import { HeroIndustries } from "../../Components/HeroIndustries";
import { BDTable } from "../../Components/BDTable";

const BigData = (props: any) => {
  const rightTextItems = [
    {
      text: "Lack of centralization, standardization and self-service of the enterprise data leading to higher time to market, costs & redundancies.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Point to Point Integrations leading to significant cost, lack of governance, data quality & missing single version of “truth”.",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Lack of ability to deal with unstructured, high volume, real-time data for advanced analytics needs in order to exploit the possibilities of the new data driven paradigm.",
      className: "paragraphText",
      isList: true,
    },
  ];

  const benefitItems = [
    {
      text: "Centralized, Standardized & Certified Data",
    },
    {
      text: "Data Democratization",
    },
    {
      text: "Data-driven-enterprise enabled through modern data-lake",
    },
    {
      text: "Enterprise Data Security",
    },
    {
      text: "Maximized Reusability",
    },
    {
      text: "Enabling federation of data through self-service",
    },
  ];
  return (
    <>
      <div className="bdMain">
        <HeroIndustries
          image={edmBanner}
          alt={"edmBanner"}
          pageName={"SERVICES   /   BIG DATA ADVISORY & ENGINEERING"}
          pageIntroPara={
            "Empowering Data-Driven Enterprises to Forge a Single Version of Truth"
          }
          pageIntro={"NAVIGATE THE DATA LANDSCAPE"}
        />
        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <Col md={21} xs={22} sm={22} className="uiCenter cloudIntro">
            <p className="paragraphText">
              In a world where data reigns supreme, enterprises face a common
              set of challenges. The lack of centralization, standardization,
              and self-service of enterprise data leads to inefficiencies,
              higher costs, and data redundancies. Point-to-point integrations
              result in significant expenses, a lack of governance, compromised
              data quality, and the absence of a single version of "truth."
              Unstructured, high-volume, real-time data poses hurdles for
              advanced analytics and harnessing the full potential of the
              data-driven paradigm.
            </p>
          </Col>
          <Col md={21} xs={22} sm={22} className="uiCenter">
            <p className="paragraphText">
              At NucleusTeq, we empower data-driven enterprises to create a
              unified, single version of truth through our Big Data Advisory &
              Engineering services. We understand that embracing "big data" and
              "data lakes" is only meaningful if it truly solves business
              challenges, enabling data democratization, reuse, exploration,
              analytics, and compliance.
            </p>
          </Col>
          <Col span={22} xs={22} sm={22} className="bdCenter">
            <h1 className="commonTitleHead">
              ENABLING DATA-DRIVEN ENTERPRISES
            </h1>
          </Col>
        </Row>

        <Row className="bdLeftTextRow1 bdLeftTextRow3">
          <LeftTextIndex />

          <Row className="bdLeftTextRow1">
            <h1 className="commonTitleHead">OUR APPROACH</h1>
          </Row>
          <Row className="bdLeftBrace">
            <Image src={upQuote} preview={false} alt="upQuote" width={"4vh"} />
          </Row>
          <h4 className="paragraphText bdLeftTextCont">
            NucleusTeq employs a methodical, cloud and platform agnostic
            approach to enterprise data lakes, enabling enterprises to leverage
            data as a reusable asset. Customers retain 100% ownership of the
            accelerator/framework code, ensuring no vendor lock-in.
          </h4>
          <Row className="bdRightBrace">
            <Image
              src={downQuote}
              preview={false}
              alt="downQuote"
              width={"4vh"}
            />
          </Row>
        </Row>
        <div className="bdKeyBenefits">
          <div className="bdGradient" />
          <Row className="bdCenter">
            <Col span={22} xs={22} sm={22}>
              <h1 className="commonTitleHead">NUCLEUSTEQ ACCELERATORS</h1>

              <h5 className="bdTextCenter paragraphText">
                NucleusTeq accelerators help with the 3-5X faster build while
                customizing the Data-lake to customer requirements.
              </h5>

              <BDTable />
            </Col>
          </Row>
        </div>
        <KeyBenefits benefitItems={benefitItems} />
        <OrangeBar />
        <SuccessIndex />
      </div>
    </>
  );
};

export default BigData;
