// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leadership_introLeft_name {
  font-style: normal;
  font-family: Roboto;
  font-weight: 400;
  font-size: 2.3vw;
  color: var(--white, #fff);
  margin-bottom: 0.625rem;
  line-height: 130% !important;
}
.leadership_introLeft_post {
  top: 3292.5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 2.3vw;
  line-height: 130% !important;
  color: #f0592a;
}
.leadership_introLeft_about {
  margin: 1.1vw 0vw;
  font-family: Roboto;
  font-style: normal;
  font-weight: 200;
  line-height: 150% !important;
  font-size: 1vw;
  color: var(--white, #fff);
}

@media screen and (max-width: 578px) {
  .leadership_introLeft_name {
    font-size: 6vw;
    justify-content: center;
  }
  .leadership_introLeft_post {
    top: 3292.5px;
    font-size: 6vw;
    justify-content: center;
  }
  .leadership_introLeft_about {
    margin: 3.5vw 0vw;
    line-height: 5vw;
    font-size: 4.3vw;
  }
}
@media screen and (min-width: 579px) and (max-width: 1023px) {
  .leadership_introLeft_about {
    margin: 2vw 0vw 2.5vw;
    line-height: 2.4vw;
    font-size: 1.7vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/LeadershipIntroLeft/leadershipIntroLeft.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,uBAAA;EACA,4BAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,4BAAA;EACA,cAAA;AADJ;AAIE;EACE,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,4BAAA;EACA,cAAA;EACA,yBAAA;AAFJ;;AAMA;EAEI;IACE,cAAA;IACA,uBAAA;EAJJ;EAOE;IACE,aAAA;IACA,cAAA;IACA,uBAAA;EALJ;EAQE;IACE,iBAAA;IACA,gBAAA;IACA,gBAAA;EANJ;AACF;AAUA;EAEI;IACE,qBAAA;IACA,kBAAA;IACA,gBAAA;EATJ;AACF","sourcesContent":[".leadership_introLeft {\n  &_name {\n    font-style: normal;\n    font-family: Roboto;\n    font-weight: 400;\n    font-size: 2.3vw;\n    color: var(--white, #fff);\n    margin-bottom: 0.625rem;\n    line-height: 130% !important;\n  }\n\n  &_post {\n    top: 3292.5px;\n    font-family: Roboto;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 2.3vw;\n    line-height: 130% !important;\n    color: #f0592a;\n  }\n\n  &_about {\n    margin: 1.1vw 0vw;\n    font-family: Roboto;\n    font-style: normal;\n    font-weight: 200;\n    line-height: 150% !important;\n    font-size: 1vw;\n    color: var(--white, #fff);\n  }\n}\n\n@media screen and (max-width: 578px) {\n  .leadership_introLeft {\n    &_name {\n      font-size: 6vw;\n      justify-content: center;\n    }\n\n    &_post {\n      top: 3292.5px;\n      font-size: 6vw;\n      justify-content: center;\n    }\n\n    &_about {\n      margin: 3.5vw 0vw;\n      line-height: 5vw;\n      font-size: 4.3vw;\n    }\n  }\n}\n\n@media screen and (min-width: 579px) and (max-width: 1023px) {\n  .leadership_introLeft {\n    &_about {\n      margin: 2vw 0vw 2.5vw;\n      line-height: 2.4vw;\n      font-size: 1.7vw;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
