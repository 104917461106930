import { Row, Col } from "antd";
import "./bdTable.scss";

const BDTable = () => {
  return (
    <>
      <Row justify={"center"} className="BDTableRow">
        <Col xs={24} sm={24} md={24}>
          <table className="bd-table">
            <thead>
              <tr className="table-header-row">
                <th colSpan={3}>NucleusTeq Big-Data Accelerator Suite</th>
              </tr>
              <tr className="table-header-row">
                <th>Raw Layer</th>
                <th>Refined Layer</th>
                <th>Consumption Layer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="table-header-div">
                    <p>NucleusTeq Universal Ingestion platform®</p>
                    <span>NucleusTeq Scheduler Framework®</span>
                  </div>
                </td>
                <td>NucleusTeq Universal data-standardization platform®</td>
                <td>
                  <div className="table-header-div">
                    <p>NucleusTeq High Availability</p>
                    <p>Micro-Services platform for API</p>
                    <p>Micro-Services platform for API</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="bd-table">
            <thead>
              <tr className="table-header-row">
                <th colSpan={2}>Core Platform Accelerators</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="table-header-div">
                    <p>Metadata Management platform®</p>
                    <p>24X7 Self-service portal for Data Governance®</p>
                    <p>Enterprise CICD integration platform®</p>
                  </div>
                </td>
                <td>
                  <div className="table-header-div">
                    <p>Data Dictionary & Catalogue®</p>
                    <p>Universal data-quality Framework®</p>
                    <p>Big-data security Framework®</p>
                    <span>Big-Data Authorization Framework®</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default BDTable;
