import React from "react";

import ContentOfPages from "../../Components/ContentOfPages/ContentOfPages";
import CareerOpenings from "../../Components/CareerOpenings/CareerOpenings";
import Powered from "../../Components/Powered/Powered";
import { HeroIndustries } from "../../Components/HeroIndustries";
import careerPortal_career_banner from "../../assets/images/careerPortal/careerPortal_career_banner.jpg";
import location from "../../assets/images/careerPortal/location.svg";
import monitor from "../../assets/images/careerPortal/monitor.svg";
import dollar from "../../assets/images/careerPortal/dollar-circle.svg";
import success_scroll1 from "../../assets/images/successStories1/success_scroll-Img1.png";
import success_scroll2 from "../../assets/images/successStories1/success_scroll-Img2.png";
import success_scroll3 from "../../assets/images/successStories1/success_scroll-Img3.png";
import SucessStoriesScroll from "../../Components/SucessStoriesScroll/SucessStoriesScroll"

const Portal = (props: any) => {
  const sucessStories = [
    {
      src: success_scroll1,
      info: "Big-Data lake setup on Azure cloud for top 3 pet retailers in the world",
      route:"/overview"
    },
    {
      src: success_scroll2,
      info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
      route:"/overview"
    },
    {
      src: success_scroll3,
      info: "Enterprise Data Modernization of one of world’s largest credit card company from Teradata to..",
      route:"/overview"
    },
    {
      src: success_scroll1,
      info: "Detection to Intent to Fraud using NPL for one of world’s largest banks",
      route:"/overview"
    },
    {
      src: success_scroll1,
      info: "Modernization of Enterprise reporting (Netezza) to AWS for a major housing finance company",
      route:"/overview"
    },
    {
      src: success_scroll2,
      info: "Back Office RPA Solution for American Multinational investment",
      route:"/overview"
    },
    {
      src: success_scroll3,
      info: "Digital Transformation for one of the largest oil and Gas company in the world.",
      route:"/overview"
    },
    {
      src: success_scroll1,
      info: "Realtime anomaly detection & network monitoring for major telecommunications company in USA.",
      route:"/overview"
    },
    {
      src: success_scroll1,
      info: "End-to-End Data Lake Setup & Migration of Netezza reporting platform to Azure for a Major retailer",
      route:"/overview"
    },
    {
      src: success_scroll2,
      info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company in the world",
      route:"/overview"
    },
    {
      src: success_scroll3,
      info: "Create new liability insurance models based on IOT sensor data for a leading business Insurer.",
      route:"/overview"
    },
    {
      src: success_scroll1,
      info: "Automation of Insurance – Sales, Underwriting & Services process for Leading Insurance company with $135 B in assets",
      route:"/overview"
    },
    {
      src: success_scroll1,
      info: "Sentiment Analysis of preferred customers for major US airline",
      route:"/overview"
    },
  ];
  const card = [
    {
      image1: location,
      image2: monitor,
      image3: dollar,

      location: "AZ, US",

      role: "Java Developer",
      position: "Remote",
      salary: "$70.00 - $75.00 / hr",
    },
    {
      image1: location,
      image2: monitor,
      image3: dollar,

      location: "Buffalo, NY",
      role: " Python Developer",
      position: "Remote",
      salary: "$70.00 - $75.00 / hr",
    },
    {
      image1: location,
      image2: monitor,
      image3: dollar,

      location: "Canada",
      role: "Big Data Engineer",
      position: "Remote",
      salary: "$70.00 - $75.00 / hr",
    },
    {
      image1: location,
      image2: monitor,
      image3: dollar,
      location: "Coral Springs, FL",
      role: " Sr. Java Developer",

      position: "Remote",

      salary: "$70.00 - $75.00 / hr",
    },
  ];

  return (
    <div style={{ backgroundColor: "#1D1A36" }}>
      <HeroIndustries
        pageName="CAREERS / COME WORK WITH US"
        pageIntro="WE ARE EMPOWERING & TRANSFORMING CUSTOMERS’ BUSINESS THROUGH THE USE OF DIGITAL TECHNOLOGIES"
        alt="img"
        image={careerPortal_career_banner}
        pageIntroPara={""}
      />
      <ContentOfPages text="Our core focus areas are Enterprise Data Management, Big-Data, Cloud, Analytics (AI, ML), Blockchain, Automation & Mobility We are helping build the next generation of Technologists and Data Scientists." />
      <CareerOpenings cards={card} text="OUR OPENINGS" />
      <Powered />
      <SucessStoriesScroll sucessStories={sucessStories} />

    </div>
  );
};

export default Portal;
