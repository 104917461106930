import React from "react";
import { Row, Col } from "antd";
import "./ourPartners.scss";
interface Props {
  data: string[];
  title: string;
  subTitle: string;
}
export default function OurPartners({ data, title, subTitle }: Props) {
  return (
    <div className="ourPartners_div">
      <Row className="ourPartners_heading">{title}</Row>
      <Row
        justify={"center"}
        align={"middle"}
        style={{ textAlign: "center", marginBottom: "3.9vw" }}
      >
        <Col md={23} sm={22} xs={22} className="subHeadTextMain">
          {subTitle}
        </Col>
      </Row>
      <div className="ourPartners_card">
        <Row style={{ width: "100%" }} justify={"center"} gutter={[24, 24]}>
          {data.map((e, i) => {
            return (
              <>
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={4}
                  xxl={4}
                  key={i}
                  className="ourPartners_col"
                >
                  <img
                    src={e}
                    className="ourPartners_img"
                    alt="NucleusTeq"
                    loading="lazy"
                  />
                </Col>
              </>
            );
          })}
        </Row>
      </div>
    </div>
  );
}
