import React from "react";
import { Row, Col } from "antd";
import "./leadershipIntroLeft.scss";

interface Props {
    name: string,
    post: string,
    about: string
}

const LeadershipIntroLeft = ({ name, post, about }: Props) => {
    const aboutLines = about.split('\n');

    return (
        <Row>
            <Col>
                <Row className="leadership_introLeft_name">
                    {name}
                </Row>
                <Row className="leadership_introLeft_post">
                    {post}
                </Row>
                <Row className="leadership_introLeft_about" style={{ textAlign: "justify" }}>
                    {aboutLines.map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </Row>
            </Col>
        </Row>
    )
}

export default LeadershipIntroLeft;
