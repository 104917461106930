export const menus = [
  {
    label: "Home",
    link: "/events/#",
  },
  {
    label: "Event",
    link: "/events/#eventsSection",
  },
  {
    label: "Speakers",
    link: "/events/#speakersSection",
  },
  {
    label: "Agenda",
    link: "/events/#agendaSection",
  },
  {
    label: "Companies",
    link: "/events/#companiesSection",
  },
  // {
  //   label: "Info",
  //   link: "#",
  //   submenu: [
  //     {
  //       label: "Contact",
  //       link: "/contact-us/",
  //     },
  //     {
  //       label: "Faq",
  //       link: "/faq/",
  //     },
  //     {
  //       label: "Terms & Conditions",
  //       link: "/terms-conditions/",
  //     },
  //     {
  //       label: "Privacy Policy",
  //       link: "/privacy-policy/",
  //     },
  //   ],
  // },
  {
    label: "Register",
    link: "/events/#registerSection",
  },
];
