import { CommunicationCard } from "../../Components/CommunicationCard";
import { Col, Row, Image } from "antd";
import { OrangeBar } from "../../Components/OrangeBar";
import { ProductBanner } from "../../Components/ProductBanner";
import "./aboutUs.scss";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import BannerBg from "../../assets/images/product/NuoData-product/banner.jpg";

import retail_Card_img1 from "../../assets/images/aboutUs/retail_Card_img1.png";
import retail_Card_img2 from "../../assets/images/aboutUs/retail_Card_img2.png";
import retail_Card_img3 from "../../assets/images/aboutUs/retail_Card_img3.png";

import AnalyticSlant1 from "../../assets/images/analytic/analytic_slant1.svg";
import AnalyticSlant2 from "../../assets/images/analytic/analytic_slant2.svg";
import AnalyticSlant3 from "../../assets/images/analytic/analytic_slant3.svg";
import { GradientCard } from "../../Components/GradientCard";

import AutoSC1 from "../../assets/images/aboutUs/auto_slant1.svg";
import AutoSC2 from "../../assets/images/aboutUs/auto_slant2.svg";
import AutoSC3 from "../../assets/images/aboutUs/auto_slant3.png";
import AutoSC4 from "../../assets/images/aboutUs/auto_slant4.svg";
import AutoSC5 from "../../assets/images/aboutUs/auto_slant5.svg";
import AutoSC6 from "../../assets/images/aboutUs/auto_slant6.svg";

import AAA from "../../assets/images/OurPartners/AAA.png";
import alibaba from "../../assets/images/OurPartners/alibaba.png";
import Hp from "../../assets/images/OurPartners/Hp.png";
import aws from "../../assets/images/OurPartners/aws.png";
import ServiceNow from "../../assets/images/OurPartners/ServiceNow.png";
import uiPath from "../../assets/images/OurPartners/UiPath.png";
import amazon from "../../assets/images/OurPartners/amazon.png";
import azure from "../../assets/images/OurPartners/Azure.png";
import peopleSoft from "../../assets/images/OurPartners/peopleSoft.png";
import GCloud from "../../assets/images/OurPartners/GCloud.png";
import Oracle from "../../assets/images/OurPartners/Oracle.png";
import Salesforce from "../../assets/images/OurPartners/Salesforce.png";
import mapr from "../../assets/images/OurPartners/mapr.png";
import cloudera from "../../assets/images/OurPartners/cloudera.png";
import Aiven from "../../assets/images/OurPartners/aiven.png";
import deloitte from "../../assets/images/OurPartners/deloitte.png";
import fivetran from "../../assets/images/OurPartners/fivetran.png";
import snowflick from "../../assets/images/OurPartners/snowflick.png";
import cisco from "../../assets/images/OurPartners/cisco.png";
import IBM from "../../assets/images/OurPartners/IBM.png";
import aboutUs from "../../assets/images/aboutUs/successAboutUs.png";
import Partners from "../../Components/Partners/Partners";
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";

import { OurPartners } from "../../Components/OurPartners";

import { CommunicationHeading } from "../../Components/CommunicationHeading";
// import AboutUsCard from "../../Components/AboutUsCard/aboutUsCard";
import { AboutUsCards } from "../../Components/AboutUsCards";
import { AboutUsCardComponent } from "../../Components/AboutUsCardComponent";

const dataCom = [
  {
    comCardImg1: retail_Card_img1,
    linkAnchor: "/Leadership",
    comCardText1: "Leadership",
    CardText1:
      "We do believe in treating our customers, colleagues and partners with utmost honesty, transparency and integrity.",
  },
  {
    comCardImg1: retail_Card_img2,
    linkAnchor: "/Partners",
    comCardText1: "Partners",
    CardText1:
      "Partners are core to the value we generate for our customers. We do believe in creating win-win propositions while offering the best value to our customers.",
  },
  {
    comCardImg1: retail_Card_img3,
    linkAnchor: "/Customers",
    comCardText1: "Customers",
    CardText1:
      "Empowering and transforming our customer’s business by maximizing the value to their business is our single largest focus at NucleusTeq.",
  },
];
const autoData = [
  {
    image: AutoSC1,
    heading: "Integrity & honesty",
    lgValue: 7,
    // text: "Being ethically unyielding and honest and inspiring trust by saying what we mean, matching our behaviors to our words and taking responsibility for our actions.",
  },
  {
    image: AutoSC2,
    heading: "Commitment to client value",
    lgValue: 7,
    // text: "Enabling clients to become high-performance businesses and creating long-term relationships by being responsive and relevant through consistently delivering value.",
  },
  {
    image: AutoSC3,
    heading: "Compassion",
    lgValue: 7,
    // text: "Valuing diversity and unique contributions, fostering a trusting, open and inclusive environment and treating each person in a manner that reflects NucleusTeq’s values.",
  },
  {
    image: AutoSC4,
    heading: "Dependability",
    lgValue: 7,
    // text: "We provide relentless focus on customer deliveries to ensure timely delivery with best in class quality.",
  },
  {
    image: AutoSC5,
    heading: "Stewardship",
    lgValue: 7,
    // text: "Fulfilling our obligation of building a better, stronger and more durable company & community for future generations while protecting the NucleusTeq brand.",
  },
  {
    image: AutoSC6,
    heading: "Culture First",
    lgValue: 7,
    // text: "Attracting, developing and retaining the best talent for our business, challenging our people, demonstrating “can-do” attitude and fostering a collaborative and supportive environment.",
  },
];
const analyticsData = [
  {
    image: AnalyticSlant1,
    heading: "Create Data and analytics strategy",
    text: "We’ll map your analytics initiatives to quantifiable business outcomes with a data-driven approach.",
    lgValue: 5,
  },
  {
    image: AnalyticSlant2,
    heading: "Data discovery and augmentation",
    text: "We bring you 360-degree views by augmenting your assets with third-party data and predictive analytics.",
    lgValue: 5,
  },
  {
    image: AnalyticSlant3,
    heading: "Data management and Optimization",
    text: "In addition to data synthesis and analytics, we assist with governance, monetization and compliance.",
    lgValue: 5,
  },
  {
    image: AnalyticSlant1,
    heading: "Create Data and analytics strategy",
    text: "We’ll map your analytics initiatives to quantifiable business outcomes with a data-driven approach.",
    lgValue: 5,
  },
  {
    image: AnalyticSlant2,
    heading: "Data discovery and augmentation",
    text: "We bring you 360-degree views by augmenting your assets with third-party data and predictive analytics.",
    lgValue: 5,
  },
  {
    image: AnalyticSlant3,
    heading: "Data management and Optimization",
    text: "In addition to data synthesis and analytics, we assist with governance, monetization and compliance.",
    lgValue: 5,
  },
];

const partnerImages = [
  IBM,
  uiPath,
  aws,
  ServiceNow,
  Hp,
  alibaba,
  GCloud,
  Salesforce,
  amazon,
  AAA,
  Oracle,
  mapr,
  azure,
  peopleSoft,
  cloudera,
  cisco,
  Aiven,
  deloitte,
  fivetran,
  snowflick,
];
const AboutUs = (props: any) => {
  FunctionTitles(" NucleusTeq | AboutUs ");

  return (
    <>
      <div style={{ background: "#1d1a36" }}>
        <ProductBanner
          title="Technology is reshaping reality Whatever you can imagine is possible now."
          link=""
          productBanner={BannerBg}
          title2=" "
          btnTitle="Contact Us"
          btnTitle2=""
        />
        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <Col md={19} xs={22} sm={22} className="commonTitleHead">
            DIGITAL REVOLUTION
          </Col>
        </Row>

        {/* <div className="boxHeading">
          <h1 className="bdBlueOrangeGrad">
            <span className="subHeadTextMain">
              "We are driving Enterprise Growth"
            </span>
          </h1>
        </div> */}
        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <Col md={19} xs={22} sm={22} className="headingParagraph">
            NucleusTeq is the top-leading software services, solutions, and
            products company dedicated to empowering and transforming businesses
            through advanced digital technologies, including big data,
            analytics, cloud, enterprise automation, and mobility. We partner
            with numerous Fortune 1000 clients across the U.S., Canada, UK, and
            India to facilitate their digital transformation journey.
          </Col>
        </Row>

        <Row
          style={{ width: "100%", textAlign: "center", marginTop: "8.4vh" }}
          justify={"center"}
        >
          <Col md={19} xs={22} sm={22} className="headingTextMain">
            OUR HIGHLIGHTS
          </Col>

          <Col>
            <div>
              <OrangeBar />
            </div>
          </Col>
        </Row>
        <div className="cardsAboutus">
          <CommunicationCard servicedata={dataCom} />
        </div>
        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <Col md={19} xs={22} sm={22} className="headingTextMain">
            OUR MISSION
          </Col>

          <Col md={19} xs={22} sm={22} className="missionText">
            Empowering and transforming businesses through digital technologies
            such as big data, analytics (AI, ML), cloud, enterprise automation,
            blockchain, mobility, CRM, and ERP. We are equally committed to
            enriching our colleagues and communities.
          </Col>
        </Row>

        <Row
          style={{ width: "100%", background: "#1D1A36" }}
          justify={"center"}
          align={"middle"}
        >
          <div className="NTAAboutUs" />
        </Row>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            background: "#1D1A36",
          }}
        >
          <Image src={aboutUs} preview={false} className="successAboutUs" />
        </div>

        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <Col md={22} xs={22} sm={22} className="commonTitleHead">
            OUR CORE VALUES
          </Col>
        </Row>

        {/* <GradientCard data={autoData} /> */}
        <AboutUsCards data={autoData}/>

        <Partners
          partnerTitle="OUR ALLIANCES & PARTNERS"
          partnerImages={partnerImages}
        />
        <CustomersAndPartners />
      </div>
    </>
  );
};

export default AboutUs;
