// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NTApproachDiv {
  text-align: center !important;
}

.NTADivider {
  background: linear-gradient(270deg, rgba(255, 136, 96, 0) -5.03%, rgba(255, 190, 113, 0.4) 29.76%, rgba(255, 153, 101, 0.4) 50%, rgba(255, 225, 123, 0.4) 67.94%, rgba(255, 242, 129, 0) 100%);
  width: 35vw !important;
  height: 0.14vh !important;
  margin: 1vh 0;
}

.NTAText {
  color: #fff;
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width: 1024px) {
  .NTAText {
    font-size: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/NTApproach/ntApproach.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;;AACA;EACE,8LAAA;EAQA,sBAAA;EACA,yBAAA;EACA,aAAA;AALF;;AASA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AANF;AAOE;EALF;IAMI,eAAA;EAJF;AACF","sourcesContent":[".NTApproachDiv {\n  text-align: center !important;\n}\n.NTADivider {\n  background: linear-gradient(\n    270deg,\n    rgba(255, 136, 96, 0) -5.03%,\n    rgba(255, 190, 113, 0.4) 29.76%,\n    rgba(255, 153, 101, 0.4) 50%,\n    rgba(255, 225, 123, 0.4) 67.94%,\n    rgba(255, 242, 129, 0) 100%\n  );\n  width: 35vw !important;\n  height: 0.14vh !important;\n  margin: 1vh 0;\n}\n\n\n.NTAText {\n  color: #fff;\n  font-size: 1.1vw;\n  font-weight: 400;\n  line-height: 150%;\n  @media (max-width: 1024px) {\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
