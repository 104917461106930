import React from "react";
import "./keyBenefits.scss";
import { Row, Col } from "antd";
interface BenefitItems {
  text: string;
}
interface Props {
  benefitItems: BenefitItems[];
}

const KeyBenefits = ({ benefitItems }: Props) => {
  return (
    <div>
      <div className="keyBenefitsDiv">
        <h1 className="commonTitleHead">KEY BENEFITS</h1>
        {/* <Row style={{ width: "100%" }} justify={"center"} align={"middle"}>
          <div className="NTADivider" />
        </Row> */}
        {benefitItems.map((item, index) => (
          <React.Fragment key={index}>
            <Row justify={"center"} align={"middle"}>
              <Col xs={22} sm={22} md={23}>
                <h5 className="NTAText">{item.text}</h5>
              </Col>
            </Row>
            <Row style={{ width: "100%" }} justify={"center"} align={"middle"}>
              <div className="NTADivider" />
            </Row>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default KeyBenefits;
