// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uiMain {
  background: #1D1A36;
}

.uiCenter {
  display: flex;
  justify-content: center;
  text-align: center;
}

.uiFullWidth {
  width: 100%;
}

.uiHalfWidth {
  width: 50%;
}

.uiMaxWidth {
  max-width: 100%;
}

.uiCardsHead {
  z-index: 1;
  padding-top: 7%;
  font-weight: 700;
  text-align: left;
}

.uiCardsHead2 {
  z-index: 1;
  padding-top: 12%;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/Pages/UI/ui.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,UAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,UAAA;EACA,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".uiMain {\n  background: #1D1A36;\n}\n\n.uiCenter {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n}\n\n.uiFullWidth {\n  width: 100%;\n}\n\n.uiHalfWidth {\n  width: 50%;\n}\n\n.uiMaxWidth {\n  max-width: 100%;\n}\n\n.uiCardsHead {\n  z-index: 1;\n  padding-top: 7%;\n  font-weight: 700;\n  text-align: left;\n}\n\n.uiCardsHead2 {\n  z-index: 1;\n  padding-top: 12%;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
