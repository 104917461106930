"use client";

import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FirstDay from "./FirstDay";

const EventSchedules: React.FC = () => {
  const eventsData1 = [
    {
      title: "NETWORKING",
      subTitle: "Registration, Lunch and Check-In",
      time: "12:00PM - 1:00PM",
      description: "",
    },
    {
      title: "WELCOME",
      subTitle: "Welcome & Opening Remarks",
      time: "1:00PM - 1:10PM",
      description: "",
    },
    {
      title: "KEYNOTE",
      subTitle:
        "Reinvent or Become Irrelevant: The Blueprint of a Modern Data & Analytics Strategy",
      time: "1:10PM - 1:40PM",
      description:
        "As AI accelerates, data & analytics leaders must become the champions of business innovation. Traditional strategies, governance, and solution delivery need a complete overhaul. Discover insights from Gartner's latest CDAO Agenda Survey and learn how to prepare your data universe of the future, today.",
    },
    {
      title: "PEER TALK: CLIENT PANEL",
      subTitle: "From Triumphs to Trials: Real-World Strategies & Lessons",
      time: "1:40PM - 2:25PM",
      description:
        "What distinguishes a successful data and analytics strategy from one that falls short? Dive into real-life examples to uncover both triumphs and pitfalls in data and analytics strategies and operating models. Explore what worked, what didn’t, and the lessons learned from both successful implementations and initial missteps.",
    },
    {
      title: "NETWORKING",
      subTitle: "Networking / Interactive Demos",
      time: "2:25PM - 2:45PM",
      description: "",
    },
    {
      title: "KEYNOTE",
      subTitle: "Build Your Data Universe for an AI- ready Future",
      time: "2:45PM - 3:15PM",
      description:
        'AI has brought both groundbreaking advancements and critical challenges, such as energy concerns, bias, and business relevance. It has highlighted the urgent need to strengthen data and analytics platforms for an AI-driven future. Discover what it means to have "AI-ready" data and push the boundaries of data quality, structure, lineage, security, governance, and metadata. While AI is faster, it’s not necessarily smarter than we are.',
    },
    {
      title: "PEER TALK: CLIENT PANEL",
      subTitle: "Data & AI Unleashed: Management, Governance & Quality",
      time: "3:15PM - 4:00PM",
      description:
        "Data is the most important asset of an organization, central to analytics, AI, and machine learning. Join our panel of experts as we navigate the landscape of AI and explore the emerging trends in data modernization and management.  Learn about essential AI governance principles to ensure quality and privacy, and how to manage data governance in multi-cloud, hybrid environments.",
    },
    {
      title: "NETWORKING",
      subTitle: "Networking / Interactive Demos",
      time: "4:00PM - 4:20PM",
      description: "",
    },
    {
      title: "ASK THE EXPERTS: PARTNER",
      subTitle: "Insights & Trends from Top Tech Partners",
      time: "4:20PM- 4:50PM",
      description:
        "Join us for an engaging rapid-fire Q&A session with top executives from AWS, IBM, GCP, and more. Dive into their unfiltered visions for the future of data, AI, and cloud technologies. Gain exclusive insights into their strategic roadmaps and priorities, and discover how they plan to drive innovation and transformation in these critical areas. No question is off-limits—this is your opportunity to get expert perspectives on the evolving landscape of data and AI.",
    },
    {
      title: "CLOSING REMARKS",
      subTitle: "Key Takeaways",
      time: "4:50PM - 5:00PM",
      description: "",
    },
    {
      title: "RECEPTION & DINNER",
      subTitle: "Reception & Dinner at Tia Carmen",
      time: "5:00PM - 8:00PM",
      description: "",
    },
  ];

  const eventsData2 = [
    {
      title: "GOLF (Optional)",
      subTitle: "Breakfast and Golf Outing",
      time: "9:00AM - 1:00PM",
      description: "",
    },
  ];

  return (
    <>
      <div className="schedule-area bg-image">
        <div className="agenda-top-area" />
        <div className="container">
          <div className="section-title" style={{ position: "relative" }}>
            <h1>Agenda</h1>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Tabs className="event-tabs">
                <TabList>
                  <Tab>
                    First Day
                    <span>October 17, 2024</span>
                  </Tab>

                  <Tab>
                    Second Day
                    <span>October 18, 2024</span>
                  </Tab>
                </TabList>

                <TabPanel>
                  {eventsData1.map((event, index) => (
                    <div
                      className={
                        index === eventsData1.length - 1
                          ? "events-last-div"
                          : "events-div"
                      }
                    >
                      <FirstDay
                        key={index}
                        title={event.title}
                        subTitle={event.subTitle}
                        time={event.time}
                        description={event.description}
                      />
                    </div>
                  ))}
                </TabPanel>

                <TabPanel>
                  {eventsData2.map((event, index) => (
                    <div
                      style={{
                        marginBottom:
                          index === eventsData2.length - 1 ? "0" : "4vh",
                      }}
                    >
                      <FirstDay
                        key={index}
                        title={event.title}
                        subTitle={event.subTitle}
                        time={event.time}
                        description={event.description}
                      />
                    </div>
                  ))}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSchedules;
