// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.automationMain {
  background: #1d1a36;
}

.automationCenter {
  display: flex;
  justify-content: center;
  text-align: center;
}

.autoMarginTop {
  margin-top: 15vh;
}
@media (max-width: 990px) {
  .autoMarginTop {
    margin-top: 8.4vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Automation/automation.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;AAAE;EAFF;IAGI,iBAAA;EAGF;AACF","sourcesContent":[".automationMain {\n  background: #1d1a36;\n}\n\n.automationCenter {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n}\n\n.autoMarginTop {\n  margin-top: 15vh;\n  @media (max-width: 990px) {\n    margin-top: 8.4vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
