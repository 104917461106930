import React from "react";
import "./mainHeadingForAll.scss";

type Props = {
  mainHeadingForAll: string;
  color: string;
};
export default function MainHeadingForAll({
  mainHeadingForAll,
  color
}: Props) {
  return (
    <div className="mainHeadingForAll">
      <p
        className="mainHeadingForAll_heading"
        style={{ color: color || "black"}}
      >
        {mainHeadingForAll}
      </p>
    </div>
  );
}
