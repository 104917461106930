// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cloudMain {
  background: #1d1a36;
}

.cloudIntro {
  margin-top: 15vh;
}
@media (max-width: 1024px) {
  .cloudIntro {
    margin-top: 8.4vh;
  }
}

.cloudCenter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cloudGrid {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Cloud/cloud.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;AAAE;EAFF;IAGI,iBAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;AAGF;;AAAA;EACE,gBAAA;AAGF","sourcesContent":[".cloudMain {\n  background: #1d1a36;\n}\n\n.cloudIntro {\n  margin-top: 15vh;\n  @media (max-width: 1024px) {\n    margin-top: 8.4vh;\n  }\n}\n\n.cloudCenter {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.cloudGrid {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
