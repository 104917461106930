import React from "react";
import "./imagesForAlls.scss";

interface Props {
  heading: String;
}

const ImagesForAlls = ({ heading }: Props) => {
  return (
    <div className="allImage">
      <h1 className="allImage_heading1">{heading}</h1>
    </div>
  );
};

export default ImagesForAlls;
