import React, { useState, useEffect } from "react";
import "./termsTab.scss";
import { Row, Col, Tabs } from "antd";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;

interface TermsTabProps {
  tabTitle: string;
  tabData: {
    title: string;
    key: string;
    content: string;
  }[];
}

const TermsContent: React.FC<{ content: string }> = ({ content }) => {
  const contentLines = content.split("\n");

  return (
    <Row align={"middle"} justify={"center"}>
      <Col span={24} className="bdBlueOrangeGrad termTabGradCol1">
        <span>
          <Col span={24} className="termTabGradCol2">
            {contentLines.map((line, index) => (
              <p key={index} className="paragraphText">
                {line}
              </p>
            ))}
          </Col>
        </span>
      </Col>
    </Row>
  );
};

const TermsTab: React.FC<TermsTabProps> = ({ tabData, tabTitle }) => {
  return (
    <Row className="terms-main">
      <Row
        style={{ width: "100%", textAlign: "center", marginBottom: "8.4vh" }}
        justify={"center"}
      >
        <Col md={24} xs={24} sm={24} className="commonTitleHead">
          Last Updated: October 2023
        </Col>
        <Col md={24} xs={24} sm={24} className="paragraphText">
          Please read these Terms of Service ("Terms") carefully before using
          our website{" "}
          <Link to={"https://www.nucleusteq.com/"} style={{ color: "#fff" }}>
            www.nucleusteq.com
          </Link>{" "}
          operated by NucleusTeq.
        </Col>
      </Row>
      <p className="terms-para">{tabTitle}</p>
      <Col xs={0} sm={0} md={24}>
        <Tabs tabPosition={"left"} className="term-tab">
          {tabData.map((tab) => (
            <TabPane tab={tab.title} key={tab.key} className="term-tab">
              <TermsContent content={tab.content} />
            </TabPane>
          ))}
        </Tabs>
      </Col>
      <Col xs={24} sm={24} md={0}>
        <Tabs tabPosition={"top"} className="term-tab">
          {tabData.map((tab) => (
            <TabPane tab={tab.title} key={tab.key} className="term-tab">
              <TermsContent content={tab.content} />
            </TabPane>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
};

export default TermsTab;
