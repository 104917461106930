import React from "react";
import GridBox from "../GridBox/GridBox";
import CloudBox1 from "../../assets/images/cloud/cloud_box1.png";
import CloudBox2 from "../../assets/images/cloud/cloud_box2.png";
import CloudBox3 from "../../assets/images/cloud/cloud_box3.png";
import CloudBox4 from "../../assets/images/cloud/cloud_box4.png";
import CloudBox5 from "../../assets/images/cloud/cloud_box5.png";
import CloudBox6 from "../../assets/images/cloud/cloud_box6.png";
import { Col, Row } from "antd";

const cloudData = [
  {
    image: CloudBox1,
    text: "Cloud Strategy",
  },
  {
    image: CloudBox2,
    text: "Cloud Data Modernization",
  },
  {
    image: CloudBox3,
    text: "Cloud Engineering",
  },
  {
    image: CloudBox4,
    text: "Cloud Operations & optimization",
  },
  {
    image: CloudBox5,
    text: "Cloud Security",
  },
  {
    image: CloudBox6,
    text: "Cloud Migration",
  },
];

function CloudGridBox() {
  return (
    <Row justify={"center"} align={"middle"} style={{ marginBottom: "4.2vh" }}>
      {cloudData.map((data, index) => (
        <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
          <GridBox
            key={index}
            gridBoxData={[
              {
                image: data.image,
                text: data.text,
              },
            ]}
          />
        </Col>
      ))}
    </Row>
  );
}

export default CloudGridBox;
