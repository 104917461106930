import Benefits from "../../Components/Benefits/Benefits";
import ComeWith from "../../Components/ComeWith/ComeWith";
import Come1 from "../../assets/images/careerPage/come1.png";
import Come2 from "../../assets/images/careerPage/come2.png";
import ImageForLife from "../../Components/ImageForLife/ImageForLife";
import ValuesHold from "../../Sections/HomePage/ValuesWeHold/ValuesWeHold";
import card4 from "../../assets/images/careerPage/career_position.png";
import { HeroIndustries } from "../../Components/HeroIndustries";
import NucleusteqVoices from "../../Components/NucleusteqVoices/NucleusteqVoices";
import career_bg from "../../assets/images/careerPage/career_bg.jpg";
import SuccessStoriesScroll from "../../Components/SucessStoriesScroll/SucessStoriesScroll";
import ContentOfPages from "../../Components/ContentOfPages/ContentOfPages";
import success_stories from "../../assets/images/successStories1/success_stories.png";
import { OurSuccessStory } from "../../Components/OurSuccessStory";
import { WeEmpowerCard } from "../../Components/WeEmpowerCard";
import bg from "../../assets/images/successStories1/bg.png";
import sentiments from "../../assets/images/successStories1/sentiments.png";
import realtime from "../../assets/images/successStories1/realtime.png";
import backofficee from "../../assets/images/successStories1/backofficee.png";
import liabilities from "../../assets/images/successStories1/liability.png";
import real_time from "../../assets/images/successStories1/real-time.png";
import modern from "../../assets/images/successStories1/modern.png";
import digital from "../../assets/images/successStories1/digital.png";
import lake from "../../assets/images/successStories1/lake.png";
import enterprise from "../../assets/images/successStories1/enterprise.png";
import detec from "../../assets/images/successStories1/detec.png";
import { Row } from "antd";
import { MainHeadingForAll } from "../../Components/MainHeadingForAll";
import { NucleusteqVoiceImages } from "../../Components/NucleusteqVoiceImages";
import bank_card_Img1 from "../../assets/images/bank/bank_Card_img1.png";
import VISHESH from "../../assets/images/NucleusteqVoiceImages/VISHESH.png";
import NAMRATA from "../../assets/images/NucleusteqVoiceImages/NAMRATA.png";
import ALIFIYA from "../../assets/images/NucleusteqVoiceImages/ALIFIYA.png";
import SHIVAM from "../../assets/images/NucleusteqVoiceImages/SHIVAM.png";
import BALKRISHNA from "../../assets/images/NucleusteqVoiceImages/BALKRISHNA.png";
import JAY from "../../assets/images/NucleusteqVoiceImages/JAY.png";
import RAHUL from "../../assets/images/NucleusteqVoiceImages/RAHUL.png";

const images = [card4, card4, card4, card4];

const sucessStories = [
  {
    src: real_time,
    info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
    route: "/overview?type=realtime",
  },
  {
    src: bg,
    info: "Big-Data lake setup on Azure cloud for top 3 pet retailers in the world",
    route: "/overview?type=bigdata",
  },
  {
    src: sentiments,
    info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
    route: "/overview?type=sentiment",
  },
  {
    src: modern,
    info: "Enterprise Data Modernization of one of world’s largest credit card company from Teradata to..",
    route: "/overview?type=modernization",
  },
  {
    src: detec,
    info: "Detection to Intent to Fraud using NPL for one of world’s largest banks",
    route: "/overview?type=detection",
  },
  {
    src: liabilities,
    info: "Modernization of Enterprise reporting (Netezza) to AWS for a major housing finance company",
    route: "/overview?type=newLiability",
  },
  {
    src: backofficee,
    info: "Back Office RPA Solution for American Multinational investment",
    route: "/overview?type=backOffice",
  },
  {
    src: digital,
    info: "Digital Transformation for one of the largest oil and Gas company in the world.",
    route: "/overview?type=digitalTransformation",
  },
  {
    src: realtime,
    info: "Realtime anomaly detection & network monitoring for major telecommunications company in USA.",
    route: "/overview?type=realtimeAnomaly",
  },
  {
    src: lake,
    info: "End-to-End Data Lake Setup & Migration of Netezza reporting platform to Azure for a Major retailer",
    route: "/overview?type=datalake",
  },
  {
    src: enterprise,
    info: "Create new liability insurance models based on IOT sensor data for a leading business Insurer.",
    route: "/overview?type=enterprise",
  },
];
const nucleusteqVoiceImages = [
  {
    name: "VISHESH TUNGARE",
    desigination:"Head Of Products Development (India)",
    thoughts:"I lead a dynamic team dedicated to innovation and excellence. Our collaborative environment enables us to create cutting-edge solutions that solve real-world problems. The company’s commitment to growth and learning has significantly enhanced my professional journey.",
    images: VISHESH,
  },

  {
    name: "NAMRATA SOLANKI",
    desigination:"Technical Recruiter Human Empowerment",
    thoughts:"Being a Technical Recruiter in Human Empowerment is a fulfilling role where I help shape our talented team. I enjoy finding and nurturing top talent who drive our innovative projects. The supportive and dynamic work environment motivates me to excel every day.",
    images: NAMRATA,
  },
  {
    name: "SHIVAM JAISWAL",
    desigination:"Senior Engineer",
    thoughts:"Working as a Senior Engineer at our company has been an incredible journey. The challenging projects and collaborative culture push me to innovate and excel daily. I appreciate the opportunities for growth and the support from my amazing team.",
    images: SHIVAM,
  },
  {
    name: "ALIFIYA PIPEWALA",
    desigination:"Associate Architect",
    thoughts:"Working as an Associate Architect has been an incredibly rewarding experience. I get to collaborate with a talented team on innovative projects that challenge and inspire me. The company’s supportive culture and focus on professional development have greatly contributed to my growth.",
    images: ALIFIYA,
  },
  {
    name: "JAY KUMAR SONI",
    desigination: "Technical Lead",
    thoughts:
      "Serving as a Technical Lead has been a highly rewarding role. I enjoy guiding my team through complex projects and fostering a culture of innovation and excellence. The company's commitment to continuous improvement and teamwork makes it an ideal place for professional growth.",
    images: JAY,
  },

  {
    name: "RAHUL KOSHTA",
    desigination: "Senior Designer",
    thoughts:
      "As a Senior UI/UX Designer, I have the privilege of crafting intuitive and engaging user experiences. The company's dedication to design excellence and user-centric solutions fuels my creativity. I thrive in our collaborative environment where my ideas are valued and innovation is encouraged.",
    images: RAHUL,
  },
  {
    name: "BALKRISHNA SHARMA",
    desigination: "Frontend Engineer",
    thoughts:
      "Being a Frontend Engineer here has been a fantastic experience. I love working on cutting-edge projects that enhance user interaction and functionality. The collaborative team and supportive environment make it a great place to grow and innovate.",
    images: BALKRISHNA,
  },
];

const CareerPage = (props: any) => {
  return (
    <div style={{ backgroundColor: "#1D1A36" }}>
      <HeroIndustries
        pageName="CAREERS  /  CAREERS AT NUCLEUSTEQ"
        pageIntro="JOIN OUR JOURNEY OF EMPOWERING & TRANSFORMING BUSINESSES"
        pageIntroPara={"Explore Career Opportunities at NucleusTeq"}
        alt="img"
        image={career_bg}
      />
      <ContentOfPages text="At NucleusTeq, we are on a mission to empower and transform businesses, and we believe that our people are at the heart of this journey. If you're ready to be a part of a dynamic, innovative, and forward-thinking organization, you've come to the right place." />
      <ComeWith
        src1={Come2}
        src2={Come1}
        gbText1={"Browse Opportunities"}
        gbText2={"Click here to access our Careers portal"}
      />
      <NucleusteqVoices
        text1={
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
        }
        image={images}
        text2="NUCLEUSTEQ VOICES"
      />
      <NucleusteqVoiceImages nucleusteqVoiceImages={nucleusteqVoiceImages} />
      <ImageForLife
        text="THE VALUES WE HOLD"
        text1="At NucleusTeq, our values are more than just words; they are the foundation of our culture and guide our actions every day."
      />
      <Benefits
        text="OUR BENEFITS"
        text2="WE ARE COMMITTED TO"
        texting="At NucleusTeq, our values are more than just words; they are the foundation of our culture and guide our actions every day."
      />
      <Row justify={"center"}>
        <MainHeadingForAll
          mainHeadingForAll="OUR SUCCESS STORIES"
          color="#FFF"
        />
      </Row>
      <SuccessStoriesScroll
        sucessStories={sucessStories}
        onCardClick={(e) => {
          console.log("oncard", e);
        }}
      />
    </div>
  );
};

export default CareerPage;
