import { Row, Col, Space, List, Image } from "antd";
import { Link } from "react-router-dom";
import footer_logo from "../../../assets/images/footer/footer_logo.svg";
import "./footer.scss";

const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Row className="footer" justify={"center"}>
      <Col span={24}>
        <Row justify={"center"}>
          <Col
            xs={24}
            sm={16}
            md={12}
            lg={6}
            xl={5}
            xxl={4}
            className="footerCol col_align"
          >
            <Image
              src={footer_logo}
              alt="logo"
              preview={false}
              className="footer_ntLogo"
            />
          </Col>
          <Col xs={24} sm={16} md={10} lg={6} xl={4} xxl={4}>
            <div className="footerColChild datacol">
              <List>
                <Col span={24}>
                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_headings1 menu-text-decoration "
                      to="/"
                    >
                      OVERVIEW
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/aboutUs"
                      onClick={handleLinkClick}
                    >
                      ABOUT US
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/leadership"
                      onClick={handleLinkClick}
                    >
                      LEADERSHIP
                    </Link>
                  </div>
                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/partners"
                      onClick={handleLinkClick}
                    >
                      PARTNERS
                    </Link>
                  </div>
                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/customers"
                      onClick={handleLinkClick}
                    >
                      CUSTOMERS
                    </Link>
                  </div>
                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/successStories1"
                      onClick={handleLinkClick}
                    >
                      SUCCESS STORIES
                    </Link>
                  </div>
                </Col>
              </List>
            </div>
          </Col>
          <Col
            xs={24}
            sm={16}
            md={12}
            lg={10}
            xl={6}
            xxl={7}
            className="footer_headings2Col"
          >
            <div className="footerColChild">
              <List>
                <Col span={24}>
                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_headings2 menu-text-decoration"
                      to="/"
                    >
                      SERVICES
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/edm"
                      onClick={handleLinkClick}
                    >
                      Enterprise Data Modernization
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/bigdata"
                      onClick={handleLinkClick}
                    >
                      Big Data Advisory and Engineering
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/analytics"
                      onClick={handleLinkClick}
                    >
                      Analytics (AI, ML) Services
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/cloud"
                      onClick={handleLinkClick}
                    >
                      Cloud Services
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/auto"
                      onClick={handleLinkClick}
                    >
                      Enterprise Automation
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/ui"
                      onClick={handleLinkClick}
                    >
                      CRM and ERP
                    </Link>
                  </div>
                </Col>
              </List>
            </div>
          </Col>
          <Col
            xs={24}
            sm={16}
            md={10}
            lg={10}
            xl={6}
            xxl={5}
            className="footer_headings3Col"
          >
            <div>
              <List>
                <Col span={24}>
                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_headings3 menu-text-decoration "
                      to="/"
                    >
                      Industries
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/bank"
                      onClick={handleLinkClick}
                    >
                      Banking & Financial Services
                    </Link>
                  </div>
                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/retail"
                      onClick={handleLinkClick}
                    >
                      Retail, Consumer Goods & Services
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/insurance"
                      onClick={handleLinkClick}
                    >
                      Insurance
                    </Link>
                  </div>
                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/life"
                      onClick={handleLinkClick}
                    >
                      Life Sciences & Healthcare
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/communication"
                      onClick={handleLinkClick}
                    >
                      Communication, Media & Technology
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/energy"
                      onClick={handleLinkClick}
                    >
                      Energy & Utilities
                    </Link>
                  </div>

                  <div className="footer_subHeadingsDiv">
                    <Link
                      className="footer_subHeadings menu-text-decoration"
                      to="/travel"
                      onClick={handleLinkClick}
                    >
                      Travel & Hospitality
                    </Link>
                  </div>
                </Col>
              </List>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Footer;
