// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imagePeople {
  width: 100%;
  height: 100%;
  border-radius: 4.1958041958vh;
}

.imageDivs {
  background: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/ImageOfPeople/imageOfPeople.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACD,6BAAA;AACH;;AAEA;EACI,kCAAA;AACJ","sourcesContent":[".imagePeople {\n    width: 100%;\n    height: 100%;\n   border-radius:  4.195804195804196vh;\n}\n\n.imageDivs{\n    background: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
