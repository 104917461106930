// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productBannerHead {
  text-align: center;
  color: #fff;
  font-size: 4vw;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 5vh;
}
@media (max-width: 600px) {
  .productBannerHead {
    font-size: 5vh;
  }
}

.productBannerSubHead {
  text-align: center;
  color: #fff !important;
  width: 100%;
  margin-bottom: 5vh;
  font-size: 4vh;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
@media (max-width: 992px) {
  .productBannerSubHead {
    font-size: 2vh;
  }
}
@media (max-width: 768px) {
  .productBannerSubHead {
    font-size: 2vh;
  }
}
@media (max-width: 576px) {
  .productBannerSubHead {
    font-size: 2.8vh;
  }
}

.productBannerImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 30.5vw;
}
@media (max-width: 1024px) {
  .productBannerImg {
    background-size: cover;
    background-position: 100%;
    height: 70vh;
  }
}
@media (max-width: 768px) {
  .productBannerImg {
    background-size: cover;
    height: 95vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/ProductBanner/productBanner.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;AACF;AACE;EATF;IAUI,cAAA;EAEF;AACF;;AACA;EACE,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEF;AACE;EAXF;IAYM,cAAA;EAEJ;AACF;AAAE;EAfF;IAgBI,cAAA;EAGF;AACF;AADE;EAnBF;IAoBI,gBAAA;EAIF;AACF;;AAAA;EACE,sBAAA;EACA,4BAAA;EACA,wBAAA;EACA,cAAA;AAGF;AADE;EANF;IAOI,sBAAA;IACA,yBAAA;IACA,YAAA;EAIF;AACF;AAFE;EAZF;IAaI,sBAAA;IACA,YAAA;EAKF;AACF","sourcesContent":[".productBannerHead {\n  text-align: center;\n  color: #fff;\n  font-size: 4vw;\n  font-weight: 700;\n  text-transform: uppercase;\n  width: 100%;\n  margin-bottom: 5vh;\n\n  @media (max-width: 600px) {\n    font-size: 5vh;\n  }\n}\n\n.productBannerSubHead {\n  text-align: center;\n  color: #fff !important;\n  width: 100%;\n  margin-bottom: 5vh;\n  font-size: 4vh;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 130%;\n\n\n  @media (max-width: 992px) {\n      font-size: 2vh;\n  }\n\n  @media (max-width: 768px) {\n    font-size: 2vh;\n  }\n\n  @media (max-width: 576px) {\n    font-size: 2.8vh;\n\n  }\n}\n\n.productBannerImg {\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: top;\n  height: 30.5vw;\n\n  @media (max-width: 1024px) {\n    background-size: cover;\n    background-position: 100%;\n    height: 70vh;\n  }\n\n  @media (max-width: 768px) {\n    background-size: cover;\n    height: 95vh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
