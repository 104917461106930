import React from "react";
import "./Maps.scss";
import { Col, Image } from "antd";
import { Link } from "react-router-dom";

interface Props {
  location: string;
  width: string;
  height: string;
  src: string;
  state: string;
  phoneNumber: string;
  country: string;
  mail: string;
  image1:string;
  image2:string;
  image3:string;
}

const Maps = ({
  width,
  height,
  src,
  state,
  phoneNumber,
  mail,
  location,
  country,
  image1,
  image2,
  image3,
}: Props) => {
  return (
    <>
      <Col xs={24} sm={24} lg={12} md={12} xl={12}>
        <div>
          <iframe
            width={width}
            height={height}
            id="gmap_canvas"
            src={src}
            className="mapIamge"
            style={{ borderRadius: '20px' }}
            title={
              "NucleusTeq - " + (Math.random() + 1).toString(36).substring(7)
            }
          ></iframe>
          <div className="map_details">
            <p className="countryFont">
              <b>{country}</b>
            </p>
            <p className="stateFont">{state}</p>
            <p className="iconColor1">
              <Image src={image1} preview={false} alt="" width={"3.5vh"}/>&nbsp;&nbsp;&nbsp;{" "}
              <Link className="mapLinks" to="tel:0019136382948">
                {phoneNumber}
              </Link>
            </p>
            <p className="iconColor2">
              <Image src={image2} preview={false} alt="" width={"3.5vh"}/>&nbsp;&nbsp;&nbsp;{" "}
              <Link className="mapLinks" to="mailto:ops@nucleusteq.com">
                {mail}
              </Link>
            </p>
            <p className="iconColor3">
              <Image src={image3} preview={false} alt="" width={"3.5vh"} className="locationImage" />&nbsp;&nbsp;&nbsp;{" "}
              <Link className="mapLinks" to="">
              {location}
              </Link>
            </p>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Maps;
