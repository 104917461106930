import React from "react";
import { Row, Col } from "antd";
import BannerBg from "../../assets/images/product/data-gov/banner.jpg";
import { ProductBanner } from "../../Components/ProductBanner";
import { KeyBenefits } from "../../Components/KeyBenefits";
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";
import { NewTitleForProduct } from "../../Components/NewTitleForProduct";
const DataGov = (props: any) => {


  const benefitItems = [
    {
      text: "Data Integrity: Your data, your trust. We ensure the accuracy, consistency, and reliability of your data, so you can make informed decisions with confidence.",
    },
    {
      text: "Regulatory Compliance: Stay ahead of the curve and avoid costly fines. Our solutions help you adhere to data-related regulations and industry standards, including GDPR, HIPAA, CCPA, and more.",
    },
    {
      text: "Risk Mitigation: Safeguard your organization's reputation and sensitive information. NucleusTeq minimizes the risk of data breaches, unauthorized access, and data leaks.",
    },
    {
      text: "Efficient Data Management: Say goodbye to data chaos. We promote efficient data classification, storage, and retrieval, reducing redundancy and maximizing the value of your data.",
    },
    {
      text: "Enhanced Decision-Making: Empower your business with data-driven insights. NucleusTeq provides a solid foundation for informed decisions, strategic planning, and improved overall performance.",
    },
    {
      text: "Don't leave your data to chance. Partner with NucleusTeq and take control of your data governance, compliance, and security. Trust us to protect what matters most – your data.",
    },
  ];


  return (
    <>
      <div className="uiMain">
        <ProductBanner
          title="UNLOCK THE POWER OF DATA GOVERNANCE, COMPLIANCE, AND SECURITY"
          link=""
          productBanner={BannerBg}
          title2="Ensure Data Consistency and Trustworthiness for a Stronger Enterprise"
          btnTitle="Book a Demo Now"
          btnTitle2=""
        />
        <Row>
          <NewTitleForProduct text="At NucleusTeq, we understand that data is the lifeblood of your organization. That's why we've made it our mission to empower you with robust Data Governance,
         Compliance, and Security solutions. With NucleusTeq, you can confidently manage the availability, usability, integrity, and security of your data, ensuring it's always a valuable asset, not a liability."
          />
        </Row>
        <Row justify={"center"} align={"middle"}>
          <Col span={22} xs={22} sm={22} xl={24}>
            <KeyBenefits benefitItems={benefitItems} />
          </Col>
        </Row>
        <CustomersAndPartners />
      </div>
    </>
  );
};

export default DataGov;
