import React from "react";
import "./cloud.scss";
import { Row, Col } from "antd";
import { RightText } from "../../Components/RightText";
import OrangeBar from "../../Components/OrangeBar/OrangeBar";
import SuccessIndex from "../../Components/Success/SuccessIndex";
import CloudBanner from "../../assets/images/services_banners/services_cloud.jpg";
import cloud1 from "../../assets/images/edm/edm_right_image1.png";
import AAA from "../../assets/images/OurPartners/AAA.png";
import alibaba from "../../assets/images/OurPartners/alibaba.png";
import Hp from "../../assets/images/OurPartners/Hp.png";
import aws from "../../assets/images/OurPartners/aws.png";
import ServiceNow from "../../assets/images/OurPartners/ServiceNow.png";
import uiPath from "../../assets/images/OurPartners/UiPath.png";
import amazon from "../../assets/images/OurPartners/amazon.png";
import azure from "../../assets/images/OurPartners/Azure.png";
import peopleSoft from "../../assets/images/OurPartners/peopleSoft.png";
import GCloud from "../../assets/images/OurPartners/GCloud.png";
import Oracle from "../../assets/images/OurPartners/Oracle.png";
import Salesforce from "../../assets/images/OurPartners/Salesforce.png";
import mapr from "../../assets/images/OurPartners/mapr.png";
import cloudera from "../../assets/images/OurPartners/cloudera.png";
import Aiven from "../../assets/images/OurPartners/aiven.png";
import deloitte from "../../assets/images/OurPartners/deloitte.png";
import fivetran from "../../assets/images/OurPartners/fivetran.png";
import snowflick from "../../assets/images/OurPartners/snowflick.png";
import cisco from "../../assets/images/OurPartners/cisco.png";
import IBM from "../../assets/images/OurPartners/IBM.png";
import Partners from "../../Components/Partners/Partners";
import CloudGridBox from "../../Components/CloudGridBox/CloudGridBox";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { HeroIndustries } from "../../Components/HeroIndustries";
import { KeyBenefits } from "../../Components/KeyBenefits";

const Cloud = (props: any) => {
  FunctionTitles("NucleusTeq | Cloud Transformation");

  const benefitItems = [
    {
      text: "Rapid IT Service Delivery",
    },
    {
      text: "Flexibility & Adaptability",
    },
    {
      text: "Business Continuity",
    },
    {
      text: "Efficiency and Clarity",
    },
    {
      text: "Optimizing and Validating Queries for Production Systems",
    },
    // {
    //   text: "Rapid Time to Market: Speed up report development 6-10X.",
    // },
    {
      text: "Self-Service Federation",
    },
    // {
    //   text: "Don't settle for outdated data systems. Embrace modernization with NucleusTeq and make your data a competitive advantage.",
    // },
  ];

  const partnerImages = [
    IBM,
    uiPath,
    aws,
    ServiceNow,
    Hp,
    alibaba,
    GCloud,
    Salesforce,
    amazon,
    AAA,
    Oracle,
    mapr,
    azure,
    peopleSoft,
    cloudera,
    cisco,
    Aiven,
    deloitte,
    fivetran,
    snowflick,
  ];

  const rightTextItems = [
    {
      text: "Cloud technology has quickly become the default at companies of all sizes. Nine out of ten companies have already moved at least some of their applications or computing infrastructure to the cloud or plan to do so in the next 12 months. IT spending reflects this trend, with the average investment in cloud rising nearly 36% from two years ago.",
      className: "paragraphText",
    },
    {
      text: "Top Business Drivers for cloud adoption are",
      className: "subHeadTextMain",
    },
    {
      text: "Improving the speed of IT service delivery",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Greater flexibility to react to changing market conditions",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Enabling business continuity & speed of development",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Improving customer support or services",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Lower total cost of ownership",
      className: "paragraphText",
      isList: true,
    },
  ];

  return (
    <>
      <div className="cloudMain">
        <HeroIndustries
          image={CloudBanner}
          alt={"edmBanner"}
          pageName={"SERVICES  /  CLOUD"}
          pageIntro={
            "CLOUD TECHNOLOGY FOR YOUR BUSINESS"
          }
          pageIntroPara={"Adapt to change, focus on core & optimize cost"}
        />
        <Row
          justify={"center"}
          align={"middle"}
          className="cloudCenter cloudIntro"
        >
          <Col md={21} xs={22} sm={22} className="uiCenter">
            <p className="paragraphText">
            In today's fast-paced business environment, cloud technology is essential. 
            Companies of all sizes are leveraging the cloud to tackle challenges, foster innovation, 
            and remain competitive. At NucleusTeq, we're here to support you on this transformative journey.
            </p>
          </Col>
          <Col md={21} xs={22} sm={22} className="uiCenter">
            <p className="paragraphText">
            Cloud technology is now the preferred choice, with nine out of ten 
            companies either already using it or planning to migrate within the next 12 months. 
            This trend is evident in IT spending, which has seen a nearly 36% increase 
            in cloud investments over the past two years.
            </p>
          </Col>
          <div style={{ marginTop: "-8.4vh" }}>
            <KeyBenefits benefitItems={benefitItems} />
          </div>
        </Row>

        <Row className="cloudCenter">
          <Col span={22} xs={22} sm={22} className="bdCenter">
            <h3 className="commonTitleHead">
              NUCLEUSTEQ CLOUD EXPERTISE AREAS
            </h3>
          </Col>
        </Row>

        <CloudGridBox />
        <Partners
          partnerTitle="CLOUD PARTNERS & ALLIANCES"
          partnerImages={partnerImages}
        />
        <OrangeBar />
        <SuccessIndex />
      </div>
    </>
  );
};

export default Cloud;
