import HeroIndustries from "../../Components/HeroIndustries/HeroIndustries";
import OurPartners from "../../Components/OurPartners/OurPartners";
import ourPartnerHeroImg from "../../assets/images/OurPartners/ourPartnerHeroImg.jpg";
import AAA from "../../assets/images/OurPartners/AAA.png";
import alibaba from "../../assets/images/OurPartners/alibaba.png";
import Hp from "../../assets/images/OurPartners/Hp.png";
import aws from "../../assets/images/OurPartners/aws.png";
import ServiceNow from "../../assets/images/OurPartners/ServiceNow.png";
import uiPath from "../../assets/images/OurPartners/UiPath.png";
import amazon from "../../assets/images/OurPartners/amazon.png";
import azure from "../../assets/images/OurPartners/Azure.png";
import peopleSoft from "../../assets/images/OurPartners/peopleSoft.png";
import GCloud from "../../assets/images/OurPartners/GCloud.png";
import Oracle from "../../assets/images/OurPartners/Oracle.png";
import Salesforce from "../../assets/images/OurPartners/Salesforce.png";
import mapr from "../../assets/images/OurPartners/mapr.png";
import cloudera from "../../assets/images/OurPartners/cloudera.png";
import Aiven from "../../assets/images/OurPartners/aiven.png";
import deloitte from "../../assets/images/OurPartners/deloitte.png";
import fivetran from "../../assets/images/OurPartners/fivetran.png";
import snowflick from "../../assets/images/OurPartners/snowflick.png";
import cisco from "../../assets/images/OurPartners/cisco.png";
import IBM from "../../assets/images/OurPartners/IBM.png";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { Col, Row } from "antd";

const Partners = (Props: any) => {
  FunctionTitles("NucleusTeq | Partners");

  const data = [
    IBM,
    uiPath,
    aws,
    ServiceNow,
    Hp,
    alibaba,
    GCloud,
    Salesforce,
    amazon,
    AAA,
    Oracle,
    mapr,
    azure,
    peopleSoft,
    cloudera,
    cisco,
    Aiven,
    deloitte,
    fivetran,
    snowflick,
  ];
  return (
    <div>
      <HeroIndustries
        pageName=" OVERVIEW /  OUR PARTNERS"
        image={ourPartnerHeroImg}
        pageIntro="OUR PARTNERS"
        alt="NucleusTeq"
        pageIntroPara={"Building a Stronger Future, Together"}
      />

      <OurPartners
        data={data}
        title="OUR PARTNERS"
        subTitle="At NucleusTeq, we believe that strategic partnerships are a
          cornerstone of innovation and growth, enabling us to deliver even
          greater value to our clients and the broader business community."
      />
    </div>
  );
};
export default Partners;
