import { Row } from "antd";
import success_heroimg from "../../assets/images/successStories1/success_heroImg.jpg";
import success_scroll1 from "../../assets/images/successStories1/success_scroll-Img1.png";
import success_scroll2 from "../../assets/images/successStories1/success_scroll-Img2.png";
import success_scroll3 from "../../assets/images/successStories1/success_scroll-Img3.png";

import PageHeading from "../../Components/PageHeading/PageHeading";
import MainHeadingForAll from "../../Components/MainHeadingForAll/MainHeadingForAll";
import SuccessStories1Card from "../../Components/SuccessStories1Card/SuccessStories1Card";
import SuccessStoriesScroll from "../../Components/SucessStoriesScroll/SucessStoriesScroll";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import SocialIcons from "../../Components/SocialIcons/SocialIcons";
import HeroIndustries from "../../Components/HeroIndustries/HeroIndustries";

import bg from "../../assets/images/successStories1/bg.png";
import sentiments from "../../assets/images/successStories1/sentiments.png";
import realtime from "../../assets/images/successStories1/realtime.png"
import backofficee from "../../assets/images/successStories1/backofficee.png"
import liabilities from "../../assets/images/successStories1/liability.png"
import real_time from "../../assets/images/successStories1/real-time.png"
import modern from "../../assets/images/successStories1/modern.png"
import digital from "../../assets/images/successStories1/digital.png"
import lake from "../../assets/images/successStories1/lake.png"
import enterprise from "../../assets/images/successStories1/enterprise.png"
import detec from "../../assets/images/successStories1/detec.png"


const SuccessStory1 = (props: any) => {
  FunctionTitles("NucleusTeq | Success Stories");

  // const sucessStories = [
  //   {
  //     src: success_scroll1,
  //     info: "Streamlining Data Efficiency in Retail Industry",
  //     route: "/overview?type=bigdata",
  //   },
  //   {
  //     src: success_scroll2,
  //     info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
  //     route: "/overview?type=backOffice",
  //   },
  //   {
  //     src: success_scroll3,
  //     info: "Enterprise Data Modernization of one of world’s largest credit card company from Teradata to..",
  //     route: "/overview?type=enterprise",
  //   },
  //   {
  //     src: success_scroll1,
  //     info: "Detection to Intent to Fraud using NPL for one of world’s largest banks",
  //     route: "/overview?type=modernization",
  //   },
  //   {
  //     src: success_scroll2,
  //     info: "Modernization of Enterprise reporting (Netezza) to AWS for a major housing finance company",
  //     route: "/overview?type=modernization",
  //   },
  //   {
  //     src: success_scroll3,
  //     info: "Back Office RPA Solution for American Multinational investment",
  //     route: "/overview?type=backOffice",
  //   },
  //   {
  //     src: success_scroll1,
  //     info: "Digital Transformation for one of the largest oil and Gas company in the world.",
  //     route: "/overview?type=digitalTransformation",
  //   },
  //   {
  //     src: success_scroll2,
  //     info: "Realtime anomaly detection & network monitoring for major telecommunications companies in the USA.",
  //     route: "/overview?type=realtimeAnomaly",
  //   },
  //   // {
  //   //   src: success_scroll1,
  //   //   info: "End-to-End Data Lake Setup & Migration of Netezza reporting platform to Azure for a Major retailer",
  //   //   route: "/overview",
  //   // },
  //   // {
  //   //   src: success_scroll2,
  //   //   info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company in the world",
  //   //   route: "/overview",
  //   // },
  //   {
  //     src: success_scroll3,
  //     info: "Create new liability insurance models based on IOT sensor data for a leading business Insurer.",
  //     route: "/overview?type=newLiability",
  //   },
  //   {
  //     src: success_scroll1,
  //     info: "Automation of Insurance – Sales, Underwriting & Services process for Leading Insurance company with $135 B in assets",
  //     route: "/overview?type=automation",
  //   },
  //   {
  //     src: success_scroll2,
  //     info: "Sentiment Analysis of preferred customers for major US airline",
  //     route: "/overview?type=sentiment",
  //   },
  //   {
  //     src: success_scroll3,
  //     info: "DETECTION OF INTENT TO FRAUD USING NLP FOR ONE OF WORLD’S LARGEST BANKS",
      
  //     route: "/overview?type=detection",
  //   },
  // ];
  const sucessStories = [
    {
      src: real_time,
      info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
      route: "/overview?type=realtime",
    },
    {
      src: bg,
      info: "Big-Data lake setup on Azure cloud for top 3 pet retailers in the world",
      route: "/overview?type=bigdata",
    },
    {
      src: sentiments,
      info: "Real-time insights and recommendation engine for one of the largest pharmaceuticals company..",
      route: "/overview?type=sentiment",
    },
    {
      src: modern,
      info: "Enterprise Data Modernization of one of world’s largest credit card company from Teradata to..",
      route: "/overview?type=modernization",
    },
    {
      src: detec,
      info: "Detection to Intent to Fraud using NPL for one of world’s largest banks",
      route: "/overview?type=detection",
    },
    {
      src: liabilities,
      info: "Modernization of Enterprise reporting (Netezza) to AWS for a major housing finance company",
      route: "/overview?type=newLiability",
    },
    {
      src: backofficee,
      info: "Back Office RPA Solution for American Multinational investment",
      route: "/overview?type=backOffice",
    },
    {
      src: digital,
      info: "Digital Transformation for one of the largest oil and Gas company in the world.",
      route: "/overview?type=digitalTransformation",
    },
    {
      src: realtime,
      info: "Realtime anomaly detection & network monitoring for major telecommunications companies in the USA.",
      route: "/overview?type=realtimeAnomaly",
    },
    {
      src: lake,
      info: "End-to-End Data Lake Setup & Migration of Netezza reporting platform to Azure for a Major retailer",
      route: "/overview?type=datalake",
    },
    {
      src: enterprise,
      info: "Create new liability insurance models based on IOT sensor data for a leading business Insurer.",
      route: "/overview?type=enterprise",
    },
    
  ];

  const TravelandHospitality = [
    {
      heading: "The Challenge",
      data: [
        {
          key: 0,
          value:
            "Identify the sentiment & Change the way customers perceive the airline, both in domestic and international sectors.",
        },
        {
          key: 1,
          value:
            "Sentiments captured from Facebook, Twitter, Email Support, Call Center Support, Website, Travel Booking sites, etc.",
        },
        {
          key: 2,
          value:
            "Connect all together to derive relevance w.r.t. passenger, travel dates, times, sectors, planes, staff etc.",
        },
      ],
    },
    {
      heading: "NucleusTeq Solution",
      data: [
        {
          key: 0,
          value:
            "Performed aspect-based sentiment analysis and identified potential areas of improvement",
        },
        {
          key: 1,
          value:
            "Worked on linkage with other datasets to identify the root causes of the problem and suggest system improvement changes",
        },
        {
          key: 2,
          value:
            "Provide high level recommendations based on past actions taken",
        },
      ],
    },
    {
      heading: "Benefits Realized",
      data: [
        {
          key: 0,
          value:
            "More than 80% accuracy in identifying the relevant sentiments that impacted the customer’s satisfaction.",
        },
        {
          key: 1,
          value:
            "Effectively provide recommendations to provide recommended action for specific cases.",
        },
      ],
    },
  ];
  const EnergyandUtilities = [
    {
      heading: "The Challenge",
      data: [
        {
          key: 0,
          value:
            "Fragmented functions lead to significant delays and less than optimal recommendations",
        },
        {
          key: 1,
          value:
            " The company did not have a unified digital strategy leading to significant delay in execution",
        },
        { key: 2, value: "Insights were not leading to effective actions" },
      ],
    },
    {
      heading: "NucleusTeq Solution",
      data: [
        {
          key: 0,
          value:
            "Defined & Implemented Azure based cloud platform strategy leveraging Industry Gen 4 reference Architecture",
        },
        {
          key: 1,
          value:
            " Implemented 10+ Insights that would lead to smart decisions such as operations optimization, Real-time Monitoring etc",
        },
        {
          key: 2,
          value: " IoT streaming & real-time analytics for predictive failures",
        },
      ],
    },
    {
      heading: "Benefits Realized",
      data: [
        {
          key: 0,
          value:
            "15% more yield through advanced Machine Learning recommendations",
        },
        {
          key: 1,
          value:
            "10% faster time to market while leveraging the cloud based digital platform.",
        },
        {
          key: 2,
          value:
            "12-15% reduction in failures & incidents through predictive monitoring",
        },
      ],
    },
  ];
  const CommMedia = [
    {
      heading: "The Challenge",
      data: [
        {
          key: 0,
          value:
            "Detect router issues, tower failure & call drops in real-time",
        },
        {
          key: 1,
          value:
            "Identify and route the traffic to another (working) infrastructure.",
        },
        { key: 2, value: "Improve customer experience" },
        {
          key: 3,
          value: "Feedback loop to customer and link it with marketing",
        },
      ],
    },
    {
      heading: "NucleusTeq Solution",
      data: [
        {
          key: 0,
          value:
            "NucleusTeq created an end-to-end pipeline where the network data is ingested, transformed and dynamically analyzed via Machine Learning Algorithms to identify anomalies and sent to appropriate channel for further action",
        },
        { key: 1, value: "Real-time routing and monitoring of the network." },
      ],
    },
    {
      heading: "Benefits Realized",
      data: [
        {
          key: 0,
          value:
            "Unsupervised algorithm dynamically updates itself and identifies anomalies. Thus, it always considers the latest dynamics of the environment",
        },
        {
          key: 1,
          value: "Real-time monitoring & optimization of the infrastructure",
        },
        { key: 2, value: " Enhanced customer experience" },
      ],
    },
  ];
  return (
    <div style={{ background: "#1d1a36" }}>
      <HeroIndustries
        pageName="OVERVIEW / SUCCESS STORIES"
        pageIntro="SUCCESS STORIES"
        pageIntroPara={"Client Spotlights: Real Success, Real Results"}
        alt="Nucleusteq"
        image={success_heroimg}
      />
      <Row justify={"center"}>
        <MainHeadingForAll
          mainHeadingForAll="TRAVEL AND HOSPITALITY"
          color="#FFF"
        />
      </Row>
      <PageHeading
        heading={`"Sentiment Analysis of preferred customers for major US airline"`}
        subHeading={""}
      />
      <SuccessStories1Card list={TravelandHospitality} padding="3vw" />
      <SocialIcons />
      <Row justify={"center"}>
        <MainHeadingForAll
          mainHeadingForAll="ENERGY AND UTILITIES"
          color="#FFF"
        />
      </Row>
      <PageHeading
        heading="Digital Transformation for one of the largest oil and Gas company in the world."
        subHeading={`"About the customer – Customer is one of the largest Oil & Gas company in the world"`}
      />
      <SuccessStories1Card list={EnergyandUtilities} padding="2vw" />
      <SocialIcons />
      <Row justify={"center"}>
        <MainHeadingForAll
          mainHeadingForAll="COMMUNICATION MEDIA & TECHNOLOGY"
          color="#FFF"
        />
      </Row>
      <PageHeading
        heading="Realtime anomaly detection & network monitoring for major telecommunications companies in the USA."
        subHeading={`"About the customer – Customer is one of the major telecommunications companies in USA"`}
      />
      <SuccessStories1Card list={CommMedia} padding="2vw" />
      <SocialIcons />
      <Row justify={"center"}>
        <MainHeadingForAll
          mainHeadingForAll="OUR SUCCESS STORIES"
          color="#FFF"
        />
      </Row>
      <SuccessStoriesScroll
        sucessStories={sucessStories}
        onCardClick={(e) => {
          console.log("oncard", e);
        }}
      />
    </div>
  );
};
export default SuccessStory1;
