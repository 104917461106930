import { Card, Col, Row } from "antd";
import "./successStories1Card.scss";

interface Props {
    padding:string
  list: {
    heading: String;
    data: {
      key: Number;
      value: String;
    }[];
  }[];
}
const SuccessStories1Card = ({ list ,padding}: Props) => {
    return (
        <>
            <Row align={"middle"} justify="center" className="successStories1Card_card" gutter={[18, 12]} style={{padding:padding || ""}}>
                {list.map(({ heading, data }) => {
                    return (
                        <>
                            <Col xs={20} sm={12} md={8} lg={8} xl={6} xxl={6} className="successStories1Card_cardCol">
                                <Card
                                    title={heading}
                                    className="successStories1Card"
                                >
                                    {data.map(({ key, value }) => {
                                        return (
                                            <Col style={{padding:".5rem"}}>
                                                <ul className='successStories1Card_list'>
                                                    <li className={key === data.length - 1 ? "no-border" : "ulBottomBorder"}>
                                                        {value}
                                                    </li>
                                                </ul>
                                            </Col>
                                        )
                                    })}
                                </Card>
                            </Col>
                        </>
                    )
                })}
            </Row>
        </>
    )
};

export default SuccessStories1Card;
