import React from "react";
import "./imageOfPeople.scss";

interface Props {
  img: string;
}

const ImageOfPeople: React.FC<Props> = ({ img }) => {
  return (
    <div style={{backgroundColor:"transparent !important"}} className="imageDivs">
      <img src={img} alt="icon" className="imagePeople" loading="lazy"/>
    </div>
  );
};

export default ImageOfPeople;
