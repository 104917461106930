import React, { useState } from "react";
import "./bookNowBtn.scss";
import { Button, Modal } from "antd";
import { BsArrowRight } from "react-icons/bs";
import { LetConnect } from "../LetConnect";
import { CloseCircleOutlined } from "@ant-design/icons";
interface Props {
  buttonText: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  modalShow: boolean;
}
export default function BookNowBtn({
  buttonText,
  style,
  onClick,
  modalShow,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Button
        style={style}
        type="primary"
        htmlType="submit"
        className="bookNow"
        onClick={modalShow === true ? showModal : onClick}
      >
        <span>
          {buttonText}
          <BsArrowRight className="bookNowArrow" />
        </span>
      </Button>
      <Modal
        title="Contact us"
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="faqModal"
        closeIcon={<CloseCircleOutlined className="faqModalClose" />}
      >
        <LetConnect padding={false} />
      </Modal>
    </div>
  );
}
