import React from "react";
import Hero from "../../Components/HeroIndustries/HeroIndustries";
import { InfoBankingServices } from "../../Components/InfoBankingServices";
import { Accomplish } from "../../Components/Accomplishment";
import CapitalMarketCards from "../../Components/CapitalMarketCards/CapitalMarketCards";
import OurSuccessStory from "../../Components/OurSuccessStory/OurSuccessStory";
import bgImg5 from "../../assets/images/life/LIFE_SCIENCES.jpg";
import squarImg from "../../assets/images/acoomplish_component/accomplishImg.jpg";
import img1 from "../../assets/images/life/img1.png";
import img2 from "../../assets/images/life/img2.png";
import img3 from "../../assets/images/life/img3.png";
import img4 from "../../assets/images/life/img4.png";
import commImg from "../../assets/images/life/Real-time.png";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { CommunicationHeading } from "../../Components/CommunicationHeading";
import { OrangeBar } from "../../Components/OrangeBar";
const dataTravel = [
  {
    card1: img1,
    comCardText1: "Patient & Medical Services",
    CardText1:
      " Elevate patient care and outcomes through innovative technology solutions that improve healthcare delivery.",
  },
  {
    card1: img2,
    comCardText1: "Supply Chain",
    CardText1:
      "Create insights-driven, transparent, and integrated supply chains that enhance efficiency and effectiveness.",
  },
  {
    card1: img3,
    comCardText1: "Commercial Sales & Marketing",
    CardText1:
      " Leverage insights and human ingenuity to deliver exceptional customer experiences and drive net-new commercial growth.",
  },
  {
    card1: img4,
    comCardText1: "Technology Advisory & Engineering",
    CardText1:
      "Transform your enterprise into a digital powerhouse, utilizing Cloud, Analytics, Big Data, and Blockchain, all while fortifying your security measures to safeguard your assets.",
  },
];

function Life() {
  FunctionTitles("NucleusTeq | Healthcare & Life Science");
  return (
    <>
      <Hero
        pageName="INDUSTRIES  /  LIFE SCIENCES & HEALTHCARE"
        pageIntro="THE FUTURE OF LIFE SCIENCES & HEALTHCARE IS HERE"
        alt="img"
        image={bgImg5}
        pageIntroPara="Advancing through Innovation"
      />

      <InfoBankingServices
        heading="LIFE SCIENCES & HEALTHCARE"
        alt="Image"
        para1="At NucleusTeq, we partner with pharmaceutical, biotech, medical technology, distributors, and consumer health clients worldwide to revolutionize how medical treatments are discovered, developed, and delivered to patients. Combining cutting-edge technology with scientific breakthroughs, we empower intelligent solutions that deliver the power of Insight Driven Health in the digital age."
        para2=""
        para3=""
      />
      <CommunicationHeading cardHeadingText="OUR CAPABILITIES" />

      <CapitalMarketCards dataTravel={dataTravel} />

      {/* <Accomplish
        squareImg={squarImg}
        accomplishText="OUR"
        accomplishText2=" ACCOMPLISHMENTS"
        accomplishText_fortune="Fortune 500 Clients"
      /> */}

      <OrangeBar />

      <OurSuccessStory
        successImg1={commImg}
        successHeading="OUR SUCCESS STORIES"
        successText="Real-time insights and recommendation engine for one of the largest pharmaceuticals company in the world"
      />
    </>
  );
}

export default Life;
