import { Row } from "antd";
import React from "react";
import CommonPara from "../../Components/CommonPara/CommonPara";
import HeroIndustries from "../../Components/HeroIndustries/HeroIndustries";
import "./overviewSucessStory.scss";

interface ContentData {
  heading: string;
  subheading:string;
  para1: string;
  para2: string;
  para3: string;
  para4: string;
  para5: string;
}

interface ProjectData {
  image: string;
  pageIntro: string;
  about: string;
  content: ContentData[];
}
interface Props {
  projectData: ProjectData;
}
const OverviewSucessStory: React.FC<Props> = ({ projectData }: Props) => {
  return (
    <>
      {projectData ? (
        <div style={{ background: "#1D1A36" }}>
          <HeroIndustries
            image={projectData.image}
            pageIntro={projectData.pageIntro}
            pageIntroPara={projectData.about}
            pageName="OVERVIEW / SUCCESS STORIES"
            alt= "Nucleusteq"
            />
          {/* <Row
            justify={"center"}
            align={"middle"}
            style={{ padding: "6vw 0vw 0vw 0vw" }}
          >
            <h2 className="overviewSucessStory_heading">{projectData.about}</h2>
          </Row> */}
          {projectData?.content?.map((data) => {
            return (
              <CommonPara
                heading={data.heading}
                subheading={data.subheading}
                para1={data.para1}
                para2={data.para2}
                para3={data.para3}
                para4={data.para4}
                para5={data.para5}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
};
export default OverviewSucessStory;
