// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BDLeftTextRow {
  width: 50% !important;
}

.BDLeftText {
  text-align: center !important;
}

.BDLeftTextDiv {
  margin: 0 4% !important;
}

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
}

.ant-tabs .ant-tabs-tab {
  padding: 1vw !important;
}
@media (max-width: 768px) {
  .ant-tabs .ant-tabs-tab {
    padding: 0.75rem !important;
  }
}

.bd-tab .ant-tabs-tab {
  font-size: 1.3vw !important;
  font-weight: 400 !important;
  color: #fff;
  border-bottom: 1px solid #fff !important;
}
@media (max-width: 1024px) {
  .bd-tab .ant-tabs-tab {
    font-size: 1.1rem !important;
  }
}

.bd-tab .ant-tabs-tab-active {
  font-size: 1.3vw !important;
  font-weight: 400;
  filter: none;
  opacity: 1;
  color: #f0592a !important;
}
@media (max-width: 1024px) {
  .bd-tab .ant-tabs-tab-active {
    font-size: 1.1rem !important;
  }
}

.bd-tab .ant-tabs-ink-bar::after {
  background: #f0592a !important;
  height: 4px !important;
  content: "";
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f0592a;
  text-shadow: 0 0 0.25px currentcolor;
}`, "",{"version":3,"sources":["webpack://./src/Components/BDLeftText/bdLeftText.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,uBAAA;AACF;AAAE;EAFF;IAGI,2BAAA;EAGF;AACF;;AAAA;EACE,2BAAA;EACA,2BAAA;EACA,WAAA;EACA,wCAAA;AAGF;AAFE;EALF;IAMI,4BAAA;EAKF;AACF;;AAFA;EACE,2BAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;EACA,yBAAA;AAKF;AAJE;EANF;IAOI,4BAAA;EAOF;AACF;;AAJA;EACE,8BAAA;EACA,sBAAA;EACA,WAAA;EACA,6BAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AAOF;;AAJA;EACE,cAAA;EACA,oCAAA;AAOF","sourcesContent":[".BDLeftTextRow {\n  width: 50% !important;\n}\n\n.BDLeftText {\n  text-align: center !important;\n}\n\n.BDLeftTextDiv {\n  margin: 0 4% !important;\n}\n\n.ant-tabs .ant-tabs-tab + .ant-tabs-tab {\n  margin: 0 !important;\n}\n\n.ant-tabs .ant-tabs-tab {\n  padding: 1vw !important;\n  @media (max-width: 768px) {\n    padding: 0.75rem !important;\n  }\n}\n\n.bd-tab .ant-tabs-tab {\n  font-size: 1.3vw !important;\n  font-weight: 400 !important;\n  color: #fff;\n  border-bottom: 1px solid #fff !important;\n  @media (max-width: 1024px) {\n    font-size: 1.1rem !important;\n  }\n}\n\n.bd-tab .ant-tabs-tab-active {\n  font-size: 1.3vw !important;\n  font-weight: 400;\n  filter: none;\n  opacity: 1;\n  color: #f0592a !important;\n  @media (max-width: 1024px) {\n    font-size: 1.1rem !important;\n  }\n}\n\n.bd-tab .ant-tabs-ink-bar::after {\n  background: #f0592a !important;\n  height: 4px !important;\n  content: \"\";\n  position: absolute !important;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n  color: #f0592a;\n  text-shadow: 0 0 0.25px currentcolor;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
