import React, { useState } from "react";
import {
  Card,
  Tabs,
  Form,
  Input,
  Space,
  Upload,
  Image,
  message,
  Button,
  Col,
  Row,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "./blogEditTab.scss";
import BlogEditImg from "../../assets/images/blog/blog-details-edit/blog-edit-img.png";
import BlogContentImg from "../../assets/images/blog/blog-details-edit/blog-content-uploader.png";
import { CommonButton } from "../CommonButton";
import { BsArrowRight } from "react-icons/bs";
import ImgCrop from "antd-img-crop";

const { TabPane } = Tabs;
const { Dragger } = Upload;

const ASPECT_RATIO_3_2 = 3 / 2;

const BlogTabPane1 = () => {
  const [selectedTab, setSelectedTab] = useState("1");
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [uploadSecImg, setUploadSecImg] = useState<File | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contentCards, setContentCards] = useState([
    {
      heading: "",
      bodyText: "",
      image: null,
    },
  ]);

  const handleAddContentCard = () => {
    setContentCards([
      ...contentCards,
      { heading: "", bodyText: "", image: null },
    ]);
  };

  const handleRemoveContentCard = (indexToRemove: any) => {
    setContentCards((prevCards) =>
      prevCards.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
  };

  const uploadSecProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
  };

  const onCropChange = (file: any, stateUpdater: any) => {
    stateUpdater(file);
  };

  return (
    <>
      <Row justify="center" align="middle" className="blogEditMain">
        <div className="BlogEditDiv" style={{ marginBottom: "24px" }}>
          <Card bordered={false} title="Banner" className="blogEditCard">
            <Form
              name="basic"
              initialValues={{ remember: true }}
              className="leftFormInput"
              autoComplete="off"
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={11} xl={11} xxl={11} className="leftFormName">
                  <Form.Item
                    name="Heading"
                    className="leftFormInput"
                    rules={[
                      {
                        required: true,
                        message: "Please input Heading!",
                      },
                    ]}
                  >
                    <Input
                      className="formInput"
                      placeholder="Add heading"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={11} xl={11} xxl={11}>
                  <Form.Item
                    name="sub-heading"
                    rules={[
                      {
                        required: true,
                        message: "Please input Sub heading!",
                      },
                    ]}
                  >
                    <Input
                      className="formInput"
                      placeholder="Sub heading"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={11} xl={11} xxl={11} className="leftFormName">
                  <Space direction="vertical" className="blogEditMain">
                    <p>Add banner image</p>
                    <ImgCrop
                      aspect={1920 / 468}
                      modalTitle="Crop your image"
                      modalClassName="faqModal"
                      onModalOk={(file) => onCropChange(file, setUploadedImage)}
                    >
                      <Dragger {...uploadProps} className="blogEditUploader">
                        {uploadedImage ? (
                          <Image
                            src={URL.createObjectURL(uploadedImage)}
                            alt="BlogEditImg"
                            preview={false}
                            width={"100%"}
                          />
                        ) : (
                          <Image
                            src={BlogEditImg}
                            alt="BlogEditImg"
                            preview={false}
                            width={"100%"}
                          />
                        )}
                      </Dragger>
                    </ImgCrop>
                  </Space>
                </Col>
                <Col xs={24} lg={11} xl={11} xxl={11}>
                  <Form.Item
                    name="body-text"
                    rules={[
                      {
                        required: true,
                        message: "Please input Body text!",
                      },
                    ]}
                  >
                    <Input
                      className="formInput"
                      placeholder="Body text"
                      bordered={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>

        <Row gutter={[0, 24]} style={{ width: "100%" }}>
          {contentCards.map((card, index) => (
            <Col span={24}>
              <Card
                style={{ border: "1px solid #ffb6a0" }}
                key={index}
                title={
                  <Row align={"middle"}>
                    <Col span={20} className="blogContentText">
                      Content text
                    </Col>{" "}
                    <Col
                      span={4}
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="blogAddButton"
                        onClick={handleAddContentCard}
                      >
                        <span>Add</span>
                      </Button>
                      {index > 0 && (
                        <Button
                          danger
                          type="primary"
                          className="blogAddButton"
                          onClick={() => handleRemoveContentCard(index)}
                          style={{ marginLeft: "4%" }}
                        >
                          <span>Delete</span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                }
                className="blogEditCard"
              >
                <Form
                  key={index}
                  name={`contentCardForm${index}`}
                  initialValues={{ remember: true }}
                  className="leftFormInput"
                  autoComplete="off"
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24} className="leftFormName">
                      <Form.Item
                        name="Heading"
                        className="leftFormInput"
                        rules={[
                          {
                            required: true,
                            message: "Please input Heading!",
                          },
                        ]}
                      >
                        <Input
                          className="formInput"
                          placeholder="Add heading"
                          bordered={false}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="body-text"
                        rules={[
                          {
                            required: true,
                            message: "Please input Body text!",
                          },
                        ]}
                      >
                        <Input
                          className="formInput"
                          placeholder="Body text"
                          bordered={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      lg={11}
                      xl={11}
                      xxl={11}
                      className="leftFormName"
                    >
                      <Space direction="vertical" className="blogEditMain">
                        <p>Add banner image</p>
                        <ImgCrop
                          aspect={ASPECT_RATIO_3_2}
                          modalTitle="Crop your image"
                          modalClassName="faqModal"
                          onModalOk={(file) =>
                            onCropChange(file, setUploadSecImg)
                          }
                        >
                          <Dragger
                            {...uploadSecProps}
                            className="blogEditUploader"
                          >
                            {uploadSecImg ? (
                              <Row align={"middle"}>
                                <Col sm={12} xs={12} md={8}>
                                  <Image
                                    src={URL.createObjectURL(uploadSecImg)}
                                    alt="BlogEditImg"
                                    preview={false}
                                    // width={"100%"}
                                  />
                                </Col>
                                <Col
                                  sm={12}
                                  xs={12}
                                  md={16}
                                  className="blogUploaderText2"
                                >
                                  Size should be in 3:2 ratio
                                </Col>
                              </Row>
                            ) : (
                              <Row align={"middle"}>
                                <Col sm={12} xs={12} md={8}>
                                  <Image
                                    src={BlogContentImg}
                                    alt="BlogEditImg"
                                    preview={false}
                                    width={"100%"}
                                  />
                                </Col>
                                <Col
                                  sm={12}
                                  xs={12}
                                  md={16}
                                  className="blogUploaderText2"
                                >
                                  Size should be in 3:2 ratio
                                </Col>
                              </Row>
                            )}
                          </Dragger>
                        </ImgCrop>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          ))}
        </Row>
      </Row>

      <Row justify="end" align="middle" className="blogEditMain">
        <CommonButton link="" buttonText="Submit" icon={<BsArrowRight />} />
      </Row>
    </>
  );
};
export default BlogTabPane1;
