// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brtTitle {
  color: #fff;
  font-size: 5.1vh;
  font-weight: 700;
  line-height: 150%;
  margin: 8vh 0;
}
@media (max-width: 1024px) {
  .brtTitle {
    font-size: 2.6rem;
  }
}
@media (max-width: 575px) {
  .brtTitle {
    font-size: 1.6rem;
  }
}

.brtImageWidth {
  width: 90%;
  object-fit: contain;
  aspect-ratio: 3/2;
}
@media (max-width: 992px) {
  .brtImageWidth {
    width: 100%;
    margin-bottom: 24px;
  }
}

.brtText {
  text-align: left;
}
@media (max-width: 992px) {
  .brtText {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/BlogRightText/blogRightText.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;AACF;AAAE;EANF;IAOI,iBAAA;EAGF;AACF;AAFE;EATF;IAUI,iBAAA;EAKF;AACF;;AAFA;EACE,UAAA;EACA,mBAAA;EACA,iBAAA;AAKF;AAJE;EAJF;IAKI,WAAA;IACA,mBAAA;EAOF;AACF;;AAJA;EACE,gBAAA;AAOF;AANE;EAFF;IAGI,kBAAA;EASF;AACF","sourcesContent":[".brtTitle {\n  color: #fff;\n  font-size: 5.1vh;\n  font-weight: 700;\n  line-height: 150%;\n  margin: 8vh 0;\n  @media (max-width: 1024px) {\n    font-size: 2.6rem;\n  }\n  @media (max-width: 575px) {\n    font-size: 1.6rem;\n  }\n}\n\n.brtImageWidth {\n  width: 90%;\n  object-fit: contain;\n  aspect-ratio: 3/2;\n  @media (max-width: 992px) {\n    width: 100%;\n    margin-bottom: 24px;\n  }\n}\n\n.brtText {\n  text-align: left;\n  @media (max-width: 992px) {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
