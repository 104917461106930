// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BDTableRow {
  margin-top: 4.2vh !important;
  width: 100%;
  display: flex;
  justify-content: center;
}

.table-header-row:nth-child(1) th {
  background: #f0592a;
  color: #fff;
}

.table-header-row:nth-child(2) th {
  background: rgba(240, 89, 42, 0.8);
  color: #fff;
}

.table-header-div p {
  color: #000 !important;
}

.table-header-div span {
  color: #f0592a !important;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: center !important;
}

.bd-table thead,
tbody,
tfoot,
tr,
th {
  border: 1px solid #bec5d7 !important;
  text-align: center !important;
  font-size: 2.2vh;
}
@media (max-width: 600px) {
  .bd-table thead,
  tbody,
  tfoot,
  tr,
  th {
    font-size: 14px;
  }
}

.bd-table th {
  padding: 0.65rem;
}

.bd-table td {
  border: 1px solid #bec5d7 !important;
  text-align: center !important;
  padding: 8px;
  font-size: 2.2vh;
  color: #000 !important;
}
@media (max-width: 600px) {
  .bd-table td {
    font-size: 14px;
  }
}

.bd-table tr {
  width: 80vw;
}

.bd-table tbody td {
  background: #fff;
  padding: 1vw;
}
@media (max-width: 600px) {
  .bd-table tbody td {
    padding: 0rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/BDTable/bdTable.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,kCAAA;EACA,WAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;EACA,6BAAA;AACF;;AAEA;;;;;EAKE,oCAAA;EACA,6BAAA;EACA,gBAAA;AACF;AAAE;EARF;;;;;IASI,eAAA;EAOF;AACF;;AAJA;EACE,gBAAA;AAOF;;AAJA;EACE,oCAAA;EACA,6BAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;AAOF;AANE;EANF;IAOI,eAAA;EASF;AACF;;AANA;EACE,WAAA;AASF;;AANA;EACE,gBAAA;EACA,YAAA;AASF;AARE;EAHF;IAII,aAAA;EAWF;AACF","sourcesContent":[".BDTableRow {\n  margin-top: 4.2vh !important;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.table-header-row:nth-child(1) th {\n  background: #f0592a;\n  color: #fff;\n}\n\n.table-header-row:nth-child(2) th {\n  background: rgba(240, 89, 42, 0.8);\n  color: #fff;\n}\n\n.table-header-div p {\n  color: #000 !important;\n}\n\n.table-header-div span {\n  color: #f0592a !important;\n}\n\ntable {\n  border-collapse: collapse;\n  width: 100%;\n  text-align: center !important;\n}\n\n.bd-table thead,\ntbody,\ntfoot,\ntr,\nth {\n  border: 1px solid #bec5d7 !important;\n  text-align: center !important;\n  font-size: 2.2vh;\n  @media (max-width: 600px) {\n    font-size: 14px;\n  }\n}\n\n.bd-table th {\n  padding: 0.65rem;\n}\n\n.bd-table td {\n  border: 1px solid #bec5d7 !important;\n  text-align: center !important;\n  padding: 8px;\n  font-size: 2.2vh;\n  color: #000 !important;\n  @media (max-width: 600px) {\n    font-size: 14px;\n  }\n}\n\n.bd-table tr {\n  width: 80vw;\n}\n\n.bd-table tbody td {\n  background: #fff;\n  padding: 1vw;\n  @media (max-width: 600px) {\n    padding: 0rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
