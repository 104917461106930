import React from "react";
import { InfoBankingServices } from "../../Components/InfoBankingServices";
import CapitalMarketCards from "../../Components/CapitalMarketCards/CapitalMarketCards";
import { Accomplish } from "../../Components/Accomplishment";
import OurSuccessStory from "../../Components/OurSuccessStory/OurSuccessStory";
import { HeroIndustries } from "../../Components/HeroIndustries";

import bgImg5 from "../../assets/images/travel/TRAVEL_HOSPITALITY.jpg";
import squarImg from "../../assets/images/acoomplish_component/accomplishImg.jpg";
import travelCardImg1 from "../../assets/images/travel/travel_cardImg1.png";
import travelCardImg2 from "../../assets/images/travel/travel_cardImg2.png";
import travelCardImg3 from "../../assets/images/travel/travel_cardImg3.png";
import travelCardImg4 from "../../assets/images/travel/travel_cardImg4.png";
import commImg from "../../assets/images/travel/travel-sucess.png";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import { NewText } from "../../Components/NewText";
import { CommunicationHeading } from "../../Components/CommunicationHeading";
import { OrangeBar } from "../../Components/OrangeBar";
const dataTravel = [
  {
    card1: travelCardImg1,
    comCardText1: "Customer Experience",
    CardText1:
      "Elevate the passenger's journey with travel technology solutions that enhance personalization and create unforgettable experiences.",
  },
  {
    card1: travelCardImg2,
    comCardText1: "Asset Maintenance",
    CardText1:
      " Increase efficiency through travel technology that empowers employees and fosters seamless collaboration.",
  },
  {
    card1: travelCardImg3,
    comCardText1: "Operations Optimization",
    CardText1:
      "Increase efficiency through travel technology that empowers employees and fosters seamless collaboration",
  },
  {
    card1: travelCardImg4,
    comCardText1: "Technology Advisory & Engineering",
    CardText1:
      "Drive digital and data transformation in the Travel and Hospitality sector, leveraging the power of technology to shape the future of travel experiences.",
  },
];

function Travel() {
  FunctionTitles("NucleusTeq | Travel & Hospitality");
  return (
    <>
      <HeroIndustries
        pageName="INDUSTRIES  /  TRAVEL & HOSPITALITY"
        pageIntro="REVOLUTIONIZING TRAVEL & HOSPITALITY"
        pageIntroPara="Navigating the Era of Unprecedented Change"
        alt="img"
        image={bgImg5}
      />
      <InfoBankingServices
        heading="TRAVEL & HOSPITALITY "
        alt="Image"
        para1="
In an era characterized by volatility, intense competition, and rapid disruption, the Travel and Hospitality industries must adapt with unprecedented speed. With heightened expectations and exponential change, at NucleusTeq, we empower companies and their hospitality workforce with state-of-the-art tools—such as chatbots, data-driven insights, AI, and integrated cloud-based platforms. These technologies enable the delivery of seamless customer experiences and the cultivation of unparalleled loyalty."
        para2=""
        para3=""
      />
      <CommunicationHeading cardHeadingText="OUR CAPABILITIES" />

      <CapitalMarketCards dataTravel={dataTravel} />

      <NewText newtext="NucleusTeq is your partner in revolutionizing Travel & Hospitality, helping you stay agile and connected in a dynamic global landscape." />

      {/* <Accomplish
        squareImg={squarImg}
        accomplishText="OUR"
        accomplishText2=" ACCOMPLISHMENTS"
        accomplishText_fortune="Fortune 500 Clients"
      /> */}

      <OrangeBar />

      <OurSuccessStory
        successImg1={commImg}
        successHeading="OUR SUCCESS STORIES"
        successText="Sentiment Analysis of preferred customers for major US airline"
      />
    </>
  );
}

export default Travel;
