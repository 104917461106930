import React from "react";
import Hero from "../../Components/HeroIndustries/HeroIndustries";
import Accomplish from "../../Components/Accomplishment/Accomplish";
import OurSuccessStory from "../../Components/OurSuccessStory/OurSuccessStory";

import bgImg5 from "../../assets/images/retail/retail_bg_image.jpg";
import commImg from "../../assets/images/retail/retail_success_image.png";
import squarImg from "../../assets/images/acoomplish_component/accomplishImg.jpg";
import retail_Card_img1 from "../../assets/images/retail/retail_Card_img1.png";
import retail_Card_img2 from "../../assets/images/retail/retail_Card_img2.png";
import retail_Card_img3 from "../../assets/images/retail/retail_Card_img3.png";
import retail_Card_img4 from "../../assets/images/retail/retail_Card_img4.png";
import retail_Card_img5 from "../../assets/images/retail/retail_Card_img5.png";
import retail_Card_img6 from "../../assets/images/retail/retail_Card_img6.png";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";
import InfoBankingServices from "../../Components/InfoBankingServices/InfoBankingServices";
import { CommunicationHeading } from "../../Components/CommunicationHeading";
import { CommunicationCard } from "../../Components/CommunicationCard";
import { NewText } from "../../Components/NewText";
import { OrangeBar } from "../../Components/OrangeBar";
const dataCom = [
  {
    comCardImg1: retail_Card_img1,
    linkAnchor: "",
    comCardText1: "Consumer Product Development",
    CardText1:
      "Elevate your product development process to align with your brand's purpose, delivering what conscious consumers seek.",
  },
  {
    comCardImg1: retail_Card_img2,
    linkAnchor: "",
    comCardText1: "Software and Platforms",
    CardText1:
      "Leverage cutting-edge software and platforms to craft seamless, purpose-driven shopping experiences that resonate with your audience.",
  },
  {
    comCardImg1: retail_Card_img3,
    linkAnchor: "",
    comCardText1: "Communications Technology",
    CardText1:
      "Engage your audience with state-of-the-art communications technology that builds trust and strengthens connections.",
  },

  {
    comCardImg1: retail_Card_img4,
    linkAnchor: "",
    comCardText1: "Inventory Optimization",
    CardText1:
      "Streamline your inventory management, ensuring that your product offerings align with your purpose and values.",
  },
  {
    comCardImg1: retail_Card_img5,
    linkAnchor: "",
    comCardText1: "Supply Chain",
    CardText1:
      "Transform your supply chain to prioritize sustainability and responsibility, meeting the demands of today's conscious consumers.",
  },
  {
    comCardImg1: retail_Card_img6,
    linkAnchor: "",
    comCardText1: "Predictive Returns",
    CardText1:
      " Utilize predictive analytics to optimize your returns process, providing a seamless experience that aligns with your brand's mission.",
  },
];

function Retail() {
  FunctionTitles("NucleusTeq | Retail, Consumer Goods & Services");
  return (
    <>
      <Hero
        pageName="INDUSTRIES  /  RETAIL , CONSUMER GOODS & SERVICES"
        pageIntro="TRANSFORMING RETAIL, CONSUMER GOODS & SERVICES"
        alt="img"
        image={bgImg5}
        pageIntroPara={"Elevate Your Brand, Empower Your Purpose"}
      />

      <InfoBankingServices
        heading="RETAIL, CONSUMER GOODS & SERVICES"
        alt="Image"
        para1="In a world where conscious consumers seek meaning and authenticity in every brand they choose, responsible retailing is paramount. Retailers are now accountable to a broader spectrum of stakeholders, including consumers, employees, partners, investors, and the planet itself. NucleusTeq is your trusted partner in transforming purpose into performance, providing retailers with a significant competitive advantage in today's dynamic landscape."
        para2=""
        para3=""
      />
      <CommunicationHeading cardHeadingText="OUR RETAIL CAPABILITIES" />

      <CommunicationCard servicedata={dataCom} />
      <NewText newtext="NucleusTeq helps retail, consumer goods, and service companies thrive by serving their stakeholders in the era of purpose-driven brands." />
      {/* <Accomplish
        squareImg={squarImg}
        accomplishText="OUR"
        accomplishText2=" ACCOMPLISHMENTS"
        accomplishText_fortune="Fortune 500 Clients"
      /> */}

      <OrangeBar />

      <OurSuccessStory
        successImg1={commImg}
        successHeading="OUR SUCCESS STORIES"
        successText="Enterprise Data modernization of one of world’s largest credit card company from Teradata to On-Premise Big-Data Lake"
      />
    </>
  );
}

export default Retail;
