import React from "react";
import "./powered.scss";
import { Col, Row, Typography } from "antd";

const Powered = () => {
  const { Text } = Typography;
  return (
    <>
      <div className="powered">
        <Row justify={"center"} className="powered_Row">
          <Col className="powered_text">
        
            <div  className="poweredtexts">
              <span>
              <Text className="poweredBy">
                powered by
                <span className="textForBreezy">
                  {" "}
                  breezy
                  </span>
              </Text>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Powered;
