import "./nucleusteqVoices.scss";
import {Row} from "antd";
import ContentOfPages from "../../Components/ContentOfPages/ContentOfPages";
import { NewTitle } from "../NewTitle";

type Props = {
  text1: string;
  text2: string;
  image: string[];
};

const NucleusteqVoices = ({text2 }: Props) => {
  return (
    <div className="voicesBackground">
      <Row className="voicesRow">
        <NewTitle text={text2} textTitleClassName="textTitleClassName" />
      </Row>
      <Row>
      <ContentOfPages text="Get a glimpse into what NucleusTeq means to our employees. Hear their stories, experiences, and what they believe makes NucleusTeq one of the world's best organizations. This section is a window into life at NucleusTeq through the eyes of our exceptional team members." />
      </Row>
    </div>
  );
};

export default NucleusteqVoices;
