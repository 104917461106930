import { Row, Col, Typography } from "antd";
import "./newTitleForProduct.scss";

interface Props {
    text: string;
    text2?: string;
    text3?: string;
}
const { Text } = Typography;
export default function NewTitleForProduct({ text, text3, text2 }: Props) {
    return (
        <>
            <Row justify={"center"} className="newtitleRow">
                <Col xs={20} sm={22} md={22} lg={22} xl={22} xxl={22}>
                    <Text className="newTitleForProduct" style={{marginBottom:"2vh"}}>{text}</Text>
                </Col>
            </Row>
        </>
    )
}
