// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainHeadingForAll_heading {
  font-size: 2.5vw;
  font-family: Roboto;
  font-weight: 600;
  bottom: 4.5rem;
  padding: 6vw 0vw 0vw 0vw;
}

@media screen and (max-width: 576px) {
  .mainHeadingForAll_heading {
    font-size: 5.5vw;
    bottom: 4.5rem;
    line-height: 7.5vw;
    justify-content: center;
    text-align: center;
    display: flex;
    padding: 15vw 0vw 0vw 0vw;
  }
}
@media screen and (min-width: 2559px) {
  .mainHeadingForAll_heading {
    font-size: 3.3vw;
    padding: 3rem;
    justify-content: center;
    text-align: center;
    display: flex;
    padding: 6vw 0vw 0vw 0vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/MainHeadingForAll/mainHeadingForAll.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,wBAAA;AAAR;;AAIA;EAEY;IACI,gBAAA;IACA,cAAA;IACA,kBAAA;IACA,uBAAA;IACA,kBAAA;IACA,aAAA;IACA,yBAAA;EAFd;AACF;AAKA;EAEY;IACI,gBAAA;IACA,aAAA;IACA,uBAAA;IACA,kBAAA;IACA,aAAA;IACA,wBAAA;EAJd;AACF","sourcesContent":[".mainHeadingForAll {\n    &_heading {\n        font-size: 2.5vw;\n        font-family:Roboto;\n        font-weight: 600;\n        bottom: 4.5rem;\n        padding: 6vw 0vw 0vw 0vw;\n    }\n}\n\n@media screen and (max-width:576px){\n    .mainHeadingForAll {\n            &_heading {\n                font-size: 5.5vw;\n                bottom: 4.5rem;\n                line-height: 7.5vw;\n                justify-content: center;\n                text-align: center;\n                display: flex;\n                padding: 15vw 0vw 0vw 0vw;\n            }\n    }\n}\n@media screen and (min-width:2559px){\n    .mainHeadingForAll {\n            &_heading {\n                font-size: 3.3vw;\n                padding: 3rem;\n                justify-content: center;\n                text-align: center;\n                display: flex;\n                padding: 6vw 0vw 0vw 0vw;\n            }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
