import React from "react";
import { Row, Col, Typography } from "antd";
import BannerBg from "../../assets/images/product/fyndr-product/Product_fyndr.jpg";
import { ProductBanner } from "../../Components/ProductBanner";
import { KeyBenefits } from "../../Components/KeyBenefits";
import { CustomersAndPartners } from "../../Sections/HomePage/CustomerAndPartners";
import { NewTitleForProduct } from "../../Components/NewTitleForProduct";
import { FyndrAdvantage } from "../../Components/FyndrAdvantage";

const { Text } = Typography;

const FyndrProduct = (props: any) => {
  const benefitItems = [
    {
      text: "Making data consistent",
    },
    {
      text: "Improving data quality",
    },
    {
      text: "Making data accurate, complete",
    },
    {
      text: "Maximizing the use of data to make decisions",
    },
    {
      text: "Improving business planning",
    },
    {
      text: "Improving financial performance",
    },
    {
      text: "Maximizing profits of the company",
    },
  ];

  return (
    <>
      <div className="uiMain">
        <ProductBanner
          title="REVOLUTIONIZE YOUR BUSINESS WITH FYNDR"
          link="https://fyndr.us/"
          productBanner={BannerBg}
          title2="The All-in-One Solution to Promote Your Business and Connect with Consumers"
          btnTitle="Book a Demo Now"
          btnTitle2="Visit Fyndr"
        />
        <Row justify={"center"}>
          <Col>
            <NewTitleForProduct
              text="Do you run a business and find yourself overwhelmed by the chaos of managing various tasks, from sales to promotions,payments to inventory? Enter Fyndr, the game-changing platform that's here to simplify your life."
              text2="Fyndr is a comprehensive business solution, meticulously designed to integrate all your essential functions into one seamless online commerce marketplace and platform."
              text3="We empower businesses like yours with a complete, cost-effective solution that covers every aspect you need"
            />
          </Col>
          <Col xs={20} sm={22} md={22} lg={22} xl={22} xxl={22}>
            <Text className="newTitleForProductSubHead">
              Fyndr is a comprehensive business solution, meticulously designed
              to integrate all your essential functions into one seamless online
              commerce marketplace and platform.
            </Text>
          </Col>
          <Col xs={20} sm={22} md={22} lg={22} xl={22} xxl={22}>
            <Text className="newTitleForProductSubHead">
              We empower businesses like yours with a complete, cost-effective
              solution that covers every aspect you need
            </Text>
          </Col>
        </Row>
        <FyndrAdvantage />

        <CustomersAndPartners />
      </div>
    </>
  );
};

export default FyndrProduct;
