// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newTitleForProduct {
  color: #FFF;
  text-align: center;
  font-size: 2.4vh;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.newTitleForProductSubHead {
  color: #FFF;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 2.4vh;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 3.5vh;
}

.newtitleRow {
  width: 100%;
  text-align: center;
  margin: 15vh 0 0vh 0;
}
@media (max-width: 1024px) {
  .newtitleRow {
    margin: 8.4vh 0 0vh 0;
  }
}

@media (max-width: 1200px) {
  .newTitleForProduct {
    font-size: 2vh;
  }
  .newTitleForProductSubHead {
    font-size: 2vh;
  }
}
@media (max-width: 992px) {
  .newTitleForProduct {
    font-size: 1.5vh;
  }
  .newTitleForProductSubHead {
    font-size: 1.5vh;
  }
}
@media (max-width: 768px) {
  .newTitleForProduct {
    font-size: 2vh;
  }
  .newTitleForProductSubHead {
    font-size: 2vh;
  }
}
@media (max-width: 576px) {
  .newTitleForProduct {
    font-size: 2.7vh;
  }
  .newTitleForProductSubHead {
    font-size: 2.7vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/NewTitleForProduct/newTitleForProduct.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AACA;EACI,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AAEJ;;AAAA;EACI,WAAA;EACA,kBAAA;EACA,oBAAA;AAGJ;AAFI;EAJJ;IAKQ,qBAAA;EAKN;AACF;;AAAA;EACI;IACI,cAAA;EAGN;EADE;IACI,cAAA;EAGN;AACF;AACA;EACI;IACI,gBAAA;EACN;EAEE;IACI,gBAAA;EAAN;AACF;AAIA;EACI;IACI,cAAA;EAFN;EAKE;IACI,cAAA;EAHN;AACF;AAOA;EACI;IACI,gBAAA;EALN;EAOE;IACI,gBAAA;EALN;AACF","sourcesContent":[".newTitleForProduct {\n    color: #FFF;\n    text-align: center;\n    font-size: 2.4vh;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%;\n}\n.newTitleForProductSubHead{\n    color: #FFF;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    font-size: 2.4vh;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%;\n    margin-top: 3.5vh;\n}\n.newtitleRow {\n    width: 100%;\n    text-align: center;\n    margin: 15vh 0 0vh 0;\n    @media (max-width: 1024px) {\n        margin: 8.4vh 0 0vh 0;\n      }\n}\n\n\n\n@media (max-width: 1200px) {\n    .newTitleForProduct {\n        font-size:2vh;\n    }\n    .newTitleForProductSubHead{\n        font-size: 2vh;\n\n    }\n}\n\n@media (max-width: 992px) {\n    .newTitleForProduct {\n        font-size: 1.5vh;\n\n    }\n    .newTitleForProductSubHead{\n        font-size: 1.5vh;\n\n    }\n}\n\n@media (max-width: 768px) {\n    .newTitleForProduct {\n        font-size: 2vh;\n\n    }\n    .newTitleForProductSubHead{\n        font-size: 2vh;\n\n    }\n}\n\n@media (max-width: 576px) {\n    .newTitleForProduct {\n        font-size: 2.7vh;\n    }\n    .newTitleForProductSubHead{\n        font-size: 2.7vh;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
