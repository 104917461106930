// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-div {
  z-index: 0;
  position: absolute;
  margin-bottom: -40vh;
}

.successStyling {
  position: absolute;
  z-index: 1;
  width: 665px;
  height: 577px;
  border-radius: 665px;
  opacity: 0.5;
  background: rgba(240, 89, 42, 0.4);
  filter: blur(150px);
  top: 573px;
}

@media (max-width: 768px) {
  .successStyling {
    display: none;
  }
}
.successDiv {
  position: relative;
}

@media (max-width: 768px) {
  .successStyling {
    display: none;
  }
}
.successDiv {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/home.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,kBAAA;EACA,oBAAA;AACF;;AAGA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;EACA,YAAA;EACA,kCAAA;EACA,mBAAA;EACA,UAAA;AAAF;;AAEA;EACI;IACE,aAAA;EACJ;AACF;AAEA;EACI,kBAAA;AAAJ;;AACA;EACE;IACE,aAAA;EAEF;AACF;AACA;EACE,kBAAA;AACF","sourcesContent":[".grid-div {\n  z-index: 0;\n  position: absolute;\n  margin-bottom: -40vh;\n  \n}\n\n.successStyling {\n  position: absolute;\n  z-index: 1;\n  width: 665px;\n  height: 577px;\n  border-radius: 665px;\n  opacity: 0.5;\n  background: rgba(240, 89, 42, 0.4);\n  filter: blur(150px);\n  top: 573px;\n}\n@media (max-width: 768px) {\n    .successStyling {\n      display: none;\n    }\n  }\n\n.successDiv{\n    position: relative;}\n@media (max-width: 768px) {\n  .successStyling {\n    display: none;\n  }\n}\n\n.successDiv {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
