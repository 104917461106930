import "./bankCarousel.scss";
import { Tabs } from "antd";
import { CapitalMarketCards } from "../CapitalMarketCards";
import bank_card_Img1 from "../../assets/images/bank/bank_Card_img1.png";
import bank_card_Img2 from "../../assets/images/bank/bank_Card_img2.png";
import bank_card_Img3 from "../../assets/images/bank/bank_Card_img3.png";
import bank_card_Img4 from "../../assets/images/bank/bank_Card_img4.png";
import bank_card_Img5 from "../../assets/images/bank/bank_Card_img5.png";
import bank_card_Img6 from "../../assets/images/bank/bank_Card_img6.png";
import bank_card_Img7 from "../../assets/images/bank/bank_Card_img7.png";
import bank_card_Img8 from "../../assets/images/bank/bank_Card_img8.png";

import TabPane from "antd/es/tabs/TabPane";

const dataTravel = [
  {
    card1: bank_card_Img1,
    comCardText1: "Investment Banking & Brokerage",
    CardText1:
      "Institutional & Retail Brokerage, Prime Brokerage, Exchange Operations",
  },
  {
    card1: bank_card_Img2,
    comCardText1: "Asset & Wealth Management",
    CardText1:
      "Mutual Fund Investment, Management Alternative Investments, Wealth Management Investment, Product Distribution, Financial Advisory",
  },
  {
    card1: bank_card_Img3,
    comCardText1: "Security Services",
    CardText1:
      "Custody Services, Securities Lending, Corporate Actions, Asset Servicing, Transfer Agency, Trust Services",
  },
  {
    card1: bank_card_Img4,
    comCardText1: "Governance Risk & Compliance",
    CardText1:
      " AML (Anti-Money Laundering), SOX (Sarbanes-Oxley Act), Credit Risk, Market Risk, Operational Risk",
  },
];
const dataTravel2 = [
  {
    card1: bank_card_Img5,
    comCardText1: "Wholesome Banking",
    CardText1:
      "Commercial Lending, Trade Finance, Cash Management, Treasury Services, Structured Finance",
  },
  {
    card1: bank_card_Img6,
    comCardText1: "Retail Banking",
    CardText1:
      "Branch Banking, Deposit Banking, Online Banking, Customer Care, Teller Services",
  },
  {
    card1: bank_card_Img7,
    comCardText1: "Consumer Lending",
    CardText1: "Mortgages, Auto Loans, Educational Loans",
  },
  {
    card1: bank_card_Img8,
    comCardText1: "Cards & Payments",
    CardText1:
      "Transaction Processing, Application Processing, Customer Care, Collections & Recovery, Merchant Processing, Back-Office & Fulfillment",
  },
];
function BankCarousel() {
  return (
    <>
      <Tabs
        tabPosition="top"
        style={{ background: "#1D1A36" }}
        className="bankCarousel_tabPane"
      >
        <TabPane tab="Capital Markets" key="1" className="bankCarousel_tabPane">
          <CapitalMarketCards dataTravel={dataTravel} />
        </TabPane>
        <TabPane
          tab="Banking Services"
          key="2"
          className="bankCarousel_tabPane"
        >
          <CapitalMarketCards dataTravel={dataTravel2} />
        </TabPane>
      </Tabs>
    </>
  );
}

export default BankCarousel;
