// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ALTRowMain {
  width: 90%;
}

.ALTTab {
  text-align: center;
}

.ALTDiv {
  margin: 4%;
}`, "",{"version":3,"sources":["webpack://./src/Components/AutoLeftText/autoLeftText.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACI,UAAA;AACJ","sourcesContent":[".ALTRowMain {\n  width: 90%;\n}\n\n.ALTTab {\n  text-align: center;\n}\n\n.ALTDiv {\n    margin: 4%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
