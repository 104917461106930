import React, { useState, useEffect } from "react";
// import "./termsTab.scss";
import { Row, Col, Tabs } from "antd";

const { TabPane } = Tabs;

const Content1 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      Welcome to{" "}
      <a
        style={{ color: "#fff" }}
        href="https://nucleusteq.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        NucleusTeq
      </a>{" "}
      At{" "}
      <a
        style={{ color: "#fff" }}
        href="https://nucleusteq.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        NucleusTeq
      </a>
      , we respect your privacy and are committed to protecting your personal
      information. This Privacy Policy explains how we collect, use, and
      safeguard your data when you visit our website.
    </div>
  </div>
);

const Content2 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      We Collect Personal Information: When you visit our website, we may
      collect certain personally identifiable information, such as your name,
      email address, postal address, and phone number, if provided voluntarily.
      Non-Personal Information: We may also collect non-personal information,
      such as browser type, IP address, device information, and cookies, to
      enhance your user experience.
    </div>
  </div>
);

const Content3 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      We may use your information for the following purposes: To provide and
      maintain our website's functionality and services. To communicate with you
      and respond to your inquiries. To personalize your experience and improve
      our website. To send updates, newsletters, and promotional materials, if
      you've opted to receive them. To comply with legal obligations.
    </div>
  </div>
);

const Content4 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      Our website may use cookies and similar tracking technologies to collect
      and store certain information about your browsing preferences. You can
      manage cookie preferences through your browser settings.
    </div>
  </div>
);

const Content5 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      We implement reasonable security measures to protect your data; however,
      no data transmission over the internet is completely secure. We cannot
      guarantee the security of your information.
    </div>
  </div>
);

const Content6 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      Our website may contain links to third-party websites or services. We have
      no control over their content or practices and are not responsible for
      their privacy policies.
    </div>
  </div>
);

const Content7 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      You may have options regarding the use of your data, including opting out
      of promotional communications or updating your personal information.
      Please contact us to exercise your choices.
    </div>
  </div>
);

const Content8 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      We may update this Privacy Policy to reflect changes in our practices or
      for legal reasons. We will notify you of any significant updates.
    </div>
  </div>
);

const Content9 = () => (
  <div>
    <p className="paragraphText">Effective Date: March 24th, 2021</p>
    <div className="commonListStyle paragraphText">
      If you have questions or concerns about this Privacy Policy or our data
      practices, please contact us at{" "}
      <a style={{ color: "#fff" }} href="mailto:ops@nucleusteq.com">
        ops@nucleusteq.com
      </a>
    </div>
  </div>
);

const tabData = [
  {
    title: "Introduction",
    key: "1",
    content: <Content1 />,
  },
  {
    title: "Information",
    key: "2",
    content: <Content2 />,
  },
  {
    title: "How We Use Your Information",
    key: "3",
    content: <Content3 />,
  },
  {
    title: "Cookies and Tracking Technologies",
    key: "4",
    content: <Content4 />,
  },
  {
    title: "Data Security",
    key: "5",
    content: <Content5 />,
  },
  {
    title: "Third-Party Links",
    key: "6",
    content: <Content6 />,
  },
  {
    title: "Your Privacy Choices",
    key: "7",
    content: <Content7 />,
  },
  {
    title: "Changes to this Privacy Policy",
    key: "8",
    content: <Content8 />,
  },
  {
    title: "Contact Us",
    key: "9",
    content: <Content9 />,
  },

  //   {
  //     title: "Dispute Resolution/arbitration Agreement",
  //     key: "16",
  //     content: <Content1 />,
  //   },
  //   {
  //     title: "Additional Disclosures",
  //     key: "17",
  //     content: <Content2 />,
  //   },
];

interface TermsContentProps {
  content: React.ReactNode;
}

const TermsContent: React.FC<TermsContentProps> = ({ content }) => (
  <Row align={"middle"} justify={"center"}>
    <Col
      span={24}
      className="bdBlueOrangeGrad"
      style={{ marginBottom: "0px", minHeight: "115.5vh" }}
    >
      <span>
        <Col
          span={24}
          style={{ textAlign: "left", padding: "2vw", minHeight: "115.5vh" }}
        >
          {content}
        </Col>
      </span>
    </Col>
  </Row>
);

const Policy = () => {
  return (
    <Row className="terms-main">
      <p className="terms-para">Privacy Statement Structure</p>
      <Col xs={0} sm={0} md={24}>
        <Tabs tabPosition={"left"} className="term-tab">
          {tabData.map((tab) => (
            <TabPane tab={tab.title} key={tab.key} className="term-tab">
              <TermsContent content={tab.content} />
            </TabPane>
          ))}
        </Tabs>
      </Col>
      <Col xs={24} sm={24} md={0}>
        <Tabs tabPosition={"top"} className="term-tab">
          {tabData.map((tab) => (
            <TabPane tab={tab.title} key={tab.key} className="term-tab">
              <TermsContent content={tab.content} />
            </TabPane>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
};

export default Policy;