import React, { useState } from "react";
import { Tabs } from "antd";
import LeftText from "../LeftText/LeftText";
import AutoLS1 from "../../assets/images/auto/auto_left_img1.png";
import AutoLS2 from "../../assets/images/auto/auto_left_img2.png";
import AutoLS3 from "../../assets/images/auto/auto_left_img3.png";
import "./autoLeftText.scss";

const { TabPane } = Tabs;

function AutoLeftText() {
  const leftTextItems = [
    { text: "Automation Advisory & Engineering", className: "headingTextMain" },
    {
      text: "At NucleusTeq, we assess your company's digital readiness, create a tailored digital transformation roadmap, and equip you for enhanced speed, ease, and performance. Our core advisory services include:",
      className: "paragraphText",
    },
    {
      text: "Automation Maturity Assessment",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Automation Tooling Strategy",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Automation Solution Blueprinting",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Roadmap & Cost Benefit Analysis",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Automation development",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Automation Quality Assurance",
      className: "paragraphText",
      isList: true,
    },
  ];

  const leftTextItems2 = [
    { text: "Automation Managed Services", className: "headingTextMain" },
    {
      text: "While the customer owns the organization, we manage and provide it with the necessary services and support.",
      className: "paragraphText",
    },
    {
      text: "Enhancements",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Production Support & SLA Management",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Reporting & Escalation Management",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Training & Enablement",
      className: "paragraphText",
      isList: true,
    },
  ];

  const leftTextItems3 = [
    { text: "Automation Training", className: "headingTextMain" },
    {
      text: "With our training programs, we tailor towards your organizational goals that enable true “People, Process, Technology” transformation for your company. We provide:",
      className: "paragraphText",
    },
    {
      text: "Offshore & Onshore Training (2-3 Days)",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Hands-on Projects Training (1-3 Weeks)",
      className: "paragraphText",
      isList: true,
    },
    {
      text: "Product-specific training (UiPath, Automation Anywhere, Jacada, BluePrism)",
      className: "paragraphText",
      isList: true,
    },
  ];

  const [selectedTab, setSelectedTab] = useState("1");

  const handleTabChange = (key: any) => {
    setSelectedTab(key);
  };

  return (
    <>
      <Tabs
        activeKey={selectedTab}
        onChange={handleTabChange}
        tabPosition="top"
        className="bd-tab"
      >
        <TabPane
          tab="Automation Advisory & Engineering"
          key="1"
          className="bd-tab"
        >
          <div className="BDLeftTextDiv">
            <LeftText rightTextImage={AutoLS1} leftTextItems={leftTextItems} />
          </div>
        </TabPane>
        <TabPane tab="Automation Managed Services" key="2" className="bd-tab">
          <div className="BDLeftTextDiv">
            <LeftText rightTextImage={AutoLS2} leftTextItems={leftTextItems2} />
          </div>
        </TabPane>
        <TabPane tab="Automation Training" key="3" className="bd-tab">
          <div className="BDLeftTextDiv">
            <LeftText rightTextImage={AutoLS3} leftTextItems={leftTextItems3} />
          </div>
        </TabPane>
      </Tabs>
    </>
  );
}

export default AutoLeftText;
