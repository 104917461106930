import { Button, Col, Collapse, Row, Layout, Space, Image } from "antd";

import down from "../../assets/images/product/Faq/down.svg";
import React, { useState } from "react";
import productBanner from "../../assets/images/product/Faq/faq.jpg";
import "./faq.scss";
import { CommonButton } from "../CommonButton";
const { Panel } = Collapse;
const items = [
  {
    key: "1",
    label:
      "If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text?",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus congue sodales quam fermentum consectetur. Phasellus pulvinar tempus arcu, eget pulvinar turpis lobortis id. Morbi volutpat vulputate dui id fringilla. Nam non efficitur lectus. Pellentesque efficitur dictum tortor. In hac habitasse platea dictumst. Donec ut dignissim lectus. Ut sollicitudin ipsum eu viverra convallis. Maecenas augue risus, faucibus nec erat ac, vehicula blandit arcu. Fusce lacinia bibendum pretium. Nulla a augue et urna commodo porta quis quis dolor. Aenean volutpat malesuada nulla, quis tempus leo. Quisque luctus sed nisl vel ultricies. Sed cursus venenatis urna, nec aliquet ligula sagittis a. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis blandit elit ut varius pulvinar.",
  },
  {
    key: "2",
    label: "Where can I get some?",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus congue sodales quam fermentum consectetur. Phasellus pulvinar tempus arcu, eget pulvinar turpis lobortis id. Morbi volutpat vulputate dui id fringilla. Nam non efficitur lectus. Pellentesque efficitur dictum tortor. In hac habitasse platea dictumst. Donec ut dignissim lectus. Ut sollicitudin ipsum eu viverra convallis. Maecenas augue risus, faucibus nec erat ac, vehicula blandit arcu. Fusce lacinia bibendum pretium. Nulla a augue et urna commodo porta quis quis dolor. Aenean volutpat malesuada nulla, quis tempus leo. Quisque luctus sed nisl vel ultricies. Sed cursus venenatis urna, nec aliquet ligula sagittis a. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis blandit elit ut varius pulvinar.",
  },
  {
    key: "3",
    label: "Where does it come from?",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus congue sodales quam fermentum consectetur. Phasellus pulvinar tempus arcu, eget pulvinar turpis lobortis id. Morbi volutpat vulputate dui id fringilla. Nam non efficitur lectus. Pellentesque efficitur dictum tortor. In hac habitasse platea dictumst. Donec ut dignissim lectus. Ut sollicitudin ipsum eu viverra convallis. Maecenas augue risus, faucibus nec erat ac, vehicula blandit arcu. Fusce lacinia bibendum pretium. Nulla a augue et urna commodo porta quis quis dolor. Aenean volutpat malesuada nulla, quis tempus leo. Quisque luctus sed nisl vel ultricies. Sed cursus venenatis urna, nec aliquet ligula sagittis a. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis blandit elit ut varius pulvinar.",
  },
  {
    key: "4",
    label:
      "Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    text: "faDF SAGDAJAS  DSAJDGH  AJSDGHDHDS JHASGD JDG DSHJADSG HDGJDSFDGHJD DDDDDFHYUH WQGEHB",
  },
  {
    key: "5",
    label:
      "The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
    text: "faDF SAGDAJAS  DSAJDGH  AJSDGHDHDS JHASGD JDG DSHJADSG HDGJDSFDGHJD DDDDDFHYUH WQGEHB",
  },
  {
    key: "6",
    label:
      "Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    text: "faDF SAGDAJAS  DSAJDGH  AJSDGHDHDS JHASGD JDG DSHJADSG HDGJDSFDGHJD DDDDDFHYUH WQGEHB",
  },
  {
    key: "7",
    label:
      "Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    text: "faDF SAGDAJAS  DSAJDGH  AJSDGHDHDS JHASGD JDG DSHJADSG HDGJDSFDGHJD DDDDDFHYUH WQGEHB",
  },
  {
    key: "8",
    label:
      "Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    text: "faDF SAGDAJAS  DSAJDGH  AJSDGHDHDS JHASGD JDG DSHJADSG HDGJDSFDGHJD DDDDDFHYUH WQGEHB",
  },
];

export default function FAQ() {
  const [isCollapsed, setIsCollapsed] = useState<any>([]);
  const { Content } = Layout;
  return (
    <>
      <div
        className="layoutProductBannerImg"
        style={{
          backgroundImage: `url(${productBanner})`,
          backgroundColor: "#1D1A36",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 className="layoutProductBannerHead">FAQ</h1>
        </div>
      </div>

      <Row justify={"center"} style={{ background: "#1d1a36" }}>
        <Col span={22}>
          <div>
            <Space direction="vertical" style={{ width: "100%" }} size={20}>
              
                <Collapse
                  style={{
                    border: "0px solid var(--Gradient_blue_orange, #ffb6a0)",
                  }}
                  accordion={true}
                  onChange={(e) => {
                    setIsCollapsed(e);
                  }}
                >
                  {items.map((elm, key) => (
                  <Panel
                    key={key + "faq"}
                    className="abc"
                    style={{
                      border: "1px solid var(--Gradient_blue_orange, #ffb6a0)",
                      background: "#1d1a36",
                      borderRadius: "5px",
                      marginTop:"1rem",
                    }}
                    header={
                      <Row
                        style={{ color: "#fff" }}
                        className="faq_questionRow"
                      >
                        <Col xs={20} sm={20} lg={20} xl={22} xxl={22}  className="faq_questionCol">
                          <span>{elm.label}</span>
                        </Col>
                        <Col
                          
                          xs={4} sm={4} lg={4} xl={2} xxl={2}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            {" "}
                            <Image
                              className="faq-imgIcon"
                              preview={false}
                              src={down}
                              style={{
                                transform: isCollapsed.includes(key + "faq")
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                                transition: "transform 0.5s",
                              }}
                            ></Image>
                          </div>
                        </Col>
                      </Row>
                    }
                    showArrow={false}
                  >
                    <span className="faq_answerFaq">{elm.text}</span>
                  </Panel>
              ))}
                </Collapse>
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
}
