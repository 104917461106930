import React from "react";
import "./blogLeftText.scss";
import { Col, Row } from "antd";

interface Props {
  leftTextImage: string;
  leftTextTitle: string;
  leftText: string;
}

const BlogLeftText = ({ leftTextImage, leftText, leftTextTitle }: Props) => {
  const aboutLines = leftText.split("\n");
  return (
    <div>
      <Row justify={"center"} align={"middle"}>
        <Col span={24} className="ltCenter brtTitle">
          {leftTextTitle}
        </Col>

        <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} className="ltCenter">
          <img
            src={leftTextImage}
            className="brtImageWidth bltImageMob"
            alt="nucleusteq"
            loading="lazy"
          />
        </Col>

        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
          <Row className="ltCenter headingParagraph bltText">
            {aboutLines.map((line, index) => (
              <span
                key={index}
                style={{
                  marginBottom: index !== aboutLines.length - 1 ? "30px" : 0,
                }}
              >
                {line}
                <br />
              </span>
            ))}
          </Row>
        </Col>
        <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={8} className="ltCenter">
          <img
            src={leftTextImage}
            className="brtImageWidth bltImageDesk"
            alt="nucleusteq"
            loading="lazy"
          />
        </Col>
      </Row>
    </div>
  );
};

export default BlogLeftText;
