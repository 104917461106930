import React from "react";
import { Row, Col } from "antd";
import "./partners.scss";

interface Props {
  partnerTitle: string;
  partnerImages: string[];
}

const Partners = ({ partnerTitle, partnerImages }: Props) => {
  return (
    <div className="partnersDiv">
      <Row className="partnersRowMain">
        <Row className="partnersRowMain">
          <Col xs={22} sm={22} md={23} lg={23} xl={23} xxl={23}>
            <h3 className="commonTitleHead">{partnerTitle}</h3>
          </Col>
        </Row>

        <Col
          xs={22}
          sm={22}
          md={23}
          lg={23}
          xl={23}
          xxl={23}
          className="partnersMainDiv"
        >
          <Row align="middle" justify="center" className="partnersRowMain">
            {partnerImages.map((partnerImage, index) => (
              <Col key={`partner-${index}`} className="partnersImageCol">
                <img
                  src={partnerImage}
                  alt="partnerImage"
                  width="100%"
                  loading="lazy"
                  className="partnersImage"
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Partners;
