// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-card {
  width: 100%;
  aspect-ratio: 3/2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 0px 0px;
  cursor: pointer;
}

.blog-overlay-text {
  display: flex;
  justify-content: center;
  margin-top: auto;
  color: #333;
  text-align: center;
  font-family: Roboto;
  font-weight: 400;
  border-radius: 0px 0px 15px 15px;
  padding: 16px;
  background: #fff;
}
@media (max-width: 991px) {
  .blog-overlay-text {
    padding: 8px 8px 16px 8px;
  }
}

.blog-overlay-text p {
  font-weight: 400;
  color: #333;
  font-size: 1.1vw !important;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .blog-overlay-text p {
    font-size: 16px !important;
  }
}

.blog-overlay-text h1 {
  color: #253c77;
  text-align: center;
  font-size: 1.2vw;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .blog-overlay-text h1 {
    font-size: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/BlogCards/blogCards.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,sCAAA;EACA,gCAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gCAAA;EACA,aAAA;EACA,gBAAA;AACF;AAAE;EAXF;IAYI,yBAAA;EAGF;AACF;;AAAA;EACE,gBAAA;EACA,WAAA;EACA,2BAAA;EACA,gBAAA;AAGF;AAFE;EALF;IAMI,0BAAA;EAKF;AACF;;AAFA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAKF;AAHE;EANF;IAOI,eAAA;EAMF;AACF","sourcesContent":[".blog-card {\n  width: 100%;\n  aspect-ratio: 3/2;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);\n  border-radius: 15px 15px 0px 0px;\n  cursor: pointer;\n}\n\n.blog-overlay-text {\n  display: flex;\n  justify-content: center;\n  margin-top: auto;\n  color: #333;\n  text-align: center;\n  font-family: Roboto;\n  font-weight: 400;\n  border-radius: 0px 0px 15px 15px;\n  padding: 16px;\n  background: #fff;\n  @media (max-width: 991px) {\n    padding: 8px 8px 16px 8px;\n  }\n}\n\n.blog-overlay-text p {\n  font-weight: 400;\n  color: #333;\n  font-size: 1.1vw !important;\n  margin-bottom: 0;\n  @media (max-width: 1024px) {\n    font-size: 16px !important;\n  }\n}\n\n.blog-overlay-text h1 {\n  color: #253c77;\n  text-align: center;\n  font-size: 1.2vw;\n  font-weight: 500;\n\n  @media (max-width: 1024px) {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
