// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridBoxMain {
  background-color: #1d1a36;
}

.gridBox {
  background-color: rgba(240, 89, 42, 0.1);
  margin: 1.69vh;
  height: 15vh;
  border-top: 0.5vh solid #fff;
}
@media (max-width: 1024px) {
  .gridBox {
    border-top: 0.25rem solid #fff;
  }
}

.gridBox:hover {
  border-top: 0.5vh solid #f0592a;
}
@media (max-width: 1024px) {
  .gridBox:hover {
    border-top: 0.25rem solid #f0592a;
  }
}

.gridBoxImage {
  display: flex;
  justify-content: left;
  height: 13.6vh;
}

.gridBoxText {
  display: flex;
  justify-content: center;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/Components/GridBox/gridBox.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,wCAAA;EACA,cAAA;EACA,YAAA;EACA,4BAAA;AACF;AAAE;EALF;IAMI,8BAAA;EAGF;AACF;;AAAA;EACE,+BAAA;AAGF;AAFE;EAFF;IAGI,iCAAA;EAKF;AACF;;AAFA;EACE,aAAA;EACA,qBAAA;EACA,cAAA;AAKF;;AAFA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AAKF","sourcesContent":[".gridBoxMain {\n  background-color: #1d1a36;\n}\n\n.gridBox {\n  background-color: rgba(240, 89, 42, 0.1);\n  margin: 1.69vh;\n  height: 15vh;\n  border-top: 0.5vh solid #fff;\n  @media (max-width: 1024px) {\n    border-top: 0.25rem solid #fff;\n  }\n}\n\n.gridBox:hover {\n  border-top: 0.5vh solid #f0592a;\n  @media (max-width: 1024px) {\n    border-top: 0.25rem solid #f0592a;\n  }\n}\n\n.gridBoxImage {\n  display: flex;\n  justify-content: left;\n  height: 13.6vh;\n}\n\n.gridBoxText {\n  display: flex;\n  justify-content: center;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
