import { Row, Col, Space } from "antd";
import "./autoTable.scss";
import thead1 from "../../assets/images/auto/auto_thead1.svg";
import thead2 from "../../assets/images/auto/auto_thead2.svg";
import thead3 from "../../assets/images/auto/auto_thead3.svg";
import thead4 from "../../assets/images/auto/auto_thead4.svg";
import thead5 from "../../assets/images/auto/auto_thead5.svg";
import green from "../../assets/images/auto/auto_table-green.png";
import yellow from "../../assets/images/auto/auto_table-yellow.png";
import red from "../../assets/images/auto/auto_table-red.png";

const data = [
  {
    category: "BANKING",
    cells: [red, yellow, yellow, yellow, red],
    description: ["Cards activation", "Fraud claim discovery"],
  },
  {
    category: "INSURANCE",
    cells: [yellow, green, green, yellow, red],
    description: ["Claim processing", "New business preparation"],
  },
  {
    category: "HEALTHCARE",
    cells: [yellow, yellow, yellow, yellow, red],
    description: ["Reports automation", "System reconciliation"],
  },
  {
    category: "MANUFACTURING",
    cells: [red, yellow, yellow, green, green],
    description: ["Bills of Material generation"],
  },
  {
    category: "HI-TECH & TELECOM",
    cells: [yellow, yellow, green, yellow, yellow],
    description: ["Service order management", "Quality reporting"],
  },
  {
    category: "ENERGY & UTILITIES",
    cells: [yellow, yellow, yellow, yellow, yellow],
    description: ["Account Setup", "Meter reading validation"],
  },
];

const AutoTable = () => {
  return (
    <Row justify="center" className="AutoTableRow">
      <Col xs={22} sm={22} md={22} lg={22} xl={20} xxl={20}>
        <div className="auto-table-container">
          <table className="auto-table">
            <thead>
              <tr className="auto-table_row">
                <th></th>
                <th>
                  <Row className="AutoTableRow">
                    <img src={thead1} alt="thead1" className="AutoHeadImg" />
                  </Row>
                </th>
                <th>
                  <Row className="AutoTableRow">
                    <img src={thead2} alt="thead2" className="AutoHeadImg" />
                  </Row>
                </th>
                <th>
                  <Row className="AutoTableRow">
                    <img src={thead3} alt="thead3" className="AutoHeadImg" />
                  </Row>
                </th>
                <th>
                  <Row className="AutoTableRow">
                    <img src={thead4} alt="thead4" className="AutoHeadImg" />
                  </Row>
                </th>
                <th>
                  <Row className="AutoTableRow">
                    <img src={thead5} alt="thead5" className="AutoHeadImg" />
                  </Row>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.category}</td>
                  {entry.cells.map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      <Row className="AutoTableRow">
                        <img
                          src={cell}
                          alt={`cell${cellIndex + 1}`}
                          width={"40%"}
                        />
                      </Row>
                    </td>
                  ))}
                  <td>
                    {entry.description[0]}
                    <p className="AutoSmallText">{entry.description[1]}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
      <Col
        xs={22}
        sm={22}
        md={22}
        lg={22}
        xl={20}
        xxl={20}
        style={{ marginTop: "5vh" }}
      >
        <Space
          direction="vertical"
          size={"small"}
          className="auto-table_details-Col"
        >
          <Row className="auto-table_details-Col">
            <h4>RPA ADOPTION EXTENT</h4>
          </Row>
          <Row className="auto-table_details-Col">
            <Space
              direction="horizontal"
              size={"large"}
              className="auto-table_details"
            >
              <ul>
                <li>High</li>
                <li>Medium</li>
                <li>Low</li>
              </ul>
              <ul>
                <li>
                  <img src={red} alt="red" width={"20vw"} />
                </li>
                <li>
                  <img src={yellow} alt="yellow" width={"20vw"} />
                </li>
                <li>
                  <img src={green} alt="green" width={"20vw"} />
                </li>
              </ul>
              <ul>
                <li>Account Receivable, Account Payable, General Ledger</li>
                <li>
                  Invoice processing from requisition to issue of purchase order
                </li>
                <li>Payroll, Hiring, Data management</li>
                <li>Customer Service</li>
              </ul>
            </Space>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default AutoTable;
