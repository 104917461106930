import React from "react";
import "./portalCards.scss";
import { Col, Row, Card, Image } from "antd";
import { CommonButton } from "../CommonButton";
import { BsArrowRight } from "react-icons/bs";

interface Props {
  location: string;
  role: string;
  salary: string;
  position: string;
  image1: string;
  image2: string;
  image3: string;
}

const PortalCards = ({
  location,
  role,
  position,
  salary,
  image1,
  image2,
  image3,
}: Props) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(146deg, rgba(255,182,160,1) 0%, rgba(240,89,42,1) 26%, rgba(37,60,119,1) 100%)",
        padding: "1px",
        borderRadius: "20px",
        width: "90%",
      }}
    >
      <Card className="portal_Cards">
        <Row>
          <Col span={24}>
            <Row align="middle">
              <Col xs={7} sm={4} md={4} lg={3} xl={2} xxl={2}>
                <p>
                  <Image src={image1} preview={false} alt="" width={"3vh"} />
                </p>
              </Col>
              <Col xs={14} sm={20} md={20} lg={21} xl={22} xxl={22}>
                <p>
                  <span className="cardLocations">{location}</span>
                </p>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col xs={7} sm={4} md={4} lg={2} xl={1} xxl={1}>
                <p>
                  <Image src={image2} preview={false} alt="" width={"3vh"} />
                </p>
              </Col>
              <Col xs={17} sm={20} md={20} lg={10} xl={10} xxl={10}>
                <p>
                  <span className="cardrole">{role}</span>
                </p>
              </Col>
              <Col xs={7} sm={4} md={4} lg={2} xl={1} xxl={1}>
                <p>
                  <Image src={image3} preview={false} alt="" width={"3vh"} />
                </p>
              </Col>
              <Col xs={17} sm={20} md={20} lg={8} xl={10} xxl={10}>
                <p>
                  <span className="cardrole">{salary}</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Col span={24}>
          <Row>
            <Col xl={1}></Col>
            <Col xl={2}>
              <CommonButton
                buttonText="Apply"
                link=""
                icon={<BsArrowRight />}
              />
            </Col>
          </Row>
        </Col>
      </Card>
    </div>
  );
};

export default PortalCards;
