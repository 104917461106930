import { Col, Divider, Image, Row } from "antd";
import "./heroIndustries.scss";
import insta from "../../assets/images/communication/insta.svg";
import linkdin from "../../assets/images/communication/linkdin.svg";
import twitter from "../../assets/images/communication/twitter.svg";
import { FAQButton } from "../FAQButton";
import { WeEmpower } from "../WeEmpower";

interface Props {
  image: string;
  pageName: String;
  pageIntro: String;
  pageIntroPara: String;
  alt: String;
}

const Hero = ({ image, pageName, pageIntro, pageIntroPara }: Props) => {
  return (
    <div
      style={{
        background: `url(${image}) no-repeat center`,
      }}
      className="heroCss_backgroundImgDiv"
    >
      <Row className="heroCss_heroMainRow">
        <Col
          sm={24}
          xs={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="heroCss_text"
        >
          <p className="heroCss_pageIntroTextPara">{pageName}</p>
        </Col>
        <div className="heroCss_dividerDiv">
          <Divider type="horizontal" className="heroCss_dividerIntro" />
        </div>
        <Col
          sm={24}
          xs={24}
          md={20}
          lg={20}
          xl={20}
          xxl={20}
          className="heroCss_pageIntroText"
        >
          <h1 className="heroCss_pageIntroTextPara"> {pageIntro}</h1>
          <span>{pageIntroPara}</span>
        </Col>
        <div className="heroCss_socialMediaLogo">
          <Row className="heroCss_weEmpowerMainRow">
            <Col
              sm={22}
              xs={24}
              md={22}
              lg={22}
              xl={22}
              xxl={22}
              style={{ marginTop: "2vw" }}
            >
              {/* <WeEmpower /> */}
            </Col>
            {/* <Col
              sm={2}
              xs={24}
              md={10}
              lg={10}
              xl={2}
              xxl={2}
              className="heroCss_socialMediaImg_desktop"
            >
              <Col style={{ display: "flex", justifyContent: "right" }}>
                <Image
                  src={linkdin}
                  preview={false}
                  className="heroCss_socialMediaLogo_linkedin"
                />
              </Col>

              <Col
                style={{
                  marginTop: "1vw",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Image
                  src={twitter}
                  preview={false}
                  className="heroCss_socialMediaLogo_linkedin"
                />
              </Col>

              <Col
                style={{
                  marginTop: "1vw",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Image
                  src={insta}
                  preview={false}
                  className="heroCss_socialMediaLogo_linkedin"
                />
              </Col>
            </Col>

            <div className="heroCss_socialMediaImg_mobileView">
              <div>
                <Image
                  src={linkdin}
                  preview={false}
                  className="heroCss_socialMediaLogo_linkedin"
                />
                <Image
                  src={twitter}
                  preview={false}
                  style={{ paddingLeft: "4vw" }}
                  className="heroCss_socialMediaLogo_linkedin"
                />
                <Image
                  src={insta}
                  preview={false}
                  style={{ paddingLeft: "4vw" }}
                  className="heroCss_socialMediaLogo_linkedin"
                />
              </div>
            </div> */}
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default Hero;
