// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.industryText {
  display: flex;
  justify-content: left;
  text-align: left;
  font-weight: 600;
  align-items: center !important;
}

.ltCenter {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.ltTextCol {
  margin-top: 1.8rem;
}

.ltTextRow {
  width: 100%;
}

.ltImageWidth {
  width: 90%;
  object-fit: contain;
}
@media (max-width: 992px) {
  .ltImageWidth {
    width: 100%;
  }
}

.leftTextHide {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/Components/LeftText/leftText.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,gBAAA;EACA,gBAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,8BAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;EACA,mBAAA;AACF;AAAE;EAHF;IAII,WAAA;EAGF;AACF;;AAAA;EACE,kBAAA;AAGF","sourcesContent":[".industryText {\n  display: flex;\n  justify-content: left;\n  text-align: left;\n  font-weight: 600;\n  align-items: center !important;\n}\n\n.ltCenter {\n  display: flex;\n  justify-content: center;\n  align-items: center !important;\n}\n\n.ltTextCol {\n  margin-top: 1.8rem;\n}\n\n.ltTextRow {\n  width: 100%;\n}\n\n.ltImageWidth {\n  width: 90%;\n  object-fit: contain;\n  @media (max-width: 992px) {\n    width: 100%;\n  }\n}\n\n.leftTextHide {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
