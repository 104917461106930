import React, { useState } from "react";
import { Col, Modal, Row, Space } from "antd";
// import "./blogCards.scss";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { CommonButton } from "../CommonButton";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
type Props = {
  blogdata: {
    blogCardImg1: string;
    CardText1: string;
    CardTitle: string;
    linkAnchor: string;
  }[];
  onEditButtonClick: (card: { CardTitle: string }) => void;
};

const BlogEditCards = ({ blogdata, onEditButtonClick }: Props) => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <Row gutter={[30, 30]} style={{ margin: "0 auto" }} justify={"center"}>
        {blogdata.map((card, index) => (
          <Col key={index} xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
            <div
              className="blog-card"
              style={{
                background: `url("${card.blogCardImg1}") no-repeat center center`,
                backgroundSize: "cover",
                margin: "0 auto",
              }}
            ></div>
            <div className="blog-overlay-text">
              <Space direction="vertical" size={"small"}>
                <h1>{card.CardTitle}</h1>
                <p>{card.CardText1}</p>
                <Space direction="horizontal" size={"small"}>
                  <CommonButton
                    buttonText="Delete"
                    link={"#"}
                    style={{ marginTop: "0" }}
                    onClick={() => setIsModalVisible(true)}
                    icon={<DeleteOutlined style={{ padding: 0 }} />}
                  />
                  <CommonButton
                    buttonText="Edit"
                    link={"#"}
                    style={{ marginTop: "0" }}
                    onClick={() => {
                      onEditButtonClick(card);
                      handleLinkClick();
                    }}
                    icon={<EditOutlined style={{ padding: 0 }} />}
                  />
                </Space>
              </Space>
            </div>
          </Col>
        ))}
        <Modal
          title="Delete"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          className="faqModal"
          closeIcon={<CloseCircleOutlined className="faqModalClose" />}
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row
            justify={"center"}
            align={"middle"}
            style={{ width: "100%", padding: "24px" }}
          >
            <Col span={20}>
              <Space direction="vertical" size={"large"}>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to delete this blog?
                </p>
                <Space direction="horizontal" size={"middle"}>
                  <CommonButton
                    buttonText="Yes"
                    link="#"
                    onClick={handleCancel}
                  />{" "}
                  <CommonButton
                    buttonText="No"
                    link="#"
                    onClick={handleCancel}
                  />
                </Space>
              </Space>
            </Col>
          </Row>
        </Modal>
      </Row>
    </div>
  );
};

export default BlogEditCards;
