import { Row } from "antd";
import "./pageHeading.scss";

interface Props {
  heading: string;
  subHeading: string;
}
const PageHeading = ({ heading, subHeading }: Props) => {
  return (
    <div className="pageheading_div">
      <Row justify={"center"} className="pageheading">
        {heading}
      </Row>
      {subHeading && (
        <Row justify={"center"} align={"middle"}>
          <h2 className="pageheading_sub">{subHeading}</h2>
        </Row>
      )}
    </div>
  );
};
export default PageHeading;
