import React, { useState } from "react";
import {
  Card,
  Tabs,
  Form,
  Input,
  Space,
  Upload,
  Image,
  message,
  Button,
  Col,
  Row,
  Breadcrumb,
} from "antd";
import "./blogEditTab.scss";
import { CommonButton } from "../CommonButton";
import retail_Card_img1 from "../../assets/images/blog/blog_Card_img1.png";
import retail_Card_img2 from "../../assets/images/blog/blog_Card_img2.png";
import retail_Card_img3 from "../../assets/images/blog/blog_Card_img3.png";
import retail_Card_img4 from "../../assets/images/blog/blog_Card_img4.png";
import retail_Card_img5 from "../../assets/images/blog/blog_Card_img5.png";
import retail_Card_img6 from "../../assets/images/blog/blog_Card_img6.png";
import retail_Card_img7 from "../../assets/images/blog/blog_Card_img7.png";
import retail_Card_img8 from "../../assets/images/blog/blog_Card_img8.png";
import retail_Card_img9 from "../../assets/images/blog/blog_Card_img9.png";
import { BsArrowRight } from "react-icons/bs";
import BlogEditCards from "../BlogEditCards/BlogEditCards";
import BlogTabPane1 from "./BlogTabPane1";

const { TabPane } = Tabs;

const BlogEditTab = () => {
  const [selectedTab, setSelectedTab] = useState("1");
  const [breadcrumbPath, setBreadcrumbPath] = useState("Manage Blogs");

  const handleEditButtonClick = (card: any) => {
    setBreadcrumbPath(`Edit / ${card.CardTitle}`);
  };

  const dataCom = [
    {
      blogCardImg1: retail_Card_img1,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img2,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img3,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img4,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img5,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img6,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img7,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img8,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
    {
      blogCardImg1: retail_Card_img9,
      linkAnchor: "/blogDetails",
      CardTitle: "Advantages of Implementing RPA for Contact Centers",
      CardText1:
        "With the development and introduction of RPA, organizations are beginning to see huge growth in terms of savings and scaling of overall operations, however",
    },
  ];

  const handleTabChange = (key: any) => {
    setSelectedTab(key);
  };

  return (
    <>
      <Row className="blogEditMain">
        <Tabs
          activeKey={selectedTab}
          onChange={handleTabChange}
          tabPosition="top"
          className="bet-tab blogEditMain"
        >
          <TabPane tab="Add New Blogs" key="1" className="bet-tab">
            <BlogTabPane1 />
          </TabPane>
          <TabPane tab="Manage Blogs" key="2" className="bet-tab">
            <Row
              style={{ width: "100%", textAlign: "center" }}
              justify={"center"}
            >
              <Breadcrumb
                separator={<BsArrowRight />}
                className="blogTabBreadCrumb"
              >
                <Breadcrumb.Item>{breadcrumbPath}</Breadcrumb.Item>
              </Breadcrumb>
              <Col md={24} xs={24} sm={24} className="bdBlueOrangeGrad">
                <span className="BlogCardDiv">
                  <BlogEditCards
                    blogdata={dataCom}
                    onEditButtonClick={handleEditButtonClick}
                  />
                </span>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Row>
    </>
  );
};

export default BlogEditTab;
