import React from "react";
import "./ourSuccessStory.scss";
import { Button, Col, Row } from "antd";
import { WeEmpower } from "../WeEmpower";
import { BsArrowRight } from "react-icons/bs";

type Props = {
  successImg1: string;
  successHeading: string;
  successText: string;
};
const OurSuccessStory = ({
  successImg1,
  successHeading,
  successText,
}: Props) => {
  return (
    <>
      <div className="success_successheading">{successHeading}</div>
      <Row
        className="success_successRow"
        style={{ background: "#1D1A36", paddingBottom: "4vw" }}
        justify={"center"}
        align={"middle"}
      >
        <Col
          xs={24}
          sm={24}
          xl={12}
          className="success_successMainRow"
          style={{
            background: `url(${successImg1}) no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          <Row>
            <Col span={1} />
            <Col xs={24} sm={24} xl={22}>
              <div className="success_emptyDiv" />
              <div className="success_successText">
                <p className="success_successP">{successText}</p>
                <Button className="success_sucsessBtn">
                  <a className="linkTag" href="/overview?type=enterprise">
                    <span>
                      Read More
                      <BsArrowRight className="Arrow" />
                    </span>
                  </a>
                </Button>
              </div>
            </Col>

            {/* <Col span={2} /> */}

            {/* <Col xs={24} sm={24} xl={9}>
              <div className="success_emptyDiv" />
              <div className="success_weEmpowerDiv">
                <WeEmpower />
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OurSuccessStory;
