// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mail_main_div {
  width: 100%;
  background-color: #f8fcff;
  padding-bottom: 3%;
  padding-top: 3%;
}
.mail_main_div_leftSide1 {
  margin-top: 4vh;
}
.mail_main_div_checkbox {
  display: flex;
  color: #a39f9f;
}
.mail_main_div_btnSubmit1 {
  border-radius: 0;
  background-color: rgb(240, 89, 42);
  margin-top: 17%;
  font-size: 1rem;
  font-weight: 400;
  height: 42px;
}
.mail_main_div_formInput1 {
  border-top: 0;
  border-right: 0;
  background-color: #f8fcff;
  border-bottom: 1px solid #000000;
  border-left: 0;
  color: #000000;
  border-radius: 0;
}
.mail_main_div_formInput1::placeholder {
  color: #000000;
  opacity: 1;
}
.mail_main_div_formInput1:hover, .mail_main_div_formInput1:focus {
  border-top: 0;
  border-right: 0;
  background-color: #f8fcff;
  border-bottom: 1px solid #000000;
  border-left: 0;
  color: #000000;
  border-radius: 0;
}

.mail_main_div_btnSubmit1[disabled] {
  border-radius: 0;
  background-color: #F4764F;
  color: #fff;
  margin-top: 17%;
  font-size: 1rem;
  font-weight: 400;
  height: 42px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Mails/mails.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,aAAA;EACA,cAAA;AAAJ;AAGE;EACE,gBAAA;EACA,kCAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AADJ;AAIE;EACE,aAAA;EACA,eAAA;EACA,yBAAA;EACA,gCAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;AAFJ;AAKE;EACE,cAAA;EACA,UAAA;AAHJ;AAME;EAEE,aAAA;EACA,eAAA;EACA,yBAAA;EACA,gCAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;AALJ;;AAUE;EACE,gBAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AAPJ","sourcesContent":[".mail_main_div {\n  width: 100%;\n  background-color: #f8fcff;\n  padding-bottom: 3%;\n  padding-top: 3%;\n\n  &_leftSide1 {\n    margin-top: 4vh;\n  }\n\n  &_checkbox {\n    display: flex;\n    color: #a39f9f;\n  }\n\n  &_btnSubmit1 {\n    border-radius: 0;\n    background-color: rgba(240, 89, 42, 1);\n    margin-top: 17%;\n    font-size: 1rem;\n    font-weight: 400;\n    height: 42px;\n  }\n\n  &_formInput1 {\n    border-top: 0;\n    border-right: 0;\n    background-color: #f8fcff;\n    border-bottom: 1px solid #000000;\n    border-left: 0;\n    color: #000000;\n    border-radius: 0;\n  }\n\n  &_formInput1::placeholder {\n    color: #000000;\n    opacity: 1;\n  }\n\n  &_formInput1:hover,\n  &_formInput1:focus {\n    border-top: 0;\n    border-right: 0;\n    background-color: #f8fcff;\n    border-bottom: 1px solid #000000;\n    border-left: 0;\n    color: #000000;\n    border-radius: 0;\n  }\n}\n\n.mail_main_div_btnSubmit1 {\n  &[disabled] {\n    border-radius: 0;\n    background-color: #F4764F;\n    color: #fff;\n    margin-top: 17%;\n    font-size: 1rem;\n    font-weight: 400;\n    height: 42px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
