// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bltText {
  text-align: right;
}
@media (max-width: 992px) {
  .bltText {
    text-align: center;
  }
}

.bltImageDesk {
  display: flex;
}
@media (max-width: 991px) {
  .bltImageDesk {
    display: none;
  }
}

.bltImageMob {
  display: none;
}
@media (max-width: 991px) {
  .bltImageMob {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/BlogLeftText/blogLeftText.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EAFF;IAGI,kBAAA;EAGF;AACF;;AAAA;EACE,aAAA;AAGF;AAFE;EAFF;IAGI,aAAA;EAKF;AACF;;AAFA;EACE,aAAA;AAKF;AAJE;EAFF;IAGI,aAAA;EAOF;AACF","sourcesContent":[".bltText {\n  text-align: right;\n  @media (max-width: 992px) {\n    text-align: center;\n  }\n}\n\n.bltImageDesk {\n  display: flex;\n  @media (max-width: 991px) {\n    display: none;\n  }\n}\n\n.bltImageMob {\n  display: none;\n  @media (max-width: 991px) {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
