// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portal_btnSubmit {
  border-radius: 0;
  width: 170px;
  color: #f5f5f5;
  font-size: 1rem;
  font-weight: 600;
  height: 38px;
  background-color: #f0592a;
  top: 64%;
}

.ant-btn-default:not(:disabled):hover {
  color: white;
  border-color: transparent;
}

.portal_Cards {
  width: 100%;
  background-color: #020C2E;
  font-size: 1vw;
  font-weight: 500;
  border: none !important;
  padding: 1px;
  border-radius: 20px;
}

.portalRole {
  font-weight: 600;
  color: #3a3a3a;
}

.portal_pCard {
  font-weight: 400;
  color: #3a3a3a;
}

@media (max-width: 376px) {
  .portal_pCard {
    font-size: 1rem;
  }
}
.cardLocations {
  color: var(--white, #FFF);
  font-family: Roboto;
  font-size: 3.3566433566vh;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.cardrole {
  color: var(--white, #FFF);
  font-family: Roboto;
  font-size: 2.7972027972vh;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/Components/PortalCards/portalCards.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,QAAA;AACF;;AAEA;EACE,YAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAGA;EACE,gBAAA;EACA,cAAA;AAAF;;AAGA;EACE,gBAAA;EACA,cAAA;AAAF;;AAGA;EACE;IACE,eAAA;EAAF;AACF;AAIA;EACE,yBAAA;EACF,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFA;;AAKA;EACE,yBAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFF","sourcesContent":[".portal_btnSubmit {\n  border-radius: 0;\n  width: 170px;\n  color: #f5f5f5;\n  font-size: 1rem;\n  font-weight: 600;\n  height: 38px;\n  background-color: #f0592a;\n  top: 64%;\n}\n\n.ant-btn-default:not(:disabled):hover {\n  color: white;\n  border-color: transparent;\n}\n\n.portal_Cards {\n  width: 100%;\n  background-color: #020C2E;\n  font-size: 1vw;\n  font-weight: 500;\n  border: none !important;\n  padding: 1px;\n  border-radius: 20px;\n  \n}\n\n.portalRole {\n  font-weight: 600;\n  color: #3a3a3a;\n}\n\n.portal_pCard {\n  font-weight: 400;\n  color: #3a3a3a;\n}\n\n@media (max-width: 376px) {\n  .portal_pCard {\n    font-size: 1rem;\n  }\n}\n\n\n.cardLocations{\n  color: var(--white, #FFF);\nfont-family: Roboto;\nfont-size: 3.3566433566433567vh;\nfont-style: normal;\nfont-weight: 700;\nline-height: 150%; \n}\n\n.cardrole{\n  color: var(--white, #FFF);\n  font-family: Roboto;\n  font-size: 2.797202797202797vh;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 150%; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
