// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fyndrAdText {
  color: #FFF;
  padding: 2vh;
  text-align: center;
  font-family: Roboto;
  font-size: 3vh;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 50px;
}

.fyndrAdText_title {
  width: 100%;
  background-color: #1D1A36;
  padding: 0% 2% 2% 2%;
  margin-top: 8.5vh;
}

.fyndrAdText_textTitleClassName {
  color: #fff;
  font-size: 5.6224899598vh;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  z-index: 100;
  margin-top: 8.5vh;
}
@media (max-width: 1200px) {
  .fyndrAdText_textTitleClassName {
    line-height: 150%;
  }
}
@media (max-width: 992px) {
  .fyndrAdText_textTitleClassName {
    line-height: 150%;
  }
}
@media (max-width: 768px) {
  .fyndrAdText_textTitleClassName {
    line-height: 150%;
  }
}
@media (max-width: 576px) {
  .fyndrAdText_textTitleClassName {
    line-height: 150%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/FyndrAdvantage/fyndrAdvantage.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AACA;EACI,WAAA;EACA,yBAAA;EACA,oBAAA;EACA,iBAAA;AAEJ;;AAAA;EACI,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,iBAAA;AAGJ;AAFI;EARJ;IASQ,iBAAA;EAKN;AACF;AADI;EAdJ;IAeQ,iBAAA;EAIN;AACF;AADI;EAnBJ;IAoBQ,iBAAA;EAIN;AACF;AADI;EAxBJ;IAyBQ,iBAAA;EAIN;AACF","sourcesContent":[".fyndrAdText{\n    color: #FFF;\n    padding: 2vh;\n    text-align: center;\n    font-family: Roboto;\n    font-size: 3vh;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%;\n    margin-top: 50px;\n}\n.fyndrAdText_title{\n    width: 100%;\n    background-color: #1D1A36;\n    padding: 0% 2% 2% 2%;\n    margin-top: 8.5vh;\n}\n.fyndrAdText_textTitleClassName {\n    color: #fff;\n    font-size: 5.622489959839357vh;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 100%;\n    z-index: 100;\n    margin-top: 8.5vh;\n    @media (max-width: 1200px) {\n        line-height: 150%;\n        \n\n    }\n    \n    @media (max-width: 992px) {\n        line-height: 150%;\n\n    }\n    \n    @media (max-width: 768px) {\n        line-height: 150%;\n\n    }\n    \n    @media (max-width: 576px) {\n        line-height: 150%;\n\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
