import HeroIndustries from "../../Components/HeroIndustries/HeroIndustries";
import OurPartners from "../../Components/OurPartners/OurPartners";
import INDUSTRIES01 from "../../assets/images/OurPartners/partner/INDUSTRIES01.png";
import INDUSTRIES02 from "../../assets/images/OurPartners/partner/INDUSTRIES02.png";
import INDUSTRIES03 from "../../assets/images/OurPartners/partner/INDUSTRIES03.png";
import INDUSTRIES04 from "../../assets/images/OurPartners/partner/INDUSTRIES04.png";
import INDUSTRIES05 from "../../assets/images/OurPartners/partner/INDUSTRIES05.png";
import INDUSTRIES06 from "../../assets/images/OurPartners/partner/INDUSTRIES06.png";
import INDUSTRIES07 from "../../assets/images/OurPartners/partner/INDUSTRIES07.png";
import INDUSTRIES08 from "../../assets/images/OurPartners/partner/INDUSTRIES08.png";
import INDUSTRIES081 from "../../assets/images/OurPartners/partner/INDUSTRIES08-1.png";
import INDUSTRIES082 from "../../assets/images/OurPartners/partner/INDUSTRIES08-2.png";
import INDUSTRIES09 from "../../assets/images/OurPartners/partner/INDUSTRIES09.png";
import INDUSTRIES010 from "../../assets/images/OurPartners/partner/INDUSTRIES10.png";
import INDUSTRIES011 from "../../assets/images/OurPartners/partner/INDUSTRIES11.png";
import INDUSTRIES012 from "../../assets/images/OurPartners/partner/INDUSTRIES12.png";
import INDUSTRIES013 from "../../assets/images/OurPartners/partner/INDUSTRIES13.png";
import INDUSTRIES014 from "../../assets/images/OurPartners/partner/INDUSTRIES14.png";
import INDUSTRIES015 from "../../assets/images/OurPartners/partner/INDUSTRIES15.png";
import ourCust from "../../assets/images/services_banners/services_bigData.jpg";
import FunctionTitles from "../../Components/Utiles/FunctionTitles";

const Customers = (props: any) => {
  FunctionTitles("NucleusTeq | Customers");
  const data = [
    INDUSTRIES01,
    INDUSTRIES02,
    INDUSTRIES03,
    INDUSTRIES04,
    INDUSTRIES05,
    INDUSTRIES06,
    INDUSTRIES07,
    INDUSTRIES08,
    INDUSTRIES081,
    INDUSTRIES082,
    INDUSTRIES09,
    INDUSTRIES010,
    INDUSTRIES011,
    INDUSTRIES012,
    INDUSTRIES013,
    INDUSTRIES014,
    INDUSTRIES015,
  ];
  return (
    <div>
      <HeroIndustries
        pageIntro="OUR CUSTOMERS"
        image={ourCust}
        pageName="OUR CUSTOMERS"
        pageIntroPara={"A Trusted Partnership for Excellence"}
        alt={"our customers"}
      />
      <OurPartners
        data={data}
        title="OUR CUSTOMERS"
        subTitle="At NucleusTeq, our clients are essential partners in our journey to excellence. We take immense pride in the relationships we've built and the trust our clients have placed in us. Their success is our success, and their satisfaction is our driving force. "
      />
    </div>
  );
};
export default Customers;
