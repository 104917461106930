import React from "react";
import "./contentOfPages.scss";
import { Col, Row, Typography } from "antd";

interface Props {
  text: String;
}

const ContentOfPages = ({ text }: Props) => {
  const { Text } = Typography;
  return (
    <div className="mainHeading">
      <Row justify={"center"} style={{ marginTop: "3vh", width:"90%" }}>
        <Col xs={24}>
          <Text className="textCareer">{text}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default ContentOfPages;
