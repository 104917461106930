// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circleImage {
  width: 20vw;
  height: 20vw;
}
.circleImage_icons {
  margin: 1.25vw 0vw 2vw 0vw;
  cursor: pointer;
}
.circleImage_linkMail {
  height: 2.5vw;
  width: 2.5vw;
}

@media only screen and (max-width: 578px) {
  .circleImage {
    width: 60vw;
    height: 60vw;
  }
  .circleImage_icons {
    margin: 2.5vw 0vw 4vw;
    cursor: pointer;
  }
  .circleImage_linkMail {
    height: 7vw;
    width: 7vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/CircleImage/circleImage.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;AACI;EACI,0BAAA;EACA,eAAA;AACR;AACI;EACI,aAAA;EACA,YAAA;AACR;;AAEA;EACI;IACI,WAAA;IACA,YAAA;EACN;EACM;IACI,qBAAA;IACA,eAAA;EACV;EACM;IACI,WAAA;IACA,UAAA;EACV;AACF","sourcesContent":[".circleImage {\n    width: 20vw;\n    height: 20vw;\n\n    &_icons{\n        margin:1.25vw 0vw 2vw 0vw;\n        cursor: pointer;\n    }\n    &_linkMail{\n        height:2.5vw;\n        width:2.5vw;\n    }\n}\n@media  only screen and (max-width:578px){\n    .circleImage {\n        width: 60vw;\n        height: 60vw;\n    \n        &_icons{\n            margin:2.5vw 0vw 4vw;\n            cursor: pointer;\n        }\n        &_linkMail{\n            height:7vw;\n            width:7vw;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
