import React from "react";
import { Col, Row } from "antd";
import "./communicationHeading.scss";

type Props = {
  cardHeadingText: String;
};

const CommunicationHeading = ({ cardHeadingText }: Props) => {
  return (
    <Row
      justify={"center"}
      className="communication_cardMainRowHeading"
      style={{ background: "#1D1A36" }}
    >
      <Col span={24}>
        <p className="communication_cardHeading">{cardHeadingText}</p>
      </Col>
    </Row>
  );
};

export default CommunicationHeading;
