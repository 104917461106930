import React, { useRef } from "react";
import Navbar from "../Layouts/Navbar";
import MainBanner from "../HomeDefault/MainBanner";
import AboutUsContent from "../HomeDefault/AboutUsContent";
import Speakers from "../HomeDefault/Speakers";
import EventSchedules from "../HomeDefault/EventSchedules";
import Partner from "../Common/Partner";
import Subscribe from "../Common/Subscribe";
import Footer from "../Layouts/EventsFooter";
import "antd-css-utilities/utility.min.css";
import Register from "../Register";

import "../../styles/bootstrap.min.css";
import "../../styles/icofont.min.css";
import "../../styles/animate.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-tabs/style/react-tabs.css";
import "swiper/css";
import "swiper/css/bundle";

// Global Styles
import "../../styles/style.css";
import "../../styles/responsive.css";
import GoTop from "../Layouts/GoTop";

export default function Events() {
  // Provide an explicit type to useRef
  const registerSectionRef = useRef<HTMLDivElement | null>(null);

  const scrollToRegisterSection = () => {
    if (registerSectionRef.current) {
      registerSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="EventsPage">
        <Navbar />
        <MainBanner />
        <div id="eventsSection">
          <AboutUsContent />
        </div>

        <div id="speakersSection">
          <Speakers />
        </div>

        <div id="agendaSection">
          <EventSchedules />
        </div>

        <div id="companiesSection">
          <Partner />
        </div>

        <div id="registerSection" ref={registerSectionRef}>
          <Register />
        </div>

        <Footer />
        <GoTop />
      </div>
    </>
  );
}
