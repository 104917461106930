import { Col, Row, Carousel } from "antd";
import React from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import "./nucleusteqVoiceImages.scss";

type Props = {
  nucleusteqVoiceImages: {
    name: string;
    desigination: string;
    thoughts: string;
    images: string;
  }[];
};

const NucleusteqVoiceImages = ({ nucleusteqVoiceImages }: Props) => {
  const carouselProps = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ height: "100vh" }}>
      <Carousel {...carouselProps} autoplay draggable pauseOnHover={false}>
        {nucleusteqVoiceImages.map((data, i) => (
          <div key={i}>
            <div style={{ padding: "0 24px " }}>
              <div
                className="NucleusteqVoiceImages_images"
                style={{
                  background: `url("${data.images}") no-repeat center center`,
                  backgroundSize: "contain",
                }}
              />
              <div className="NucleusteqVoiceImages_name">{data.name}</div>
              <div className="NucleusteqVoiceImages_designation">
                {data.desigination}
              </div>
              <div>
                <p className="NucleusteqVoiceImages_thought">{data.thoughts}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default NucleusteqVoiceImages;
