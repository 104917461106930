// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leadershipcomp_dekstopview {
  padding: 6vw 10vw 0vw 10vw;
  background: #1D1A36;
}
.leadershipcomp_mobileview {
  padding: 3rem;
  display: none;
  background: #1D1A36;
}

@media screen and (max-width: 320px) {
  .leadershipcomp_dekstopview {
    display: none;
  }
  .leadershipcomp_mobileview {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 320px) and (max-width: 576px) {
  .leadershipcomp_dekstopview {
    display: none;
  }
  .leadershipcomp_mobileview {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 576px) and (max-width: 931px) {
  .leadershipcomp_dekstopview {
    display: flex;
    flex-direction: column;
  }
  .leadershipcomp_mobileview {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/LeadershipComponent/leadershipComponent.scss"],"names":[],"mappings":"AACI;EACI,0BAAA;EACA,mBAAA;AAAR;AAGI;EACI,aAAA;EACA,aAAA;EACA,mBAAA;AADR;;AAKA;EAEQ;IACI,aAAA;EAHV;EAMM;IACI,aAAA;IACA,sBAAA;EAJV;AACF;AAQA;EAEQ;IACI,aAAA;EAPV;EAUM;IACI,aAAA;IACA,sBAAA;EARV;AACF;AAaA;EAEQ;IACI,aAAA;IACA,sBAAA;EAZV;EAeM;IACI,aAAA;EAbV;AACF","sourcesContent":[".leadershipcomp {\n    &_dekstopview {\n        padding: 6vw 10vw 0vw 10vw;\n        background: #1D1A36;\n    }\n\n    &_mobileview {\n        padding: 3rem;\n        display: none;\n        background: #1D1A36;\n    }\n}\n\n@media screen and (max-width:320px) {\n    .leadershipcomp {\n        &_dekstopview {\n            display: none;\n        }\n\n        &_mobileview {\n            display: flex;\n            flex-direction: column;\n        }\n    }\n}\n\n@media screen and (min-width:320px) and (max-width:576px) {\n    .leadershipcomp {\n        &_dekstopview {\n            display: none;\n        }\n\n        &_mobileview {\n            display: flex;\n            flex-direction: column;\n        }\n    }\n\n}\n\n@media screen and (min-width:576px) and (max-width:931px){\n    .leadershipcomp {\n        &_dekstopview {\n            display: flex;\n            flex-direction: column;\n        }\n    \n        &_mobileview {\n            display: none\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
