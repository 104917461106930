import React from "react";
import { Col, Row } from "antd";
import "./communicationCard.scss";
import { Link } from "react-router-dom";
type Props = {
  servicedata: {
    comCardImg1: string;
    comCardText1: string;
    CardText1: string;
    linkAnchor: string;
  }[];
};

const CommunicationCard = ({ servicedata }: Props) => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="communication-card container">
      <Row gutter={[16, 16]} style={{ margin: "0 auto" }}>
        {servicedata.map((card, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
            <Link
              to={card.linkAnchor}
              style={{ textDecoration: "none" }}
              onClick={handleLinkClick}
            >
              <div
                className="card"
                style={{
                  background: `url("${card.comCardImg1}") no-repeat center center`,
                  backgroundSize: "cover",
                  margin: "0 auto",
                }}
              >
                <div className="overlay-text">{card.CardText1}</div>
              </div>
            </Link>
            <div className="comText">{card.comCardText1}</div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CommunicationCard;
