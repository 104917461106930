import { useState, useEffect } from "react";
import { Layout, Row, Col, Tabs, Image } from "antd";
import { customersAndPartners } from "../../../mocks/customersAndPartners";

const { TabPane } = Tabs;

const CustomersAndPartners = () => {
  const [activeTab, setActiveTab] = useState("1");
  const handleChangeTab = (tabKey: any) => {
    setActiveTab(tabKey);
  };

  const autoPlayInterval = 3000;

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = customersAndPartners.findIndex(
        (tab) => tab.tabKey === activeTab
      );
      const nextIndex = (currentIndex + 1) % customersAndPartners.length;

      setActiveTab(customersAndPartners[nextIndex].tabKey);
    }, autoPlayInterval);

    return () => clearInterval(interval);
  }, [activeTab]);

  const activeTabIndex = customersAndPartners.findIndex(
    (tab) => tab.tabKey === activeTab
  );

  const reorderedTabs = [
    ...customersAndPartners.slice(activeTabIndex),
    ...customersAndPartners.slice(0, activeTabIndex),
  ];
  return (
    <Layout className="container-fluid ocp">
      <Row justify={"center"} className="ocp-center">
        <Row className="ocp-main-row">
          <Row
            align={"middle"}
            justify={"center"}
            style={{ width: "100%" }}
            className="commonTitleHead"
          >
            OUR CUSTOMERS
          </Row>
          <div className="ocp-logo-div">
            <Tabs
              activeKey={activeTab}
              onChange={handleChangeTab}
              tabPosition={"top"}
              className="ocp-tab"
            >
              {reorderedTabs.map((tab) => (
                <TabPane
                  className="ocp-tab"
                  tab={
                    <Col>
                      <Image
                        src={tab.tab}
                        alt="tabLogo"
                        preview={false}
                        className="cpTabImage"
                      />
                    </Col>
                  }
                  key={tab.tabKey}
                >
                  {tab.items.map((item, index) => {
                    console.log("index", index);
                    return (
                      <Row 
                      // style={{ marginBottom: "4.2vh" }}
                      >
                        {/* <Col xs={24} sm={24} md={24} className="tab-title">
                          {item.title}
                        </Col>
                        <Col xs={24} sm={24} md={24} className="tab-text">
                          {item.text}
                        </Col> */}
                      </Row>
                    );
                  })}
                </TabPane>
              ))}
            </Tabs>
          </div>
        </Row>
        <div className="ocp-orange-gradient" />
      </Row>
    </Layout>
  );
};

export default CustomersAndPartners;
